import * as React from 'react';
import scriptjs from 'scriptjs';
import { RenderProp } from '../app-utilities/reactUtilTypes';
import { GOOGLE_MAP_LIB_URL } from '../deprecated/deprecated-apoly-app/utilities/googleMaps/withGoogleMapScript';

type GoogleMap = typeof google.maps | undefined;

interface LoadGoogleProps {
  children: RenderProp<GoogleMap>;
}

declare global {
  interface Window {
    google?: {
      maps: GoogleMap;
    };
  }
}

const getGoogleMaps = () => {
  if (window.google) {
    return window.google.maps;
  }

  return undefined;
};

export function geocode(
  googleMaps: typeof google.maps,
  address: string,
  params?: google.maps.GeocoderRequest
): Promise<google.maps.GeocoderResult[]> {
  return new Promise((resolve, reject) => {
    new googleMaps.Geocoder().geocode(
      { address, ...params },
      (results, status) => {
        switch (status) {
          case googleMaps.GeocoderStatus.OK:
            return resolve(results);
          case googleMaps.GeocoderStatus.ZERO_RESULTS:
            return resolve([]);
          default:
            return reject(new Error(status.toString()));
        }
      }
    );
  });
}

export class LoadGoogle extends React.PureComponent<
  LoadGoogleProps,
  { googleMaps: GoogleMap }
> {
  state = {
    googleMaps: getGoogleMaps(),
  };

  componentDidMount() {
    if (this.state.googleMaps) {
      return;
    }

    // TODO besser mit https://github.com/muicss/loadjs lösen (error-handling ist da mit drin), aber aktuell nutzt diesesr google-wrapper auch scriptjs weswegen das besser passt
    scriptjs(GOOGLE_MAP_LIB_URL, () => {
      if (this.state.googleMaps !== getGoogleMaps()) {
        this.setState({ googleMaps: getGoogleMaps() });
      }
    });
  }

  render() {
    return this.props.children(this.state.googleMaps);
  }
}
