import { OnDidMount } from '@apoly-42/apoly-utils';
import { Avatar, MenuItem } from '@material-ui/core';
import React from 'react';
import Highlighter from 'react-highlight-words';
import googleDataLayerPush from '../../utilities/googleDataLayerPush';

/* eslint-disable quote-props */
const mapPropsToPageImpression = suggestion => ({
  ecommerce: {
    currencyCode: 'EUR',
    impressions: [
      {
        list: 'Shop-Such-Leiste',
        name: suggestion.name,
        id: suggestion.name,
        brand: suggestion.producers.map(producer => producer.name).join(','),
        category: suggestion.symptoms.map(symptom => symptom.name).join(','),
        position: suggestion.index,
      },
    ],
  },
});

const renderProductSuggestion = (
  suggestion,
  bigAvatar,
  { query, isHighlighted } = {}
) => (
  <React.Fragment>
    <OnDidMount
      handler={() => googleDataLayerPush(mapPropsToPageImpression(suggestion))}
    />

    <MenuItem style={{ marginTop: 8 }} selected={isHighlighted} component="div">
      <Avatar alt={suggestion.name} src={suggestion.image} style={bigAvatar} />
      <div>
        <Highlighter
          highlightStyle={{ fontWeight: 600, backgroundColor: 'transparent' }}
          searchWords={[query]}
          autoEscape
          textToHighlight={suggestion.name}
        />
      </div>
    </MenuItem>
  </React.Fragment>
);

export default renderProductSuggestion;
