import { CssBaseline } from '@material-ui/core';
import * as React from 'react';
import { DynamicProvider } from 'react-dynamic-context';
import { hot } from 'react-hot-loader';
import { appPalette } from '../app-theme/apolyThemeValues';
import { ErrorBoundary } from '../app-util-components/ErrorBoundary';
import {
  SyncLanguageCtxWithRedux,
  SyncPharmacyCtxWithRedux,
  SyncServerTimeCtxWithRedux,
} from '../deprecated/redux-adapters';
import { AsyncLanguageLoadable } from '../languages/AsyncLanguageLoadable';
import {
  getLanguage,
  LanguageProvider,
  Languages,
  LanguageValues,
} from '../languages/LanguageContext';
import { WriteCtxLanguageToStorageCtx } from '../languages/WriteCtxLanguageToStorageCtx';
import { StorageProvider, valuesFromStorage } from '../storage/StorageContext';
import { SyncStorageWithContext } from '../storage/SyncStorageWithContext';
import { UrlContextProvider, urlFromLocation } from '../url';
import { SyncUrlWithCtxReactRouter } from '../url-utils/SyncUrlCtxWithReactRouter';
import { AppContent } from './AppContent';
import { AppError } from './AppError';
import { EternalAppError } from './EternalAppError';
import { AppMuiTheme } from './layout/AppMuiTheme';
import { SyncServerTime } from './SyncServerTime';
import DataProtectionAndCookieConsent from '../deprecated/deprecated-apoly-app/components/DataProtectionAndCookieConsent';

const InnerApp = () => {
  const url = urlFromLocation(window.location);
  const lsValues = valuesFromStorage(window.localStorage);
  const language = getLanguage(url, lsValues) || Languages.de;

  return (
    <ErrorBoundary component={EternalAppError}>
      <DynamicProvider>
        <SyncPharmacyCtxWithRedux />
        <SyncServerTimeCtxWithRedux />
        <SyncServerTime />

        <UrlContextProvider urlBase={url} initialUrl={url}>
          {/* Eigentlich sollte hier SyncUrlContextWithBrowser genommen werden, aber gut */}
          <SyncUrlWithCtxReactRouter />

          <StorageProvider initialValues={lsValues}>
            <SyncStorageWithContext storage={window.localStorage} />

            <LanguageProvider initialValues={{ language }}>
              <WriteCtxLanguageToStorageCtx />
              <SyncLanguageCtxWithRedux />

              <LanguageValues>
                {ctx => (
                  <AsyncLanguageLoadable language={ctx.language}>
                    <AppMuiTheme palette={appPalette}>
                      <CssBaseline />
                      <ErrorBoundary component={AppError}>
                        <AppContent />

                        <DataProtectionAndCookieConsent />
                      </ErrorBoundary>
                    </AppMuiTheme>
                  </AsyncLanguageLoadable>
                )}
              </LanguageValues>
            </LanguageProvider>
          </StorageProvider>
        </UrlContextProvider>
      </DynamicProvider>
    </ErrorBoundary>
  );
};

export const App =
  process.env.NODE_ENV === 'development' ? hot(module)(InnerApp) : InnerApp;
