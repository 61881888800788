import * as React from 'react';
import {
  StorageConsumer,
  StorageContextProps,
} from '../storage/StorageContext';
import { LanguageConsumer, LanguageContextProps } from './LanguageContext';

interface SyncLanguageWithStorageCtxProps {
  languageStorage: StorageContextProps['language'];
  languageCtxValue: LanguageContextProps['language'];
}

export class SyncLanguageWithStorage extends React.PureComponent<
  SyncLanguageWithStorageCtxProps
> {
  componentDidMount() {
    this.syncCtxValueToStorage();
  }

  componentDidUpdate() {
    this.syncCtxValueToStorage();
  }

  syncCtxValueToStorage() {
    const val = this.props.languageCtxValue.toString();

    if (val !== this.props.languageStorage.value) {
      this.props.languageStorage.setValue(val);
    }
  }

  render() {
    return null;
  }
}

export const WriteCtxLanguageToStorageCtx: React.SFC = () => (
  <StorageConsumer>
    {storageCtx => (
      <LanguageConsumer>
        {languageCtx => (
          <SyncLanguageWithStorage
            languageStorage={storageCtx.language}
            languageCtxValue={languageCtx.language.value}
          />
        )}
      </LanguageConsumer>
    )}
  </StorageConsumer>
);
