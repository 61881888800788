import { Hidden } from '@material-ui/core';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import {
  appGrid,
  gridArea,
  mdUp,
} from '../../../../constants/styles/cssEmotionClasses';
import {
  selectBasePath,
  selectPharmacyId,
} from '../../../../redux/shopPharmacy/reducer';
import {
  decreaseItemAmount,
  deleteItemFromShoppingCart,
  increaseItemAmount,
} from '../../../../redux/shoppingCart/reducer';
import { selectProducts } from '../../../../redux/shoppingCart/selectors';
import ShoppingCartItem from '../soppingCartItem/ShoppingCartItem';
import ShoppingCartItemResponsive from '../soppingCartItem/ShoppingCartItemResponsive';
import ShoppingCartTableHead from './ShoppingCartTableHead';

const shoppingCartGrid = css`
  ${appGrid};
  grid-template-columns: 1fr;
  grid-template-areas: 'shoppingCartItems';
  ${mdUp} {
    grid-template-areas:
      'tableHead'
      'shoppingCartItems';
  }
`;

const shoppingCartItemsGrid = css`
  ${appGrid};
  grid-area: shoppingCartItems;
  grid-template-columns: 1fr;
`;

/* eslint-disable no-shadow,quote-props */
const InnerShoppingCartTableView = ({
  products,
  articles,
  deleteItemFromShoppingCart,
  increaseItemAmount,
  decreaseItemAmount,
  pharmacyId,
  basePath,
}) => (
  <div className={shoppingCartGrid}>
    <Hidden smDown>
      <div className={gridArea('tableHead')}>
        <ShoppingCartTableHead />
      </div>
    </Hidden>
    <div className={shoppingCartItemsGrid}>
      {articles.length > 0 &&
        Object.keys(products).map(key => (
          <div key={products[key].articleId}>
            <Hidden smDown>
              <ShoppingCartItem
                articleId={products[key].articleId}
                basePath={basePath}
                articles={articles}
                count={Number(products[key].count)}
                deleteItem={() =>
                  deleteItemFromShoppingCart({
                    articleId: products[key].articleId,
                    pharmacyId,
                  })
                }
                increaseItemAmount={() =>
                  increaseItemAmount({
                    articleId: products[key].articleId,
                    pharmacyId,
                  })
                }
                decreaseItemAmount={() =>
                  decreaseItemAmount({
                    articleId: products[key].articleId,
                    pharmacyId,
                  })
                }
              />
            </Hidden>
            <Hidden mdUp>
              <ShoppingCartItemResponsive
                articleId={products[key].articleId}
                basePath={basePath}
                articles={articles}
                count={Number(products[key].count)}
                deleteItem={() =>
                  deleteItemFromShoppingCart({
                    articleId: products[key].articleId,
                    pharmacyId,
                  })
                }
                increaseItemAmount={() =>
                  increaseItemAmount({
                    articleId: products[key].articleId,
                    pharmacyId,
                  })
                }
                decreaseItemAmount={() =>
                  decreaseItemAmount({
                    articleId: products[key].articleId,
                    pharmacyId,
                  })
                }
              />
            </Hidden>
          </div>
        ))}
    </div>
  </div>
);

const mapStateToProps = state => ({
  pharmacyId: selectPharmacyId(state),
  basePath: selectBasePath(state),
  products: selectProducts(state),
});

const mapDispatchToProps = {
  deleteItemFromShoppingCart,
  increaseItemAmount,
  decreaseItemAmount,
};

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

const ShoppingCartTableView = enhance(InnerShoppingCartTableView);

ShoppingCartTableView.propTypes = {
  articles: PropTypes.array.isRequired,
};

InnerShoppingCartTableView.propTypes = {
  products: PropTypes.object.isRequired,
  pharmacyId: PropTypes.number.isRequired,
  increaseItemAmount: PropTypes.func.isRequired,
  decreaseItemAmount: PropTypes.func.isRequired,
  deleteItemFromShoppingCart: PropTypes.func.isRequired,
  ...ShoppingCartTableView.propTypes,
};

export default ShoppingCartTableView;
