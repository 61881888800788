import { I18nMessage } from '@apoly-42/apoly-components';
import { ButtonWithIcon, GridRow } from '@apoly-42/material-ui';
import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { selectBasePath } from '../redux/shopPharmacy/reducer';

const messages = defineMessages({
  noItemsInShoppingCart: {
    id: 'noItemsInShoppingCart',
    defaultMessage: 'No items in shopping cart!',
  },
  backToPharmacy: {
    id: 'backToPharmacy',
    defaultMessage: 'Go back to your selected Pharmacy',
  },
});

const InnerNoItemsInShoppingCart = ({ basePath }) => (
  <Grid container spacing={16}>
    <GridRow item xs={12}>
      <Typography variant="headline">
        <I18nMessage message={messages.noItemsInShoppingCart} />
      </Typography>
    </GridRow>
    <GridRow item>
      <ButtonWithIcon
        icon={faArrowLeft}
        color="secondary"
        variant="raised"
        component={Link}
        to={basePath}
      >
        <I18nMessage message={messages.backToPharmacy} />
      </ButtonWithIcon>
    </GridRow>
  </Grid>
);

const mapStateToProps = state => ({
  basePath: selectBasePath(state),
});

const enhance = compose(connect(mapStateToProps));

const NoItemsInShoppingCart = enhance(InnerNoItemsInShoppingCart);

InnerNoItemsInShoppingCart.propTypes = {
  basePath: PropTypes.string.isRequired,
};

export default NoItemsInShoppingCart;
