import { Typography } from '@material-ui/core';
import { TypographyProps } from '@material-ui/core/Typography';
import { css, cx } from 'emotion';
import { equals } from 'ramda';
import * as React from 'react';
import { cond } from '../app-utilities/fn-utils';
import { calcFuncForPairs } from '../app-utilities/gauss';

type Pair = [/* width in px */ number, /* font-size in px */ number];

export const toCalc = (points: [Pair, Pair]) => {
  const [vwFactor, pxVal] = calcFuncForPairs(points);

  return `calc(${vwFactor * 100}vw + ${pxVal}px)`;
};

const headline1 = css`
  font-family: Lora, Roboto, sans-serif;
  font-size: ${toCalc([[1920, 60], [320, 32]])};
  line-height: 1;
  font-weight: 300;
  letter-spacing: -0.01562rem;
`;
const headline2 = css`
  font-size: ${toCalc([[1920, 46], [320, 26]])};
  line-height: 1;
  font-weight: 300;
  letter-spacing: -0.00833rem;
`;
const headline3 = css`
  font-size: ${toCalc([[1920, 36], [320, 22]])};
  line-height: 1.041666667;
  font-weight: 400;
  letter-spacing: normal;
`;
const headline4 = css`
  font-size: ${toCalc([[1920, 28], [320, 20]])};
  line-height: 1.176470588;
  font-weight: 400;
  letter-spacing: 0.00735rem;
`;
const headline5 = css`
  font-size: ${toCalc([[1920, 22], [320, 18]])};
  line-height: 1.333333333;
  font-weight: 400;
  letter-spacing: normal;
`;
const headline6 = css`
  font-size: ${toCalc([[1920, 20], [320, 16]])};
  line-height: 1.6;
  font-weight: 500;
  letter-spacing: 0.0125rem;
`;
const subtitle1 = css`
  font-size: ${toCalc([[1920, 18], [320, 16]])};
  line-height: 1.75;
  font-weight: 400;
  letter-spacing: 0.00937rem;
`;
const subtitle2 = css`
  font-size: ${toCalc([[1920, 16], [320, 14]])};
  line-height: 1.571428571;
  font-weight: 500;
  letter-spacing: 0.00714rem;
`;
const body1 = css`
  font-size: ${toCalc([[1920, 18], [320, 16]])};
  line-height: 1.5;
  font-weight: 400;
  letter-spacing: 0.03125rem;
`;
const body2 = css`
  font-size: ${toCalc([[1920, 16], [320, 14]])};
  line-height: 1.428571429;
  font-weight: 400;
  letter-spacing: 0.01786rem;
`;

const getClassName = cond([
  [equals('headline1'), () => headline1],
  [equals('headline2'), () => headline2],
  [equals('headline3'), () => headline3],
  [equals('headline4'), () => headline4],
  [equals('headline5'), () => headline5],
  [equals('headline6'), () => headline6],
  [equals('subtitle1'), () => subtitle1],
  [equals('subtitle2'), () => subtitle2],
  [equals('body1'), () => body1],
  [equals('body2'), () => body2],
]);

const getTag = cond([
  [equals('headline1'), () => 'h1'],
  [equals('headline2'), () => 'h2'],
  [equals('headline3'), () => 'h3'],
  [equals('headline4'), () => 'h4'],
  [equals('headline5'), () => 'h5'],
  [equals('headline6'), () => 'h6'],
  [equals('subtitle1'), () => 'p'],
  [equals('subtitle2'), () => 'p'],
  [equals('body1'), () => 'p'],
  [equals('body2'), () => 'p'],
]);

type TypographyTypes =
  | 'headline1'
  | 'headline2'
  | 'headline3'
  | 'headline4'
  | 'headline5'
  | 'headline6'
  | 'subtitle1'
  | 'subtitle2'
  | 'body1'
  | 'body2';

export interface FluidTypographyProps extends TypographyProps {
  type?: TypographyTypes;
}

const colorFromType = (type: TypographyTypes) =>
  type === 'headline1' ? 'primary' : undefined;

export class FluidTypography extends React.PureComponent<FluidTypographyProps> {
  render() {
    const { type = 'body1', component, className, color, ...rest } = this.props;

    return (
      <Typography
        component={component || getTag(type)}
        className={cx(getClassName(type), className)}
        color={color || colorFromType(type)}
        {...rest}
      />
    );
  }
}
