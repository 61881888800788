import { cond, equals } from 'ramda';
import { defineMessages } from 'react-intl';
import { commonMessages } from '../../constants/messages/commonMessages';
import cash from './assets/bar.png';
import mastercard from './assets/mastercard.png';
import paypal from './assets/paypal.png';
import sepa from './assets/sepa.png';
import sofort from './assets/sofort-ueberweisung.png';
import visa from './assets/visa.png';

const messages = defineMessages({
  paymentCash: {
    id: 'paymentCash',
    defaultMessage: 'cash',
  },
  paymentSofort: {
    id: 'paymentSofort',
    defaultMessage: 'sofort',
  },
  paymentVisa: {
    id: 'paymentVisa',
    defaultMessage: 'visa',
  },
  paymentSepa: {
    id: 'paymentSepa',
    defaultMessage: 'sepa',
  },
  paymentMastercard: {
    id: 'paymentMastercard',
    defaultMessage: 'Mastercard',
  },
  paymentPaypal: {
    id: 'paymentPaypal',
    defaultMessage: 'PayPal',
  },
});

export const getPaymentMethodImage = cond([
  [equals('cash'), () => cash],
  [equals('sofort'), () => sofort],
  [equals('visa'), () => visa],
  [equals('sepa'), () => sepa],
  [equals('mastercard'), () => mastercard],
  [equals('paypal'), () => paypal],
  [() => true, () => ''],
]);

export const paymentMethodToMessage = cond([
  [equals('cash'), () => messages.paymentCash],
  [equals('sofort'), () => messages.paymentSofort],
  [equals('visa'), () => messages.paymentVisa],
  [equals('sepa'), () => messages.paymentSepa],
  [equals('mastercard'), () => messages.paymentMastercard],
  [equals('paypal'), () => messages.paymentPaypal],
  [() => true, () => commonMessages.unknown],
]);
