import { build } from '../../tpl/tpl';
import { TplCreator } from '../../tpl/tplTypes';
import { Pharmacy } from '../apolyApi';
import { PharmacyBasePathProps } from '../routes';
import { channelToRoute, PharmacyChannel } from '../routes-helper';

// das ist nicht gut, insgesamt funktioniert die url-geschichte nicht so wie gewünscht
export function pharmacyCtxToPath(
  tplCreator: TplCreator<PharmacyBasePathProps>,
  pharmacyCtx: {
    pharmacyChannel: PharmacyChannel | undefined;
    pharmacy: Pharmacy | undefined;
  }
) {
  return pharmacyCtx.pharmacy && pharmacyCtx.pharmacyChannel !== undefined
    ? build(tplCreator, {
        pharmacyUrlCode: pharmacyCtx.pharmacy.urlCode,
        pharmacyChannel: channelToRoute(pharmacyCtx.pharmacyChannel),
      })
    : '';
}
