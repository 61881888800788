import { FlagIcon } from '@apoly-42/apoly-components';
import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { I18nMessage } from '../../../app-util-components/I18nMessage';
import { appMessages } from '../../../app-utilities/appMessages';
import {
  flex,
  verticalCenteredInFlex,
} from '../../../app-utilities/cssClasses';
import { LanguageConsumer } from '../../../languages/LanguageContext';
import { FooterHeadline } from './FooterHeadline';

export const ChangeLocale: React.SFC = () => (
  <LanguageConsumer>
    {lngCtx => (
      <Grid container={true} spacing={16}>
        <Grid item={true} xs={12}>
          <FooterHeadline>
            <I18nMessage message={appMessages.selectLanguage} />
          </FooterHeadline>
        </Grid>
        <Grid item={true} xs={6} sm={6} md={12} lg={6} xl={6}>
          <Button
            className={flex}
            onClick={() => lngCtx.language.setValue('en')}
            disabled={lngCtx.language.value === 'en'}
          >
            <FlagIcon className={verticalCenteredInFlex} code={'gb'} />
            English
          </Button>
        </Grid>
        <Grid item={true} xs={6} sm={6} md={12} lg={6} xl={6}>
          <Button
            className={flex}
            onClick={() => lngCtx.language.setValue('de')}
            disabled={lngCtx.language.value === 'de'}
          >
            <FlagIcon className={verticalCenteredInFlex} code={'de'} />
            Deutsch
          </Button>
        </Grid>
        <Grid item={true} xs={6} sm={6} md={12} lg={6} xl={6}>
          <Button
            className={flex}
            onClick={() => lngCtx.language.setValue('ar')}
            disabled={lngCtx.language.value === 'ar'}
          >
            <FlagIcon className={verticalCenteredInFlex} code={'sy'} />
            العربية
          </Button>
        </Grid>
        <Grid item={true} xs={6} sm={6} md={12} lg={6} xl={6}>
          <Button
            className={flex}
            onClick={() => lngCtx.language.setValue('tr')}
            disabled={lngCtx.language.value === 'tr'}
          >
            <FlagIcon className={verticalCenteredInFlex} code={'tr'} />
            Türkçe
          </Button>
        </Grid>
        <Grid item={true} xs={6} sm={6} md={12} lg={6} xl={6}>
          <Button
            className={flex}
            onClick={() => lngCtx.language.setValue('ru')}
            disabled={lngCtx.language.value === 'ru'}
          >
            <FlagIcon className={verticalCenteredInFlex} code={'ru'} />
            Русский
          </Button>
        </Grid>
      </Grid>
    )}
  </LanguageConsumer>
);
