import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.APOLY_API_BASE_URL,
});

export const searchSymptomsDetailed = (
  filter,
  { limit, page, pharmacyId, medicineName, lang } = {}
) =>
  axiosInstance
    .get('/symptoms', {
      params: { ...filter, limit, page, pharmacyId, medicineName, lang },
    })
    .then(response => response.data);

export const searchSymptomsDetailedViaBodyRegion = (
  filter,
  { limit, page, pharmacyId, medicineName, lang } = {}
) =>
  axiosInstance
    .get('/bodyRegions/symptoms', {
      params: { ...filter, limit, page, pharmacyId, medicineName, lang },
    })
    .then(response => response.data);

export const searchAgents = (
  filter,
  { limit, page, pharmacyId, medicineName } = {}
) =>
  axiosInstance
    .get('/agents', {
      params: { ...filter, limit, page, pharmacyId, medicineName },
    })
    .then(response => response.data);

export const searchDosageForms = (
  filter,
  { limit, page, pharmacyId, medicineName } = {}
) =>
  axiosInstance
    .get('/dosageForms', {
      params: { ...filter, limit, page, pharmacyId, medicineName },
    })
    .then(response => response.data);

export const searchDosageFormGroups = (
  filter,
  { limit, page, pharmacyId, medicineName } = {}
) =>
  axiosInstance
    .get('/dosageFormGroups', {
      params: { ...filter, limit, page, pharmacyId, medicineName },
    })
    .then(response => response.data);

export const searchBodyRegions = (
  filter,
  { limit, page, pharmacyId, medicineName } = {}
) =>
  axiosInstance
    .get('/bodyRegions', {
      params: { ...filter, limit, page, pharmacyId, medicineName },
    })
    .then(response => response.data);

export const searchProducers = (
  filter,
  { limit, page, pharmacyId, medicineName } = {}
) =>
  axiosInstance
    .get('/producers', {
      params: { ...filter, limit, page, pharmacyId, medicineName },
    })
    .then(response => response.data);

export const searchProducerGroups = (
  filter,
  { limit, page, pharmacyId, medicineName } = {}
) =>
  axiosInstance
    .get('/producerGroups', {
      params: { ...filter, limit, page, pharmacyId, medicineName },
    })
    .then(response => response.data);

export const searchMedicineFlags = (
  filter,
  { limit, page, pharmacyId, medicineName } = {}
) =>
  axiosInstance
    .get('/medicineFlags', {
      params: { ...filter, limit, page, pharmacyId, medicineName },
    })
    .then(response => response.data);

export const searchExcludeDopingFlags = (
  filter,
  { limit, page, pharmacyId, medicineName } = {}
) =>
  axiosInstance
    .get('/excludeDopingFlags', {
      params: { ...filter, limit, page, pharmacyId, medicineName },
    })
    .then(response => response.data);

export const searchSymptomsQuickSearch = (
  name,
  { limit, orderByRating, ignoreGroups, lang, symptomIds } = {}
) =>
  axiosInstance
    .get('/symptoms/quickSearch', {
      params: {
        limit,
        orderByRating,
        ignoreGroups,
        lang,
        symptomIds,
        name,
      },
    })
    .then(response => response.data);

export const loadDefaultPharmacy = zip =>
  axiosInstance
    .get('/pharmacies', {
      params: { nearestOrDefault: true, zip },
    })
    .then(response => response.data[0]);

export const loadMedicineFromUrlCode = urlCode =>
  axiosInstance
    .get(`/medicines/urlCode=${urlCode}`)
    .then(response => response.data);

export const searchArticlesForPriceComparison = (
  { medicineId, packageId },
  { limit, page } = {}
) =>
  axiosInstance
    .get('/articles', { params: { medicineId, limit, page, packageId } })
    .then(response => response.data);

export const searchArticles = (
  pharmacyId,
  {
    filters,
    medicineName,
    medicineUrlCode,
    medicinePage,
    medicineLimit,
    articleIds,
    bodyRegionNames,
  } = {}
) =>
  axiosInstance
    .get(`/pharmacies/${pharmacyId}/articles`, {
      params: {
        medicineName,
        orderByRating: 1,
        ...filters,
        medicineUrlCode,
        medicinePage,
        medicineLimit,
        articleIds,
        bodyRegionNames,
      },
    })
    .then(response => response.data);

export const getMoreInformationAboutProduct = medicineId =>
  axiosInstance
    .get(`/medicines/${medicineId}/information`)
    .then(response => response.data);

export const getPackageInsertsForProduct = packageId =>
  axiosInstance
    .post(`/packages/${packageId}/packageInsertLink`)
    .then(response => response.data);

export const loadPharmacy = pharmacyId =>
  axiosInstance
    .get(`/pharmacies/${pharmacyId}`)
    .then(response => response.data);

export const loadPharmacyFromUrlCode = pharmacyUrlCode =>
  axiosInstance
    .get(`/pharmacies/urlCode=${pharmacyUrlCode}`)
    .then(response => response.data);

export const loadNoPartnerPharmacyFromUrlCode = noPartnerPharmacyUrlCode =>
  axiosInstance
    .get(`/noPartnerPharmacies/urlCode=${noPartnerPharmacyUrlCode}`)
    .then(response => response.data);

export const searchPharmaciesByZip = zip =>
  axiosInstance
    .get('/pharmacies', { params: { zip } })
    .then(response => response.data);

export const searchPharmaciesHolidays = ({ pharmacyIds, date, until }) =>
  axiosInstance
    .get('/pharmaciesHolidays', { params: { pharmacyIds, date, until } })
    .then(response => response.data);

export const searchNoPartnerPharmaciesByZip = zip =>
  axiosInstance
    .get('/noPartnerPharmacies', { params: { zip } })
    .then(response => response.data);

// todo korrekten endpounkt suchen
export const searchDefaultPharmacy = () =>
  axiosInstance.get('/pharmacies').then(response => response.data);

// TODO das mit nutzen irgendwann wenn die welt kein so furchtbarer ort ist und
// nicht mehr alles ausschließlich furcthbar
export const loadPureArticlesByRating = pharmacyId =>
  axiosInstance
    .get(`/pharmacies/${pharmacyId}/articles`, { params: { orderByRating: 1 } })
    .then(response => response.data);

export const loadPureArticlesWithSpecialOffer = pharmacyId =>
  axiosInstance
    .get(`/pharmacies/${pharmacyId}/articles`, {
      params: { onlySpecialOffers: 1 },
    })
    .then(response => response.data);

export const loadArticlesOrderedByRating = pharmacyId =>
  axiosInstance
    .get(`/pharmacies/${pharmacyId}/articles`, {
      params: { orderByRating: 1, medicineLimit: 5 },
    })
    .then(response => response.data);

export const loadTopOffersForPharmacy = pharmacyId =>
  axiosInstance
    .get(`/pharmacies/${pharmacyId}/articles`, {
      params: { onlySpecialOffers: 1 },
    })
    .then(response => response.data);

export const createRxOrder = rxOrder =>
  axiosInstance.post('/rxOrders', rxOrder).then(response => response.data);

export const uploadRxOrderImage = (rxOrderKey, formData) =>
  axiosInstance
    .post(`/rxOrders/rxOrderKey=${rxOrderKey}/images`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(response => response.data);

export const executeRxOrder = rxOrderKey =>
  axiosInstance
    .post(`/rxOrders/rxOrderKey=${rxOrderKey}/execute`)
    .then(response => response.data);

export const createOtcOrder = otcOrder =>
  axiosInstance
    .post('/otcOrders', { ...otcOrder, shopCountry: 'DE' })
    .then(response => response.data);

export const wirecardPaymentRequest = (
  otcOrderKey,
  finishedPaymentRedirectUrl,
  failedPaymentRedirectUrl
) =>
  axiosInstance
    .post(`/otcOrders/otcOrderKey=${otcOrderKey}/wirecardPaymentRequest`, {
      finishedPaymentRedirectUrl,
      failedPaymentRedirectUrl,
    })
    .then(response => response.data);

export const loadPageCategoryFromUrlCode = urlCode =>
  axiosInstance.get(`/categories/${urlCode}`).then(response => response.data);

export const getSentMailsForOtcOrder = otcOrderKey =>
  axiosInstance
    .get(`/otcOrders/otcOrderKey=${otcOrderKey}/mails`)
    .then(response => response.data);

export const sentMailForOtcOrder = (otcOrderKey, otcOrderMailType) =>
  axiosInstance
    .post(`/otcOrders/otcOrderKey=${otcOrderKey}/mails`, {
      mailType: otcOrderMailType,
    })
    .then(response => response.data);

export const getStatusForOtcOrder = otcOrderKey =>
  axiosInstance
    .get(`/otcOrders/otcOrderKey=${otcOrderKey}/otcOrderHistories`)
    .then(response => response.data);

export const getSentMailsForRxOrder = rxOrderKey =>
  axiosInstance
    .get(`/rxOrders/rxOrderKey=${rxOrderKey}/mails`)
    .then(response => response.data);

export const sentMailForRxOrder = (rxOrderKey, rxOrderMailType) =>
  axiosInstance
    .post(`/rxOrders/rxOrderKey=${rxOrderKey}/mails`, {
      mailType: rxOrderMailType,
    })
    .then(response => response.data);

export const getStatusForRxOrder = rxOrderKey =>
  axiosInstance
    .get(`/rxOrders/rxOrderKey=${rxOrderKey}/rxOrderHistories`)
    .then(response => response.data);

// test-call to user-api with Authorization-token
const toAuthToken = token => ({ Authorization: `Bearer ${token}` });

export const createRxOrderForUser = (userToken, userId, rxOrder) =>
  axiosInstance
    .post(`/users/${userId}/rxOrders`, rxOrder, {
      headers: toAuthToken(userToken),
    })
    .then(response => response.data);

export const getRxOrdersForUser = (
  userToken,
  userId,
  { page, limit, from, to }
) =>
  axiosInstance
    .get(`/users/${userId}/rxOrders`, {
      headers: toAuthToken(userToken),
      params: { page, limit, from, to },
    })
    .then(response => response.data);

export const getRxOrderByIdForUser = (userToken, userId, rxOrderId) =>
  axiosInstance
    .get(`/users/${userId}/rxOrders/${rxOrderId}`, {
      headers: toAuthToken(userToken),
    })
    .then(response => response.data);

export const uploadRxOrderImageForUser = (
  userToken,
  userId,
  rxOrderId,
  formData
) =>
  axiosInstance
    .post(`/users/${userId}/rxOrders/${rxOrderId}/images`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        ...toAuthToken(userToken),
      },
    })
    .then(response => response.data);

export const executeRxOrderForUser = (userToken, userId, rxOrderId) =>
  axiosInstance
    .post(`/users/${userId}/rxOrders/${rxOrderId}/execute`, null, {
      headers: toAuthToken(userToken),
    })
    .then(response => response.data);

export const sentMailForRxOrderForUser = (
  userToken,
  userId,
  rxOrderId,
  rxOrderMailType
) =>
  axiosInstance
    .post(
      `/users/${userId}/rxOrders/${rxOrderId}/mails`,
      { mailType: rxOrderMailType },
      {
        headers: toAuthToken(userToken),
      }
    )
    .then(response => response.data);

export const createOtcOrderForUser = (userToken, userId, otcOrder) =>
  axiosInstance
    .post(
      `/users/${userId}/otcOrders`,
      { ...otcOrder, shopCountry: 'de' },
      {
        headers: toAuthToken(userToken),
      }
    )
    .then(response => response.data);

export const getOtcOrdersForUser = (
  userToken,
  userId,
  { page, limit, from, to }
) =>
  axiosInstance
    .get(`/users/${userId}/otcOrders`, {
      headers: toAuthToken(userToken),
      params: { page, limit, from, to },
    })
    .then(response => response.data);

export const getOtcOrderByIdForUser = (userToken, userId, otcOrderId) =>
  axiosInstance
    .get(`/users/${userId}/otcOrders/${otcOrderId}`, {
      headers: toAuthToken(userToken),
    })
    .then(response => response.data);

export const wirecardPaymentRequestForUser = (
  userToken,
  userId,
  otcOrderId,
  finishedPaymentRedirectUrl,
  failedPaymentRedirectUrl
) =>
  axiosInstance
    .post(
      `/users/${userId}/otcOrders/${otcOrderId}/wirecardPaymentRequest`,
      { finishedPaymentRedirectUrl, failedPaymentRedirectUrl },
      { headers: toAuthToken(userToken) }
    )
    .then(response => response.data);

export const sentMailForOtcOrderForUser = (
  userToken,
  userId,
  otcOrderId,
  otcOrderMailType
) =>
  axiosInstance
    .post(
      `/users/${userId}/otcOrders/${otcOrderId}/mails`,
      { mailType: otcOrderMailType },
      {
        headers: toAuthToken(userToken),
      }
    )
    .then(response => response.data);

export const sendContactEmail = ({ email, name, message }) =>
  axiosInstance
    .post('/contactEmails', { email, name, message })
    .then(response => response.data);

export const logCookieConsent = () =>
  axiosInstance.post('/logCookieConsent').then(response => response.data);

export const dsgvoLogging = type =>
  axiosInstance.post('/dsgvoLogging', {
    type,
    cookies: document.cookie,
  });

export const symptomsForRegion = ({
  region,
  limit,
  page,
  lang,
  regionId,
  ...other
}) =>
  axiosInstance
    .get('/bodyRegions/symptomsForRegion', {
      params: { limit, page, region, regionId, lang, ...other },
    })
    .then(response => response.data);

export const articlesForBodyRegion = (
  pharmacyId,
  region,
  { limit, page, symptomId }
) =>
  axiosInstance
    .get('/bodyRegions/articlesForBodyRegion', {
      params: {
        limit,
        page,
        symptomId,
        pharmacyId,
        region,
      },
    })
    .then(response => response.data);
