import { WithIcon } from '@apoly-42/apoly-components';
import {
  faChevronDoubleRight,
  faFrown,
} from '@fortawesome/pro-regular-svg-icons';
import { Button, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { appMessages } from '../../app-utilities/appMessages';
import { addressToString } from '../../app-utilities/toStringUtils';
import { Address } from '../../app/apolyApi';
import { FluidTypography } from '../FluidTypography';
import { I18nMessage } from '../I18nMessage';

interface SelectAddressProps {
  addresses: Address[];
  onSubmit: (address: Address) => void;
  onBackButtonClick: () => void;
}

const messages = defineMessages({
  selectAddressLabel: {
    id: 'selectAddressLabel',
    defaultMessage: 'Wählen Sie Ihre Adresse aus',
  },
  addressNotFound: {
    id: 'addressNotFound',
    defaultMessage: 'Wir konnten Ihre Adresse leider nicht finden',
  },
  checkYourInput: {
    id: 'checkYourInput',
    defaultMessage:
      'Bitte überprüfen Sie Ihre Eingaben und geben Sie eine korrekte Straße ein.',
  },
  selectAddress: {
    id: 'selectAddress',
    defaultMessage: 'Adresse auswählen',
  },
});

export class SelectAddress extends React.PureComponent<SelectAddressProps> {
  state = {
    addressIndex: 0,
  };

  render() {
    const { onBackButtonClick, onSubmit, addresses } = this.props;

    return (
      <div style={{ height: '100%', display: 'grid', gridGap: 16 }}>
        {addresses.length > 0 ? (
          <React.Fragment>
            <div style={{ alignSelf: 'center' }}>
              <FluidTypography>
                <I18nMessage message={messages.selectAddressLabel} />
              </FluidTypography>
              <RadioGroup
                onChange={(_, value) =>
                  this.setState({ addressIndex: Number(value) })
                }
                value={this.state.addressIndex.toString()}
                name="adddresses"
              >
                {addresses.map((address, i) => (
                  <FormControlLabel
                    key={i}
                    value={i.toString()}
                    control={<Radio />}
                    label={addressToString(address)}
                  />
                ))}
              </RadioGroup>
            </div>
            <div style={{ alignSelf: 'flex-end', display: 'flex' }}>
              <Button onClick={onBackButtonClick}>
                <I18nMessage message={appMessages.editAddressInput} />
              </Button>
              <Button
                variant="raised"
                color="secondary"
                onClick={() => onSubmit(addresses[this.state.addressIndex])}
                style={{ marginLeft: 'auto' }}
              >
                <WithIcon icon={faChevronDoubleRight}>
                  <I18nMessage message={appMessages.selectAddress} />
                </WithIcon>
              </Button>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <FluidTypography>
              <WithIcon icon={faFrown}>
                <I18nMessage message={messages.checkYourInput} />
              </WithIcon>
            </FluidTypography>
            <Button
              style={{ alignSelf: 'flex-end' }}
              variant="raised"
              color="secondary"
              onClick={onBackButtonClick}
            >
              <I18nMessage message={appMessages.editAddressInput} />
            </Button>
          </React.Fragment>
        )}
      </div>
    );
  }
}
