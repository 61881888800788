import { I18nMessage } from '@apoly-42/apoly-components';
import { Grid } from '@material-ui/core';
import { css } from 'emotion';
import { equals } from 'ramda';
import React from 'react';
import { defineMessages } from 'react-intl';
import {
  blackWhiteFilter,
  flexAlignStart,
} from '../../../app-utilities/cssClasses';
import { cond, throwF } from '../../../app-utilities/fn-utils';
import { BRAND_NAME } from '../../../environment';
import cash from './assets/bar-w.png';
import mastercard from './assets/mastercard-w.png';
import paypal from './assets/paypal-w.png';
import sepa from './assets/sepa-w.png';
import sofort from './assets/sofort-ueberweisung-w.png';
import visa from './assets/visa-w.png';
import { FooterHeadline } from './FooterHeadline';

const getPaymentMethodImageForFooter = cond<string, string>(
  [
    [equals('cash'), () => cash],
    [equals('sofort'), () => sofort],
    [equals('visa'), () => visa],
    [equals('sepa'), () => sepa],
    [equals('mastercard'), () => mastercard],
    [equals('paypal'), () => paypal],
  ],
  throwF(val => `unknown payment ${val}`)
);

const image = css`
  max-width: 100%;
  margin: auto;
  ${blackWhiteFilter};
`;

const messages = defineMessages({
  possiblePaymentMethods: {
    id: 'possiblePaymentMethods',
    defaultMessage: 'possible payment Methods at {brand}',
  },
});

const possiblePaymentMethods = [
  'cash',
  'sofort',
  'visa',
  'sepa',
  'mastercard',
  'paypal',
];

export const PossiblePaymentMethods: React.SFC = () => (
  <Grid container={true} spacing={16}>
    <Grid item={true} xs={12}>
      <FooterHeadline>
        <I18nMessage
          message={messages.possiblePaymentMethods}
          values={{ brand: BRAND_NAME }}
        />
      </FooterHeadline>
    </Grid>
    <Grid item={true} xs={12}>
      <Grid container={true} spacing={16}>
        {possiblePaymentMethods.map(paymentMethod => (
          <Grid
            item={true}
            xs={3}
            key={paymentMethod}
            className={flexAlignStart}
          >
            <img
              className={image}
              alt={paymentMethod}
              src={getPaymentMethodImageForFooter(paymentMethod)}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  </Grid>
);
