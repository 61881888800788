import {
  createContainer,
  onMount,
  onPropsDidUpdate,
} from '@apoly-42/apoly-utils';
import { connect } from 'react-redux';
import {
  getShoppingCartFromLocalStorage,
  setShoppingCartToLocalStorage,
} from '../../../containers/apis/localStorage/shoppingCart';
import { overwriteShoppingCartState } from '../reducer';
import { selectShoppingCartState } from '../selectors';

const mapStateToProps = state => ({
  shoppingCart: selectShoppingCartState(state),
});

const SyncShoppingCartStateWithLocalStorage = createContainer(
  connect(
    mapStateToProps,
    { overwriteShoppingCartState }
  ),
  onMount(props => {
    const shoppingCartFromLocalStorage = getShoppingCartFromLocalStorage();

    if (shoppingCartFromLocalStorage) {
      props.overwriteShoppingCartState(getShoppingCartFromLocalStorage());
    }
  }),
  onPropsDidUpdate(['shoppingCart'], props =>
    setShoppingCartToLocalStorage(props.shoppingCart)
  )
);

export default SyncShoppingCartStateWithLocalStorage;
