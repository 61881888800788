import {
  faCamera,
  faEnvelope,
  faHome,
  faHospital,
  faInfoCircle,
  faSearch,
} from '@fortawesome/pro-solid-svg-icons';
import {
  AppBar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
} from '@material-ui/core';
import { css } from 'emotion';
import React from 'react';
import { defineMessages } from 'react-intl';
import { I18nMessage } from '../../../../app-util-components/I18nMessage';
import { appMessages } from '../../../../app-utilities/appMessages';
import { BRAND_NAME } from '../../../../environment';
import { CtxPathLink } from '../../../../url-utils';
import { Pharmacy } from '../../../apolyApi';
import { PharmacyContextValues } from '../../../PharmacyContext';
import {
  contactPathSetPharmacy,
  faqPath,
  pharmacyBase,
  submitPrescriptionPath,
} from '../../../routes';
import { PharmacyChannel } from '../../../routes-helper';
import { pharmacyCtxToPath } from '../../PharmacyBaseLink';
import { BrandName } from '../navbar/navBarComponents/BrandName';
import { ChangeLocaleDrawer } from './drawerComponents/ChangeLocaleDrawer';
import { CloseDrawerButton } from './drawerComponents/CloseDrawerButton';
import { ListItemButtonWithIcon } from './ListItemButtonWithIcon';
import { ListItemLinkWithIcon } from './ListItemLinkWithIcon';
import { PaymentMethods } from './PaymentMethods';

export const pharmacyDrawerMessages = defineMessages({
  changePharmacy: {
    id: 'changePharmacy',
    defaultMessage: 'change pharmacy',
  },
  searchForMedicine: {
    id: 'searchForMedicine',
    defaultMessage: 'search for medicine',
  },
  paymentMethods: {
    id: 'paymentMethods',
    defaultMessage: 'payment methods',
  },
});

export interface RightDrawerProps {
  pharmacy: Pharmacy | undefined;
  open: boolean;
  handleDrawer: () => void;
  toggleSearchBarAndDrawer: () => void;
  pharmacyCtx: PharmacyContextValues;
}

const drawer = css`
  min-width: 250px;
  max-width: 300px;
`;

export const RightDrawer: React.SFC<RightDrawerProps> = ({
  pharmacy,
  open,
  toggleSearchBarAndDrawer,
  handleDrawer,
  pharmacyCtx,
}) => (
  <Drawer anchor="right" open={open} onClose={handleDrawer}>
    <div tabIndex={0} role="button" onKeyDown={handleDrawer}>
      <AppBar position="static" color="primary">
        <Toolbar>
          <CtxPathLink
            style={{ margin: 'auto' }}
            noDeco={true}
            path={pharmacyCtxToPath(pharmacyBase, pharmacyCtx)}
          >
            <BrandName align="center" component="span" />
          </CtxPathLink>
        </Toolbar>
      </AppBar>
      <List className={drawer}>
        <CloseDrawerButton handleDrawer={handleDrawer} />
        <div>
          <ListItemLinkWithIcon
            text={BRAND_NAME}
            icon={faHospital}
            linkComponent={compPops => (
              <CtxPathLink
                {...compPops}
                path={pharmacyCtxToPath(pharmacyBase, pharmacyCtx)}
              />
            )}
          />
          <ListItem>
            <ListItemText
              inset={true}
              primary={
                <I18nMessage message={pharmacyDrawerMessages.paymentMethods} />
              }
            />
          </ListItem>
          <ListItem divider={true}>
            {pharmacy && (
              <ListItemText
                primary={
                  <PaymentMethods
                    availablePaymentMethods={pharmacy.paymentMethods}
                  />
                }
              />
            )}
          </ListItem>
        </div>
        <ListItemLinkWithIcon
          text={<I18nMessage message={appMessages.home} />}
          icon={faHome}
          linkComponent={compPops => (
            <CtxPathLink
              {...compPops}
              path={pharmacyCtxToPath(pharmacyBase, pharmacyCtx)}
            />
          )}
        />
        {/* gibts vorerst nicht, deswegen erstmal weg
        <ListItemLinkWithIcon
          text={<I18nMessage message={pharmacyDrawerMessages.changePharmacy} />}
          icon={faRetweet}
          linkProp={'find-pharmacy'}
        />*/}
        <ListItemButtonWithIcon
          text={
            <I18nMessage message={pharmacyDrawerMessages.searchForMedicine} />
          }
          icon={faSearch}
          onClickEvent={toggleSearchBarAndDrawer}
        />
        {pharmacyCtx.pharmacyChannel === PharmacyChannel.local && (
          <ListItemLinkWithIcon
            text={<I18nMessage message={appMessages.submitPrescription} />}
            icon={faCamera}
            linkComponent={compPops => (
              <CtxPathLink
                {...compPops}
                path={pharmacyCtxToPath(submitPrescriptionPath, pharmacyCtx)}
              />
            )}
          />
        )}
        <ListItemLinkWithIcon
          text={<I18nMessage message={appMessages.faq} />}
          icon={faInfoCircle}
          linkComponent={compPops => (
            <CtxPathLink
              {...compPops}
              path={pharmacyCtxToPath(faqPath, pharmacyCtx)}
            />
          )}
        />
        <ListItemLinkWithIcon
          text={<I18nMessage message={appMessages.contact} />}
          icon={faEnvelope}
          linkComponent={compPops => (
            <CtxPathLink
              {...compPops}
              path={pharmacyCtxToPath(contactPathSetPharmacy, pharmacyCtx)}
            />
          )}
        />
        <ListItem divider={true}>
          <ChangeLocaleDrawer />
        </ListItem>
      </List>
    </div>
  </Drawer>
);
