import { createContainer, onMount } from '@apoly-42/apoly-utils';
import PropTypes from 'prop-types';
import { tap } from 'ramda';
import { searchArticles } from '../../utilities/apis/apolyApi/apolyApi';
import {
  denormalizeArticleResponse,
  denormalizeMedicinesFromArticleResponse,
} from '../../utilities/apis/apolyApi/schemas/searchArticlesResponseSchema';
import { wrapHandlerWithGracefulLogging } from '../../utilities/wrapHandlerWithGracefulLogging';

const LoadArticlesContainer = createContainer(
  onMount(props => {
    props.setIsLoading(true);

    searchArticles(props.pharmacyId, { articleIds: props.articleIdArray })
      .then(({ result: { articles: articleIds }, entities }) => ({
        medicine: denormalizeMedicinesFromArticleResponse(
          entities,
          Object.keys(entities.medicines)
        ).medicines[0],
        articles: denormalizeArticleResponse(entities, articleIds).articles,
      }))
      .then(({ articles }) => tap(props.setArticles(articles)))
      .catch(tap(wrapHandlerWithGracefulLogging(props.setError)))
      .then(() => props.setIsLoading(false));
  })
);

LoadArticlesContainer.propTypes = {
  pharmacyId: PropTypes.number.isRequired,
  articleIdArray: PropTypes.array.isRequired,
  setArticles: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
};

export default LoadArticlesContainer;
