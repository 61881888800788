import { createAction, createReducer } from '@apoly-42/apoly-utils';
import { compose, prop } from 'ramda';
import { selectAppStateRoot } from '../selectors';

export const selectSubmitPrescriptionState = compose(
  prop('submitPrescription'),
  selectAppStateRoot
);

export const setSubmitPrescriptionStep = createAction(
  'setSubmitPrescriptionStep'
);
export const setPrescriptionPreviewImage = createAction(
  'setPrescriptionPreviewImage'
);
export const setPrescriptionFormData = createAction('setPrescriptionFormData');
export const clearPrescriptionImage = createAction('clearPrescriptionImage');
export const setPrescriptionNotes = createAction('setPrescriptionNotes');
export const setAskedUserForLogin = createAction('setAskedUserForLogin');

export const selectSubmitPrescriptionStep = compose(
  prop('submitPrescriptionStep'),
  selectSubmitPrescriptionState
);
export const selectPrescriptionPreviewImage = compose(
  prop('prescriptionPreviewImage'),
  selectSubmitPrescriptionState
);
export const selectPrescriptionFormData = compose(
  prop('prescriptionFormData'),
  selectSubmitPrescriptionState
);
export const selectPrescriptionNotes = compose(
  prop('prescriptionNotes'),
  selectSubmitPrescriptionState
);
export const selectAskedUserForLogin = compose(
  prop('askedUserForLogin'),
  selectSubmitPrescriptionState
);

const reducer = createReducer(
  {
    submitPrescriptionStep: 'loginRegister',
    prescriptionPreviewImage: null,
    prescriptionFormData: null,
    askedUserForLogin: false,
    prescriptionNotes: '',
  },
  {
    [setSubmitPrescriptionStep]: (state, { payload }) => ({
      ...state,
      submitPrescriptionStep: payload,
    }),
    [setPrescriptionPreviewImage]: (state, { payload }) => ({
      ...state,
      prescriptionPreviewImage: payload,
    }),
    [setPrescriptionFormData]: (state, { payload }) => ({
      ...state,
      prescriptionFormData: payload,
    }),
    [clearPrescriptionImage]: state => ({
      ...state,
      prescriptionPreviewImage: null,
      prescriptionFormData: null,
      prescriptionNotes: '',
    }),
    [setPrescriptionNotes]: (state, { payload }) => ({
      ...state,
      prescriptionNotes: payload,
    }),
    [setAskedUserForLogin]: (state, { payload }) => ({
      ...state,
      askedUserForLogin: payload || true,
    }),
  }
);

export default reducer;
