import { WithIcon } from '@apoly-42/apoly-components';
import {
  faCamera,
  faChevronDoubleRight,
} from '@fortawesome/pro-solid-svg-icons';
import { AppBar, Grid, Hidden, Toolbar } from '@material-ui/core';
import { css } from 'emotion';
import React from 'react';
import { InjectedIntlProps, injectIntl } from 'react-intl';
import { PharmacyThemeContext } from '../../app-theme/pharmacy-theme/pharmacyTheme';
import { I18nMessage } from '../../app-util-components/I18nMessage';
import { appMessages } from '../../app-utilities/appMessages';
import { flex, smUp } from '../../app-utilities/cssClasses';
import ShoppingCartButton from '../../deprecated/deprecated-apoly-app/components/ShoppingCartButton';
import { CtxPathLink } from '../../url-utils';
import { MuiBtnLink } from '../../url-utils/MuiBtnLink';
import { PharmacyValuesConsumer } from '../PharmacyContext';
import { pharmacyBase, submitPrescriptionPath } from '../routes';
import { PharmacyChannel } from '../routes-helper';
import { RightDrawer } from './navbar/drawer/RightDrawer';
import { BrandName } from './navbar/navbar/navBarComponents/BrandName';
import { FindMedicButton } from './navbar/navbar/navBarComponents/FindMedicButton';
import { ToggleDrawerButton } from './navbar/navbar/navBarComponents/ToggleDrawerButton';
import { SearchBar } from './navbar/navbar/SearchBar';
import { pharmacyCtxToPath } from './PharmacyBaseLink';
import pssLogoWhite from './navbar/PSS_logo_white.png';

interface State {
  drawerOpen: boolean;
  searchBarOpen: boolean;
}

const brandLink = css`
  margin: auto 8px;
  margin-left: -16px;
  ${smUp} {
    margin-left: 8px;
  }
`;

export const NavBar = injectIntl(
  class InnerNavBar extends React.Component<InjectedIntlProps, State> {
    state: State = {
      drawerOpen: false,
      searchBarOpen: false,
    };

    toggleDrawer = () => this.setState({ drawerOpen: !this.state.drawerOpen });
    /*
    showUserAvatar = (user: firebase.User | null) =>
      user ? <AvatarForTabbar user={user} /> : null;
*/
    toggleSearchBar = () =>
      this.setState({ searchBarOpen: !this.state.searchBarOpen });

    toggleSearchBarAndDrawer = () => [
      this.toggleDrawer(),
      this.toggleSearchBar(),
    ];

    render() {
      const { intl } = this.props;

      return (
        <PharmacyThemeContext>
          {pharmacyThemeCtx => (
            <PharmacyValuesConsumer>
              {pharmacyCtx => (
                <React.Fragment>
                  <AppBar position="static">
                    <Grid container={true} justify="center" spacing={0}>
                      <Grid item={true} xs={12} lg={10} style={{ flexGrow: 1 }}>
                        <Toolbar>
                          <CtxPathLink
                            className={brandLink}
                            noDeco={true}
                            path={pharmacyCtxToPath(pharmacyBase, pharmacyCtx)}
                          >
                            {pharmacyThemeCtx.logo ? (
                              <div style={{ display: 'flex' }}>
                                <img
                                  src={pharmacyThemeCtx.logo}
                                  style={{
                                    maxHeight: 32,
                                    margin: 'auto 8px',
                                    maxWidth: '50%',
                                  }}
                                />
                                <img
                                  src={pssLogoWhite}
                                  style={{
                                    maxHeight: 40,
                                    margin: 'auto 8px',
                                    maxWidth: '50%',
                                  }}
                                />
                              </div>
                            ) : (
                              <BrandName component="span" />
                            )}
                          </CtxPathLink>
                          <div style={{ marginLeft: 'auto' }} className={flex}>
                            <FindMedicButton
                              toggleSearchBar={this.toggleSearchBar}
                              disabled={this.state.searchBarOpen}
                            />
                            {pharmacyCtx.pharmacyChannel ===
                              PharmacyChannel.local && (
                              <Hidden smDown={true}>
                                <MuiBtnLink
                                  color="inherit"
                                  path={pharmacyCtxToPath(
                                    submitPrescriptionPath,
                                    pharmacyCtx
                                  )}
                                  aria-label={intl.formatMessage(
                                    appMessages.submitPrescription
                                  )}
                                >
                                  <WithIcon
                                    iconFontSize={16}
                                    icon={faCamera}
                                    iconLeft={true}
                                  >
                                    <WithIcon
                                      iconFontSize={16}
                                      icon={faChevronDoubleRight}
                                    >
                                      <I18nMessage
                                        message={appMessages.submitPrescription}
                                      />
                                    </WithIcon>
                                  </WithIcon>
                                </MuiBtnLink>
                              </Hidden>
                            )}
                            <ShoppingCartButton />
                            <ToggleDrawerButton
                              {...pharmacyCtx}
                              toggleDrawer={this.toggleDrawer}
                            />
                          </div>
                        </Toolbar>
                      </Grid>
                    </Grid>
                  </AppBar>
                  <RightDrawer
                    open={this.state.drawerOpen}
                    toggleSearchBarAndDrawer={this.toggleSearchBarAndDrawer}
                    handleDrawer={this.toggleDrawer}
                    pharmacy={pharmacyCtx.pharmacy}
                    pharmacyCtx={pharmacyCtx}
                  />
                  <SearchBar
                    open={this.state.searchBarOpen}
                    closeSearchBar={this.toggleSearchBar}
                  />
                </React.Fragment>
              )}
            </PharmacyValuesConsumer>
          )}
        </PharmacyThemeContext>
      );
    }
  }
);
