import * as React from 'react';
import { PharmacyValuesConsumer } from '../app/PharmacyContext';
import { both, first } from '../app-utilities/fn-utils';
import { PharmacyChannel } from '../app/routes-helper';
import { equals } from 'ramda';
import { Checkout } from '../app/pages/checkout/Checkout';
import { LoadCheckoutPage } from './deprecated-apoly-app/components/pages/LoadPagesAsync';
import { DeliveryType } from '../app/pages/checkout/OtcOrderTypes';

interface PharmacyCheckoutProps {}

export class PharmacyCheckout extends React.PureComponent<
  PharmacyCheckoutProps
> {
  renderCheckout = first<PharmacyChannel, React.ReactNode>(
    [
      both(equals(PharmacyChannel.pss), () => (
        <Checkout deliveryType={DeliveryType.pickUp} />
      )),
      both(equals(PharmacyChannel.customerBoard), () => (
        <Checkout deliveryType={DeliveryType.pickUp} />
      )),
      both(equals(PharmacyChannel.local), () => (
        <Checkout deliveryType={DeliveryType.pickUp} />
      )),
      both(equals(PharmacyChannel.mailOrder), () => (
        <Checkout deliveryType={DeliveryType.mailOrder} />
      )),
    ],
    () => <LoadCheckoutPage />
  );

  render() {
    return (
      <PharmacyValuesConsumer>
        {({ pharmacyChannel }) =>
          pharmacyChannel && this.renderCheckout(pharmacyChannel)
        }
      </PharmacyValuesConsumer>
    );
  }
}
