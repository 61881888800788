import { faFrownOpen } from '@fortawesome/pro-light-svg-icons';
import { Button } from '@material-ui/core';
import { cx } from 'emotion';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { FluidTypography } from '../app-util-components/FluidTypography';
import { I18nMessage } from '../app-util-components/I18nMessage';
import { SizedFaIcon } from '../app-util-components/SizedFaIcon';
import { appMessages } from '../app-utilities/appMessages';
import { contentContainer } from '../app-utilities/cssClasses';
import { errorContainerCss } from './EternalAppError';
import { DefaultPage } from './layout/DefaultPage';

interface AppErrorProps {}

const appErrorMessages = defineMessages({
  thatShouldNotHaveHappened: {
    id: 'thatShouldNotHaveHappened',
    defaultMessage: 'Sorry, that should not have happened!',
  },
  errorOccurred: {
    id: 'errorOccurred',
    defaultMessage: 'An error occurred.',
  },
  weInvestigateAndTryToSolveProblem: {
    id: 'weInvestigateAndTryToSolveProblem',
    defaultMessage:
      'We will investigate this Problem and try to resolve it as fast as possible.',
  },
  sorryForInconveniences: {
    id: 'sorryForInconveniences',
    defaultMessage: 'Please accept our apologies for the inconvenience caused.',
  },
  tryToRefreshPageOnError: {
    id: 'tryToRefreshPageOnError',
    defaultMessage:
      'Please try to reload this page. If the problem persists, try again later today or tomorrow.',
  },
});

export class AppError extends React.PureComponent<AppErrorProps> {
  render() {
    return (
      <DefaultPage>
        <div className={cx(contentContainer, errorContainerCss)}>
          <FluidTypography type="headline1" paragraph={true}>
            <I18nMessage message={appErrorMessages.thatShouldNotHaveHappened} />
          </FluidTypography>
          <FluidTypography
            type="headline3"
            style={{ display: 'flex' }}
            paragraph={true}
          >
            <I18nMessage message={appErrorMessages.errorOccurred} />{' '}
            <SizedFaIcon
              style={{ margin: 'auto 8px' }}
              fontSize={32}
              icon={faFrownOpen}
            />
          </FluidTypography>
          <FluidTypography paragraph={true}>
            <I18nMessage message={appErrorMessages.tryToRefreshPageOnError} />{' '}
            <I18nMessage
              message={appErrorMessages.weInvestigateAndTryToSolveProblem}
            />{' '}
            <I18nMessage message={appErrorMessages.sorryForInconveniences} />
          </FluidTypography>
          <Button
            onClick={() => window.location.reload(true)}
            variant="raised"
            color="secondary"
          >
            <I18nMessage message={appMessages.reloadPage} />
          </Button>
        </div>
      </DefaultPage>
    );
  }
}
