import { cond, equals } from 'ramda';
import { createDynamicContext } from 'react-dynamic-context';
import {
  parseQueryString,
  QsLanguage,
} from '../app-utilities/queryStringInterfaces';
import { StorageContextValues } from '../storage/StorageContext';
import { RfcUrl } from '../url';

export enum Languages {
  de = 'de',
  ar = 'ar',
  en = 'en',
  ru = 'ru',
  tr = 'tr',
}

export const LanguagesKeys: Languages[] = [];

// tslint:disable-next-line:forin
for (const key in Languages) {
  LanguagesKeys.push(Languages[key] as Languages);
}

export const langStringToLanguage = cond([
  [equals('de'), () => Languages.de],
  [equals('ar'), () => Languages.ar],
  [equals('en'), () => Languages.en],
  [equals('ru'), () => Languages.ru],
  [equals('tr'), () => Languages.tr],
]);

export interface LanguageContextProps {
  language: Languages;
}

const LanguageContext = createDynamicContext<LanguageContextProps>({
  language: Languages.de,
});

export const LanguageProvider = LanguageContext.Provider;

export const LanguageConsumer = LanguageContext.Consumer;
export const LanguageValues = LanguageContext.ValuesConsumer;

const languageFromLocalStorage = (localStorageValues: StorageContextValues) =>
  localStorageValues.language &&
  langStringToLanguage(localStorageValues.language);

const languageFromUrlQuery = (url: RfcUrl) => {
  if (url.query) {
    const queryObj = parseQueryString<QsLanguage>(url.query);

    return queryObj.lang && langStringToLanguage(queryObj.lang);
  }

  return undefined;
};

const languageFromBrowser = () => {
  if (window.navigator && window.navigator.language) {
    const lng = window.navigator.language.substr(0, 2);
    return langStringToLanguage(lng);
  }

  return undefined;
};

export const getLanguage = (
  url: RfcUrl,
  localStorageValues: StorageContextValues
) =>
  languageFromUrlQuery(url) ||
  languageFromLocalStorage(localStorageValues) ||
  languageFromBrowser();
