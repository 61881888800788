import { createAction, createReducer } from '@apoly-42/apoly-utils';
import { compose, defaultTo, prop } from 'ramda';
import { selectAppStateRoot } from '../selectors';

export const selectAuth = compose(
  prop('auth'),
  selectAppStateRoot
);

export const userLoggedIn = createAction('userLoggedIn');
export const userLoggedOut = createAction('userLoggedOut');

export const selectUser = compose(
  prop('user'),
  selectAuth
);
export const selectIsLoggedIn = compose(
  prop('isLoggedIn'),
  selectAuth
);
export const selectAuthIsLoaded = compose(
  prop('isLoaded'),
  selectAuth
);
export const selectUserDeliveryAddress = compose(
  prop('deliveryAddress'),
  selectAuth
);

const selectUserOrDefault = compose(
  defaultTo({}),
  selectUser
);
export const selectUserId = compose(
  prop('uid'),
  selectUserOrDefault
);

export default createReducer(
  {
    isLoggedIn: false,
    user: null,
    userId: '',
    isLoaded: false,
    deliveryAddress: {
      street: 'Bernhard-Göring-Straße',
      streetNumber: '162',
      zip: '04277',
      city: 'Leipzig',
      notes: '',
    },
  },
  {
    [userLoggedIn]: (state, { payload }) => ({
      ...state,
      isLoaded: true,
      isLoggedIn: true,
      user: payload,
    }),
    [userLoggedOut]: state => ({
      ...state,
      isLoaded: true,
      isLoggedIn: false,
      user: null,
    }),
  }
);
