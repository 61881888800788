import * as React from 'react';
import { rfcUrl, UrlConsumer, UrlContextValues, UrlType } from '../url';
import { Button } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';

interface MuiBtnLinkProps extends ButtonProps {
  path: string;
  query?: string;
  fragment?: string;
  urlType?: UrlType;
}


// die veriante mit dem render-rpop in mui-button geht nicht so gut da die komponente auch wieder einen consumer mit render-prop nutzt was irgendwas kaputt gemacht hat (klassen wurden nicht mehr korrekt zugewiesen)
export class MuiBtnLink extends React.PureComponent<MuiBtnLinkProps> {
  renderAnchor = (ctx: UrlContextValues) => {
    const {
      path,
      query,
      fragment,
      urlType = UrlType.major,
      onClick,
      ...rest
    } = this.props;

    const to = rfcUrl({ path, query, fragment }, ctx.urlBase, urlType);

    return (
      <Button
        component="a"
        href={to.toString()}
        onClick={e => {
          if (onClick) {
            onClick(e);
          }

          e.preventDefault();
          ctx.setUrl(to);
        }}
        {...rest}
      />
    );
  };

  render() {
    return <UrlConsumer>{this.renderAnchor}</UrlConsumer>;
  }
}
