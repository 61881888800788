import { FnItemTplVar, ItemTplVar } from '../tplTypes';
import {
  FnItemStage1,
  ItemTypes,
  Stage1Item,
  StrItemStage1,
} from './tplItemTypes';

function nextStartAfterPrev<Values>(prevItem: Stage1Item<Values> | undefined) {
  if (!prevItem) {
    return 0;
  }

  if (
    prevItem.possibleStart >= 0 &&
    prevItem.type === ItemTypes.StrItemStage1
  ) {
    return prevItem.possibleStart + prevItem.tplVar.length;
  }

  return prevItem.possibleStart;
}

function createFnItemStage1<Values>(
  tplVar: FnItemTplVar<Values>,
  prevItem: Stage1Item<Values> | undefined
): FnItemStage1<Values> {
  return {
    type: ItemTypes.FnItemStage1,
    tplVar,
    prevItem,
    possibleStart: nextStartAfterPrev(prevItem),
  };
}

function createStrItemStage1<Values>(
  str: string,
  tplVar: string,
  prevItem: Stage1Item<Values> | undefined
): StrItemStage1<Values> {
  const startAt = nextStartAfterPrev(prevItem);

  return {
    type: ItemTypes.StrItemStage1,
    possibleStart: startAt >= 0 ? str.indexOf(tplVar, startAt) : -1,
    tplVar,
    prevItem,
  };
}

function createStage1Item<Values>(
  str: string,
  tplVar: ItemTplVar<Values>,
  prevItem: Stage1Item<Values>
) {
  if (typeof tplVar === 'string') {
    return createStrItemStage1(str, tplVar, prevItem);
  }

  if (typeof tplVar === 'function') {
    return createFnItemStage1(tplVar, prevItem);
  }

  throw new Error('template must be of type string or function!');
}

export function createStage1Array<Values>(
  str: string,
  templateVars: ItemTplVar<Values>[]
) {
  let prevItem: Stage1Item<Values>;

  return templateVars.map(template => {
    prevItem = createStage1Item(str, template, prevItem);
    return prevItem;
  });
}
