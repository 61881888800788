import React from 'react';
import { I18nMessage } from '../../app-util-components/I18nMessage';
import { PharmacySlider } from '../../app/landingPage/PharmacySlider';
import {
  veritasLandingPageSliderImages,
  veritasLogo,
} from '../../assets/veritas/veritasImages';
import { PharmacyTheme } from './PharmacyThemeInterface';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  veritasLandingSlogan: {
    id: 'veritasLandingSlogan',
    defaultMessage: 'Willkommen bei Ihrer Veritas-Apotheke',
  },
});

const landingSlogan = <I18nMessage message={messages.veritasLandingSlogan} />;

const VeritasPanoramaComponent: React.SFC = props => (
  <PharmacySlider sliderImages={veritasLandingPageSliderImages}>
    {props.children}
  </PharmacySlider>
);

export const veritasCustomisations: Partial<PharmacyTheme> = {
  palette: {
    primary: {
      light: '#e9ffff',
      main: '#b7cdd1',
      dark: '#879ca0',
      contrastText: '#000000',
    },
    secondary: {
      light: '#fff6e9',
      main: '#d1c3b7',
      dark: '#a09387',
      contrastText: '#000000',
    },
  },
  logo: veritasLogo,
  pharmacyLogo: veritasLogo,
  PanoramaComponent: VeritasPanoramaComponent,
  landingSlogan,
};

/*
prev secondary: {
      light: '#ffa270',
      main: '#ff7043',
      dark: '#c63f17',
      contrastText: '#ffffff',
   }
 */

export const veritasPharmacyCode = process.env.CUSTOM_PHARMACIES_VERITAS;
