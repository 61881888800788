import { createContainer, onMount } from '@apoly-42/apoly-utils';
import firebase from 'firebase/app';
import PropTypes from 'prop-types';

const ObserveLoginStateContainer = createContainer(
  onMount(props =>
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        props.userLoggedIn(user);
      } else {
        props.userLoggedOut();
      }

      props.setIsLoading(false);
    })
  )
);

ObserveLoginStateContainer.propTypes = {
  setIsLoading: PropTypes.func.isRequired,
  userLoggedIn: PropTypes.func.isRequired,
  userLoggedOut: PropTypes.func.isRequired,
};

export default ObserveLoginStateContainer;
