import { switchF } from '@apoly-42/apoly-utils';
import PropTypes from 'prop-types';
import { defineMessages, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { selectGermanServerTimeMoment } from '../../redux/serverTime/serverTime';

const messages = defineMessages({
  pharmacyIsOpenWithHours: {
    id: 'pharmacyIsOpenWithHours',
    defaultMessage:
      'open, closes in approx. {numberOfHours, plural, one {one hour} other {{numberOfHours} hours}} (at {closesAt})',
  },
  pharmacyIsOpenWithMinutes: {
    id: 'pharmacyIsOpenWithMinutes',
    defaultMessage:
      'open, closes soon (in about {numberOfMinutes, plural, one {one minute} other {{numberOfMinutes} minutes}} at {closesAt})',
  },
  pharmacyIsClosedOpensWithHours: {
    id: 'pharmacyIsClosedOpensWithHours',
    defaultMessage:
      'closed, opens in approx. {numberOfHours, plural, one {one hour} other {{numberOfHours} hours}} (at {opensAt})',
  },
  pharmacyIsClosedOpensWithMinutes: {
    id: 'pharmacyIsClosedOpensWithMinutes',
    defaultMessage:
      'closed, opens in about {numberOfMinutes, plural, one {one minute} other {{numberOfMinutes} minutes}} (at {opensAt})',
  },
  pharmacyIsClosed: {
    id: 'pharmacyIsClosed',
    defaultMessage: 'closed, opens next {weekday} ({date}) at {opensAt}',
  },
});

const openedMessage = (intl, moment, openingToMoment) => {
  const diffHours = openingToMoment.diff(moment, 'hours', true);
  const diffMinutes = openingToMoment.diff(moment, 'minutes');

  const i18nValues = {
    numberOfHours: Math.round(diffHours),
    numberOfMinutes: diffMinutes,
    closesAt: openingToMoment.format('LT'),
  };

  const write = message => intl.formatMessage(message, i18nValues);

  return switchF(
    [() => diffHours >= 1, () => write(messages.pharmacyIsOpenWithHours)],
    [() => true, () => write(messages.pharmacyIsOpenWithMinutes)]
  );
};

const closedMessage = (intl, moment, openingFromMoment) => {
  const isSameDay = moment.isSame(openingFromMoment, 'day');
  const diffHours = openingFromMoment.diff(moment, 'hours', true);
  const diffMinutes = openingFromMoment.diff(moment, 'minutes');

  const i18nValues = {
    numberOfHours: Math.round(diffHours),
    numberOfMinutes: diffMinutes,
    opensAt: openingFromMoment.format('LT'),
    weekday: openingFromMoment.format('dddd'),
    date: openingFromMoment.format('L'),
  };

  const write = message => intl.formatMessage(message, i18nValues);

  return switchF(
    [
      () => isSameDay && diffHours >= 1,
      () => write(messages.pharmacyIsClosedOpensWithHours),
    ],
    [() => isSameDay, () => write(messages.pharmacyIsClosedOpensWithMinutes)],
    [() => true, () => write(messages.pharmacyIsClosed)]
  );
};

const InnerPharmacyOpeningTimeSpan = props =>
  switchF(
    [
      () => props.isOpen,
      () =>
        openedMessage(
          props.intl,
          props.germanServerTimeMoment,
          props.openingToMoment
        ),
    ],
    [
      () => !props.isOpen,
      () =>
        closedMessage(
          props.intl,
          props.germanServerTimeMoment,
          props.openingFromMoment
        ),
    ]
  );

const mapStateToProps = state => ({
  germanServerTimeMoment: selectGermanServerTimeMoment(state),
});

const enhance = compose(
  injectIntl,
  connect(mapStateToProps)
);

const PharmacyOpeningTimeSpan = enhance(InnerPharmacyOpeningTimeSpan);

PharmacyOpeningTimeSpan.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  openingFromMoment: PropTypes.object.isRequired,
  openingToMoment: PropTypes.object.isRequired,
};

InnerPharmacyOpeningTimeSpan.propTypes = {
  germanServerTimeMoment: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  ...PharmacyOpeningTimeSpan.propTypes,
};

export default PharmacyOpeningTimeSpan;
