export function both<T, R = any>(fn1: (t: T) => any, fn2: (t: T) => R) {
  return (t: T) => fn1(t) && fn2(t);
}

export function prop<T extends {}>(key: keyof T) {
  return (o: T) => o[key];
}

type Fn<T, R> = (val: T) => R;
type CondPair<T, R> = [(val: T) => boolean, Fn<T, R>];

export function cond<T, R>(pairs: CondPair<T, R>[]): Fn<T, R | undefined>;
export function cond<T, R>(pairs: CondPair<T, R>[], def: Fn<T, R>): Fn<T, R>;
export function cond<T, R>(pairs: CondPair<T, R>[], fallback?: Fn<T, R>) {
  const len = pairs.length;

  return (val: T) => {
    for (let i = 0; i < len; i++) {
      if (pairs[i][0](val)) {
        return pairs[i][1](val);
      }
    }

    return fallback ? fallback(val) : undefined;
  };
}

// set a return-type to use it correctly for escaping
export function throwImmediately<T= any>(message?: string): T {
  throw new Error(message);
}

export function throwF<T>(message?: string | ((val: T) => string)) {
  return (val: T) => {
    const msg = typeof message === 'function' ? message(val) : message;
    throw new Error(msg);
  };
}

export function tap<T>(fn: Fn<T, any>) {
  return (val: T) => {
    fn(val);
    return val;
  };
}

export function multiTap<T>(...fns: Fn<T, any>[]) {
  return (val: T) => {
    fns.forEach(fn => fn(val));
    return val;
  };
}

export function first<T, R>(pairs: Fn<T, boolean | R>[]): Fn<T, R | undefined>;
export function first<T, R>(
  pairs: Fn<T, boolean | R>[],
  def: Fn<T, R>
): Fn<T, R>;
export function first<T, R>(fns: Fn<T, boolean | R>[], defaultFn?: Fn<T, R>) {
  const len = fns.length;

  return (val: T) => {
    for (let i = 0; i < len; i++) {
      const res = fns[i](val);
      if (res) {
        return res;
      }
    }

    return defaultFn ? defaultFn(val) : undefined;
  };
}

export function objToArray<T>(obj: { [key: string]: T }) {
  return Object.keys(obj).map(key => obj[key]);
}
