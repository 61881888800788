import moment, { Moment } from 'moment-timezone';
import * as React from 'react';
import ReactInterval from 'react-interval';
import { BERLIN_TIME_ZONE } from '../environment';
import { getServerTime } from './apolyApi';
import { AppConsumer } from './AppContext';

interface SyncServerTimeProps {
  setServerTime: (mom: Moment) => void;
}

export class InnerSyncServerTime extends React.PureComponent<
  SyncServerTimeProps
> {
  componentDidMount() {
    getServerTime()
      .then(res => res.data.serverTime)
      .then(serverTime => moment.tz(serverTime, BERLIN_TIME_ZONE))
      .then(this.props.setServerTime)
      // tslint:disable-next-line:no-console
      .catch(console.error);
  }

  render() {
    return null;
  }
}

// tslint:disable-next-line:max-classes-per-file
export class SyncServerTime extends React.Component {
  state = {
    count: 1,
  };

  inc = () => this.setState({ count: this.state.count + 1 });

  render() {
    // allle 6 sek neu laden
    return (
      <React.Fragment>
        <ReactInterval timeout={60000} enabled={true} callback={this.inc} />
        <AppConsumer>
          {appCtx => (
            <InnerSyncServerTime
              key={this.state.count}
              setServerTime={appCtx.serverTime.setValue}
            />
          )}
        </AppConsumer>
      </React.Fragment>
    );
  }
}
