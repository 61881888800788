import { denormalize, schema } from 'normalizr';

const agentSchema = new schema.Entity('agents');
const dosageFormSchema = new schema.Entity('dosageForms');
const producerSchema = new schema.Entity('producers');
const unitSchema = new schema.Entity('units');
const symptomSchema = new schema.Entity('symptoms');

const medicineAgentSchema = new schema.Entity('medicineAgents', {
  agent: agentSchema,
  unit: unitSchema,
});

const medicineSchema = new schema.Entity('medicines', {
  medicineAgents: [medicineAgentSchema],
  producers: [producerSchema],
  symptoms: [symptomSchema],
});

const packageSchema = new schema.Entity('packages', {
  dosageForm: dosageFormSchema,
  medicine: medicineSchema,
});

const articleSchema = new schema.Entity('articles', {
  package: packageSchema,
});

const searchArticleResponseSchema = {
  agents: [agentSchema],
  dosageForms: [dosageFormSchema],
  producers: [producerSchema],
  units: [unitSchema],
  symptoms: [symptomSchema],
  medicineAgents: [medicineAgentSchema],
  medicines: [medicineSchema],
  packages: [packageSchema],
  articles: [articleSchema],
};

export const denormalizeArticleResponse = (entities, articleIds) =>
  denormalize({ articles: articleIds }, searchArticleResponseSchema, entities);

export const denormalizeMedicinesFromArticleResponse = (
  entities,
  medicineIds
) =>
  denormalize(
    { medicines: medicineIds },
    searchArticleResponseSchema,
    entities
  );

export default searchArticleResponseSchema;
