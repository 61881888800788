import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import { FormikFieldState } from 'formik-fields';
import * as React from 'react';

export interface MuiFormikTextFieldProps extends TextFieldProps {
  field: FormikFieldState<string | number | undefined>;
}

export class MuiFormikTextField extends React.PureComponent<
  MuiFormikTextFieldProps
> {
  render() {
    const { field, ...rest } = this.props;

    return (
      <TextField
        name={field.name}
        value={field.value}
        onChange={field.handleChange}
        onBlur={field.setIsTouched}
        error={field.isTouched && Boolean(field.error)}
        helperText={field.isTouched && field.error}
        {...rest}
      />
    );
  }
}
