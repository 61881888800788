import { Dialog, DialogContent } from '@material-ui/core';
import firebase from 'firebase/app';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { FluidTypography } from '../../../app-util-components/FluidTypography';
import { I18nMessage } from '../../../app-util-components/I18nMessage';
import { objToArray } from '../../../app-utilities/fn-utils';
import { selectProducts } from '../../../deprecated/deprecated-apoly-app/redux/shoppingCart/selectors';
import { getPostalCost } from '../../../deprecated/deprecated-apoly-app/utilities/delivery/deliveryUtils';
import { UrlConsumer } from '../../../url';
import {
  Address,
  CashOtcOrder,
  DeliveryType,
  EnhancedPharmacy,
  OtcOrder,
  PaymentMethod,
  ShoppingCartItem,
} from '../../apolyApi';
import { PharmacyValuesConsumer } from '../../PharmacyContext';
import { PharmacyChannel } from '../../routes-helper';
import {
  CheckoutValues,
  CompletedCheckoutDefault,
  CompletedCheckoutOffizin,
  CompletedCheckoutPss,
} from './checkoutTypes';
import { DeliveryType as DeliveryTypeEnum } from './OtcOrderTypes';
import { SubmitGuestOrder } from './submitOtcOrder/SubmitGuestOrder';
import { SubmitUserOrder } from './submitOtcOrder/SubmitUserOrder';

type ReduxShoppingCartProducts = { [key: string]: ShoppingCartItem };

interface PssOrderAdditionalTypes {
  products: ReduxShoppingCartProducts;
  enhancedPharmacy: EnhancedPharmacy;
}

const priceFromArticles = (products: ReduxShoppingCartProducts) =>
  objToArray(products).reduce(
    (sum, product) => product.count * Number(product.articlePrice) + sum,
    0
  );

const shoppingCartFromProducs = (
  products: ReduxShoppingCartProducts,
  deliveryPrice = 0
) => ({
  price: priceFromArticles(products) + Number(deliveryPrice),
  items: objToArray(products),
});

const checkoutPssToOrder = (
  { email, billingAddress, paymentMethod }: CompletedCheckoutPss,
  { enhancedPharmacy, products }: PssOrderAdditionalTypes
): OtcOrder => {
  const res = {
    shoppingCart: shoppingCartFromProducs(products),
    buyer: {
      email,
      name: billingAddress ? billingAddress.name : undefined,
      phone: undefined,
    },
    pharmacyId: enhancedPharmacy.pharmacy.pharmacyId,
    deliveryType: 'pss_vending_machine' as DeliveryType,
    shippingAddress: undefined,
    billingAddress,
    courierTimeSpanId: undefined,
  };

  // magie mit gleichen checks wird benötigt damit TS-check vernünftig funktioniert mit verschiednen paymentMethods
  if (paymentMethod === PaymentMethod.cash) {
    return {
      ...res,
      paymentType: paymentMethod,
    };
  }

  return {
    ...res,
    paymentType: paymentMethod,
  };
};

const checkoutOffizinToOrder = (
  { billingAddress }: CompletedCheckoutOffizin,
  { enhancedPharmacy, products }: PssOrderAdditionalTypes
): CashOtcOrder => ({
  shoppingCart: shoppingCartFromProducs(products),
  buyer: {
    email: 'test.user@apoly.eu', // todo dummy-email entfernen
    name: billingAddress ? billingAddress.name : undefined,
    phone: undefined,
  },
  paymentType: PaymentMethod.cash,
  pharmacyId: enhancedPharmacy.pharmacy.pharmacyId,
  deliveryType: 'pss_vending_machine_reserve' as DeliveryType,
  shippingAddress: undefined,
  billingAddress,
  courierTimeSpanId: undefined,
});

const getName = (a?: Address) => (a ? a.name : undefined);

export const toDeliveryType = (delType: DeliveryTypeEnum): DeliveryType => {
  if (delType === DeliveryTypeEnum.pickUp) {
    return 'pickUp';
  }

  if (delType === DeliveryTypeEnum.courier) {
    return 'courier';
  }

  if (delType === DeliveryTypeEnum.mailOrder) {
    return 'postal';
  }

  if (delType === DeliveryTypeEnum.pssReservation) {
    return 'pss_vending_machine_reserve';
  }

  throw new Error(`unkown deliveryTypeEnum: ${delType}`);
};

const getDelPrice = (
  delPrice: number,
  deliveryType: DeliveryTypeEnum,
  deliveryAddress?: Address
) => {
  if (
    deliveryType === DeliveryTypeEnum.pickUp ||
    deliveryType === DeliveryTypeEnum.pssReservation
  ) {
    return 0;
  }

  if (deliveryType === DeliveryTypeEnum.courier) {
    return delPrice;
  }

  if (!deliveryAddress) {
    throw new Error('FUUUUUUU');
  }

  return getPostalCost(deliveryAddress.country);
};

const checkoutDefaultToOrder = (
  {
    billingAddress,
    telephone,
    deliveryAddress,
    email,
    deliveryType,
    paymentMethod,
  }: CompletedCheckoutDefault,
  { enhancedPharmacy, products }: PssOrderAdditionalTypes
): OtcOrder => {
  const delPrice = enhancedPharmacy.nextCourierTimeSpans[0].price;

  const res = {
    shoppingCart: shoppingCartFromProducs(
      products,
      getDelPrice(delPrice, deliveryType, deliveryAddress)
    ),
    buyer: {
      email,
      name: getName(billingAddress || deliveryAddress),
      phone: telephone,
    },
    pharmacyId: enhancedPharmacy.pharmacy.pharmacyId,
    deliveryType: toDeliveryType(deliveryType),
    shippingAddress: deliveryAddress,
    billingAddress,
    courierTimeSpanId:
      enhancedPharmacy.nextCourierTimeSpans[0].courierTimeSpanId,
  };

  // magie mit gleichen checks wird benötigt damit TS-check vernünftig funktioniert mit verschiednen paymentMethods
  if (paymentMethod === PaymentMethod.cash) {
    return {
      ...res,
      paymentType: paymentMethod,
    };
  }

  return {
    ...res,
    paymentType: paymentMethod,
  };
};

const checkoutsToOder = (
  checkoutValues: CheckoutValues,
  addData: PssOrderAdditionalTypes
) => {
  if (checkoutValues.channel === PharmacyChannel.pss) {
    return checkoutPssToOrder(checkoutValues, addData);
  }

  if (checkoutValues.channel === PharmacyChannel.customerBoard) {
    return checkoutOffizinToOrder(checkoutValues, addData);
  }

  if (checkoutValues.channel === PharmacyChannel.local) {
    return checkoutDefaultToOrder(checkoutValues, addData);
  }

  throw new Error('not implemented');
};

interface Props {
  checkoutValues: CheckoutValues;
}

interface InnerProps extends Props {
  products: ReduxShoppingCartProducts;
}

const messages = defineMessages({
  orderIsPosting: {
    id: 'orderIsPosting',
    defaultMessage: 'Ihr Bestellung wird momentan übermittelt...',
  },
  doNotReloadPage: {
    id: 'doNotReloadPage',
    defaultMessage:
      'Sie werden automatisch weitergeleitet, bitte laden Sie die Seite nicht neu.',
  },
});

export const CheckoutSubmitOrder = connect(state => ({
  products: selectProducts(state),
}))(
  class InnerCheckoutSubmitOrder extends React.PureComponent<InnerProps> {
    orderFromProps = (enhancedPharmacy: EnhancedPharmacy) => {
      const { checkoutValues, products } = this.props;

      return checkoutsToOder(checkoutValues, { products, enhancedPharmacy });
    };

    render() {
      return (
        <Dialog
          open={true}
          disableBackdropClick={true}
          disableEscapeKeyDown={true}
        >
          <DialogContent style={{ maxWidth: 500 }}>
            <FluidTypography type="headline3" paragraph={true} align="center">
              <I18nMessage message={messages.orderIsPosting} />
            </FluidTypography>

            <UrlConsumer>
              {({ urlBase }) =>
                urlBase && (
                  <PharmacyValuesConsumer>
                    {({ pharmacy, pharmacyChannel, enhancedPharmacy }) => {
                      if (!pharmacy || !pharmacyChannel || !enhancedPharmacy) {
                        return undefined;
                      }

                      const user = firebase.auth().currentUser;
                      return user ? (
                        <SubmitUserOrder
                          pharmacy={pharmacy}
                          pharmacyChannel={pharmacyChannel}
                          order={this.orderFromProps(enhancedPharmacy)}
                          user={user}
                          urlBase={urlBase}
                        />
                      ) : (
                        <SubmitGuestOrder
                          pharmacy={pharmacy}
                          pharmacyChannel={pharmacyChannel}
                          order={this.orderFromProps(enhancedPharmacy)}
                          urlBase={urlBase}
                        />
                      );
                    }}
                  </PharmacyValuesConsumer>
                )
              }
            </UrlConsumer>

            <FluidTypography type="body2" paragraph={true} align="center">
              <I18nMessage message={messages.doNotReloadPage} />
            </FluidTypography>
          </DialogContent>
        </Dialog>
      );
    }
  }
);
