import * as React from 'react';
import {
  PharmacyThemeProvider,
  pharmacyUrlCodeToPharmacyTheme,
} from '../app-theme/pharmacy-theme/pharmacyTheme';
import { PharmacyShopRoutes } from '../deprecated/PharmacyShopRoutes';
import { build } from '../tpl/tpl';
import { AppConsumer } from './AppContext';
import { AppMuiTheme } from './layout/AppMuiTheme';
import { LoadPharmacy } from './LoadPharmacy';
import { PharmacyConsumer } from './PharmacyContext';
import { pharmacyBase, PharmacyBasePathProps } from './routes';
import { routeToChannel } from './routes-helper';

export class PharmacyBase extends React.PureComponent<PharmacyBasePathProps> {
  render() {
    const { pharmacyUrlCode, pharmacyChannel } = this.props;

    const pharmacyTheme = pharmacyUrlCodeToPharmacyTheme(pharmacyUrlCode);

    const channel = routeToChannel(pharmacyChannel);

    return (
      <PharmacyThemeProvider value={pharmacyTheme}>
        <AppConsumer>
          {appCtx => (
            <PharmacyConsumer>
              {pharmacyCtx => (
                <LoadPharmacy
                  pharmacyUrlCode={this.props.pharmacyUrlCode}
                  setEnhancedPharmacy={pharmacyCtx.enhancedPharmacy.setValue}
                  setPharmacy={pharmacyCtx.pharmacy.setValue}
                  setPharmacyIsLoading={pharmacyCtx.pharmacyIsLoading.setValue}
                  serverTime={appCtx.serverTime.value}
                  setPharmacyChannel={pharmacyCtx.pharmacyChannel.setValue}
                  pharmacyChannelFromRoute={this.props.pharmacyChannel}
                />
              )}
            </PharmacyConsumer>
          )}
        </AppConsumer>

        <AppMuiTheme palette={pharmacyTheme.palette}>
          <PharmacyConsumer>
            {ctx =>
              ctx.enhancedPharmacy.value && (
                <PharmacyShopRoutes
                  basePath={build(pharmacyBase, {
                    pharmacyUrlCode,
                    pharmacyChannel,
                  })}
                  pharmacyChannel={channel}
                />
              )
            }
          </PharmacyConsumer>
        </AppMuiTheme>
      </PharmacyThemeProvider>
    );
  }
}
