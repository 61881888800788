import { equals, cond } from 'ramda';
import { POSTAL_COST } from '../../constants';
import { countryIsoCode2IsPartOfEurope } from '../countrys';

export const DELIVERY_COSTS_DE_POSTAL = 3.9;
export const DELIVERY_COSTS_AT = 8.99;
export const DELIVERY_COSTS_EUROPE = 15.99;

const getNationalDeliveryPrice = (enhancedPharmacy, transmission) =>
  transmission !== 'postal'
    ? enhancedPharmacy.nextCourierTimeSpans.length > 0
      ? enhancedPharmacy.nextCourierTimeSpans[0].price
      : null
    : DELIVERY_COSTS_DE_POSTAL;

const getInternationalDeliveryPrice = deliveryCountry =>
  deliveryCountry === 'DE' ? DELIVERY_COSTS_AT : DELIVERY_COSTS_EUROPE;

const getDeliveryPrice = (enhancedPharmacy, deliveryCountry, transmission) =>
  deliveryCountry === 'AT'
    ? getNationalDeliveryPrice(enhancedPharmacy, transmission)
    : getInternationalDeliveryPrice(deliveryCountry);

export const deliveryPriceFromEnhancedPharmacy = (
  enhancedPharmacy,
  deliveryCountry = 'AT',
  transmission
) =>
  countryIsoCode2IsPartOfEurope(deliveryCountry)
    ? getDeliveryPrice(enhancedPharmacy, deliveryCountry, transmission)
    : null;

export const getPostalCost = cond([
  [equals('AT'), () => POSTAL_COST],
  [equals('DE'), () => DELIVERY_COSTS_AT],
  [true, () => DELIVERY_COSTS_EUROPE],
]);
