import { Typography } from '@material-ui/core';
import { css } from 'emotion';
import React from 'react';
import { defineMessages } from 'react-intl';
import { I18nMessage } from '../../../app-util-components/I18nMessage';
import { appMessages } from '../../../app-utilities/appMessages';
import { build } from '../../../tpl/tpl';
import { CtxPathLink } from '../../../url-utils';
import { PharmacyContextValues } from '../../PharmacyContext';
import { agbPath, dataSecurityPath, imprintPath } from '../../routes';
import { pharmacyCtxToPath } from '../PharmacyBaseLink';

const container = css`
  display: flex;
  margin: auto;
  @media (max-width: 600px) {
    display: inline;
  }
`;

const link = css`
  margin: 0 8px;
  text-decoration: none;
  color: #fff;
  &:hover {
    text-decoration: underline;
  }
`;

const messages = defineMessages({
  agbDownload: {
    id: 'agbDownload',
    defaultMessage: 'conditions of use & sale (pdf-download)',
  },
});

export const LawAgreements: React.SFC<PharmacyContextValues> = props => (
  <div className={container}>
    <a href={build(agbPath, {})} target="_blank" className={link}>
      <Typography component="span" className={link}>
        <I18nMessage message={messages.agbDownload} />
      </Typography>
    </a>
    <CtxPathLink
      path={pharmacyCtxToPath(dataSecurityPath, props)}
      className={link}
    >
      <Typography component="span">
        <I18nMessage message={appMessages.dataSecurity} />
      </Typography>
    </CtxPathLink>
    <CtxPathLink path={pharmacyCtxToPath(imprintPath, props)} className={link}>
      <Typography component="span" className={link}>
        <I18nMessage message={appMessages.imprint} />
      </Typography>
    </CtxPathLink>
  </div>
);
