import * as React from 'react';
import { Omit } from 'utility-types';
import { RfcUrl } from '../url/rfcUrl';
import { UrlConsumer, UrlContextValues } from '../url/UrlContext';

export type CtxLinkBase = Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  'href'
>;

export class CtxLink extends React.PureComponent<CtxLinkBase & { to: RfcUrl }> {
  renderAnchor = (ctx: UrlContextValues) => {
    const { to, onClick, ...rest } = this.props;

    return (
      <a
        href={to.toString()}
        onClick={e => {
          if (onClick) {
            onClick(e);
          }

          e.preventDefault();
          ctx.setUrl(to);
        }}
        {...rest}
      />
    );
  };

  render() {
    return <UrlConsumer>{this.renderAnchor}</UrlConsumer>;
  }
}
