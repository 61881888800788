import { Divider, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import ShoppingCartFooter from './ShoppingCartFooter';
import ShoppingCartTable from './shoppingCartTable/ShoppingCartTable';

const ShoppingCartView = ({ submit, pharmacyId }) => (
  <Grid container spacing={16}>
    <Grid item xs={12}>
      <ShoppingCartTable pharmacyId={pharmacyId} />
    </Grid>
    <Grid item xs={12}>
      <Divider />
    </Grid>
    <Grid item xs={12}>
      <ShoppingCartFooter submit={submit} />
    </Grid>
  </Grid>
);

ShoppingCartView.propTypes = {
  submit: PropTypes.func,
  pharmacyId: PropTypes.number.isRequired,
};
ShoppingCartView.defaultProps = {
  submit: null,
};

export default ShoppingCartView;
