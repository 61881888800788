import { FormattedEuro, I18nMessage } from '@apoly-42/apoly-components';
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
} from '@material-ui/core';
import React from 'react';
import { commonMessages } from '../../../deprecated/deprecated-apoly-app/constants/messages/commonMessages';
import { PharmacyValuesConsumer } from '../../PharmacyContext';
import { DeliveryType } from './OtcOrderTypes';

export interface SelectDeliveryTypeProps {
  deliveryType: DeliveryType;
  setDeliveryType: (deliveryType: DeliveryType) => void;
  disableDelivery: boolean;
}

export const SelectDeliveryType: React.SFC<SelectDeliveryTypeProps> = ({
  deliveryType,
  setDeliveryType,
  disableDelivery,
}) => (
  <PharmacyValuesConsumer>
    {({ enhancedPharmacy }) =>
      enhancedPharmacy && (
        <div style={{ display: 'flex' }}>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Radio
                    checked={deliveryType === DeliveryType.pickUp}
                    onChange={() => setDeliveryType(DeliveryType.pickUp)}
                    value="pickUp"
                    name="receiveProducts"
                  />
                }
                label={<I18nMessage message={commonMessages.pickUp} />}
              />
              <FormControlLabel
                control={
                  <Radio
                    checked={deliveryType === DeliveryType.pssReservation}
                    onChange={() =>
                      setDeliveryType(DeliveryType.pssReservation)
                    }
                    value="pssReservation"
                    name="receiveProducts"
                  />
                }
                label={<I18nMessage message={commonMessages.pssDelivery} />}
              />
              {!disableDelivery && (
                <FormControlLabel
                  control={
                    <Radio
                      checked={deliveryType === DeliveryType.courier}
                      onChange={() => setDeliveryType(DeliveryType.courier)}
                      value="courier"
                      name="receiveProducts"
                    />
                  }
                  label={
                    <I18nMessage
                      message={commonMessages.deliveryForExtraCharge}
                      values={{
                        price: (
                          <FormattedEuro
                            value={Number(
                              enhancedPharmacy.nextCourierTimeSpans[0].price
                            )}
                          />
                        ),
                      }}
                    />
                  }
                />
              )}
            </FormGroup>
          </FormControl>
        </div>
      )
    }
  </PharmacyValuesConsumer>
);
