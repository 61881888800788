export const SUPPORT_MAIL_NAME = 'support';
export const PARTNER_MAIL_NAME = 'partner';

export const BRAND_NAME = process.env.REACT_APP_BRAND_NAME;
export const E_MAIL_DOMAIN = process.env.REACT_APP_E_MAIL_DOMAIN;

export const BERLIN_TIME_ZONE = 'Europe/Berlin';

export const PSS_DEMO_PACKAGE_ID = 452930;
export const PSS_DEM0_PHARMACY_PACKAGE_ID = 252347;
