import { Grid } from '@material-ui/core';
import React from 'react';
import { I18nMessage } from '../../../app-util-components/I18nMessage';
import { UrlList } from '../../../app-util-components/UrlList';
import { appMessages } from '../../../app-utilities/appMessages';
import { BRAND_NAME } from '../../../environment';
import {
  PharmacyContextValues,
  PharmacyValuesConsumer,
} from '../../PharmacyContext';
import {
  contactPathSetPharmacy,
  faqPath,
  jobsPath,
  pharmacyBase,
  pressPath,
} from '../../routes';
import { pharmacyCtxToPath } from '../PharmacyBaseLink';
import { FooterHeadline } from './FooterHeadline';

const getLinkListArray = (pharmacyCtx: PharmacyContextValues) => [
  {
    text: <I18nMessage message={appMessages.linkToLandingPage} />,
    url: pharmacyCtxToPath(pharmacyBase, pharmacyCtx),
  },
  {
    text: <I18nMessage message={appMessages.faq} />,
    url: pharmacyCtxToPath(faqPath, pharmacyCtx),
  },
  {
    text: <I18nMessage message={appMessages.contact} />,
    url: pharmacyCtxToPath(contactPathSetPharmacy, pharmacyCtx),
  },
  // aktuell nur für veritas => da mu
  /*{
    text: <I18nMessage message={appMessages.pressAndPartners} />,
    url: pharmacyCtxToPath(pressPath, pharmacyCtx),
  },
  {
    text: (
      <I18nMessage
        message={appMessages.jobsAtBrand}
        values={{ brand: BRAND_NAME }}
      />
    ),
    url: pharmacyCtxToPath(jobsPath, pharmacyCtx),
  },*/
];

export const MoreInformation: React.SFC = () => (
  <Grid container={true} spacing={16}>
    <Grid item={true} xs={12}>
      <FooterHeadline>
        <I18nMessage message={appMessages.moreInformation} />
      </FooterHeadline>
    </Grid>
    <PharmacyValuesConsumer>
      {pharmacyCtx => (
        <Grid item={true} xs={12} style={{ color: '#fff' }}>
          <UrlList linkList={getLinkListArray(pharmacyCtx)} />
        </Grid>
      )}
    </PharmacyValuesConsumer>
  </Grid>
);
