import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Divider, Grid, Hidden, Typography } from '@material-ui/core';
import { css, cx } from 'emotion';
import React from 'react';
import { appGrid, flex, gridArea, smUp } from '../app-utilities/cssClasses';
import { PharmacyValuesConsumer } from './PharmacyContext';
import PharmacyOpeningTime from '../deprecated/deprecated-apoly-app/components/pharmacyOpeningTImes/PharmacyOpeningTime';
import PharmacyLogoDefault from '../deprecated/deprecated-apoly-app/components/pharmacyLogoDefault/PharmacyLogoDefault';
import { PharmacyThemeContext } from '../app-theme/pharmacy-theme/pharmacyTheme';

const PharmacyWithLogo = css`
  ${appGrid};
  grid-template-columns: 16.66% 1fr;
  grid-template-areas: 'text text';
  ${smUp} {
    grid-template-areas: 'image text';
  }
`;

const pharmacyCard = css`
  margin-left: 2px;
  padding: 16px;
  max-width: 90%;
  @media (max-width: 600px) {
    padding: 8px;
  }
`;

const logo = css`
  margin: auto;
  padding: 16px;
  display: block;
  max-width: 100%;
  max-height: 120px;
`;

export const LocalPharmacyCard: React.SFC = () => (
  <PharmacyValuesConsumer>
    {({ pharmacy, enhancedPharmacy }) =>
      enhancedPharmacy &&
      pharmacy && (
        <Card className={pharmacyCard}>
          <div className={PharmacyWithLogo}>
            <Hidden xsDown>
              <div className={cx(gridArea('image'), flex)}>
                <PharmacyThemeContext>
                  {pharmacyThemeCtx => (
                    <img src={pharmacyThemeCtx.pharmacyLogo} className={logo} />
                  )}
                </PharmacyThemeContext>
              </div>
            </Hidden>
            <div className={gridArea('text')}>
              <Grid
                container
                spacing={16}
                style={{ height: '100%' }}
                alignItems="center"
                alignContent="center"
              >
                <Grid item xs={12}>
                  <Typography variant="title">{pharmacy.name}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    {pharmacy.address.street}, {pharmacy.address.zip}{' '}
                    {pharmacy.address.city}
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
          <Grid container spacing={16}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              {enhancedPharmacy && (
                <Typography>
                  <FontAwesomeIcon icon={faClock} style={{ marginRight: 8 }} />
                  <PharmacyOpeningTime
                    enhancedNextOpeningTimes={enhancedPharmacy.nextOpeningTimes}
                    isOpen={enhancedPharmacy.isOpen}
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
        </Card>
      )
    }
  </PharmacyValuesConsumer>
);
