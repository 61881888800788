import { Typography } from '@material-ui/core';
import * as React from 'react';
import { I18nMessage } from '../../../app-util-components/I18nMessage';
import { commonMessages } from '../../../deprecated/deprecated-apoly-app/constants/messages/commonMessages';
import { getPaymentMethodImage } from '../../../deprecated/deprecated-apoly-app/utilities/paymentMethod/paymentMethodsUtilities';
import { PaymentMethod } from '../../apolyApi';

interface PaymentSummaryProps {
  paymentMethod: PaymentMethod;
}

export class PaymentSummary extends React.PureComponent<PaymentSummaryProps> {
  render() {
    const { paymentMethod } = this.props;

    return (
      <div style={{ display: 'flex', height: '100%', width: '100%' }}>
        <img
          style={{ margin: 'auto', maxHeight: 40 }}
          src={getPaymentMethodImage(paymentMethod)}
          alt={paymentMethod}
        />
        {paymentMethod === 'cash' && (
          <Typography style={{ margin: 'auto 8px' }} variant="title">
            <I18nMessage message={commonMessages.cash} />
          </Typography>
        )}
      </div>
    );
  }
}
