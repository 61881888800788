import { I18nMessage } from '@apoly-42/apoly-components';
import { Typography } from '@material-ui/core';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import React from 'react';
import { mdUp, xsDown } from '../../../app-utilities/cssClasses';
import { commonMessages } from '../constants/messages/commonMessages';
import { getPaymentMethodImage } from '../utilities/paymentMethod/paymentMethodsUtilities';

export const paddingBot = css`
  ${mdUp} {
    padding-bottom: 16px;
  }
  ${xsDown} {
    padding-bottom: 16px;
  }
`;

const PaymentMethodImage = ({ availablePaymentMethod, textAside }) => (
  <div
    className={availablePaymentMethod === 'cash' ? paddingBot : undefined}
    style={{
      display: 'flex',
      flexDirection: textAside ? 'row' : 'column',
      alignItems: 'center',
      height: '100%',
      width: '100%',
    }}
  >
    <img
      src={getPaymentMethodImage(availablePaymentMethod)}
      alt={availablePaymentMethod}
      style={{ maxWidth: '100%', maxHeight: '100%', margin: 'auto' }}
    />
    {availablePaymentMethod === 'cash' && (
      <Typography style={{ marginTop: 8 }} align="center" variant="title">
        <I18nMessage message={commonMessages.cash} />
      </Typography>
    )}
  </div>
);

PaymentMethodImage.propTypes = {
  availablePaymentMethod: PropTypes.string.isRequired,
  textAside: PropTypes.bool,
};

PaymentMethodImage.defaultProps = {
  textAside: false,
};

export default PaymentMethodImage;
