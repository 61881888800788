import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Input, InputAdornment } from '@material-ui/core';
import React from 'react';

const renderAutoSuggestInput = inputProps => {
  const { classes, autoFocus, value, ref, ...other } = inputProps;

  return (
    <Card className={classes.searchBox}>
      <Input
        fullWidth
        autoFocus={autoFocus}
        className={classes.textField}
        value={value}
        inputRef={ref}
        id="search"
        type="search"
        disableUnderline
        startAdornment={
          <InputAdornment position="start">
            <div className={classes.icon}>
              <FontAwesomeIcon icon={faSearch} />
            </div>
          </InputAdornment>
        }
        {...other}
      />
    </Card>
  );
};

export default renderAutoSuggestInput;
