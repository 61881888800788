import * as React from 'react';
import { RenderChildrenProp } from '../../app-utilities/reactUtilTypes';
import { RfcUrl } from '../../url/rfcUrl';
import { UrlConsumer, UrlContextValues } from '../../url/UrlContext';

export interface UrlContextValuesWithRequiredBase extends UrlContextValues {
  urlBase: RfcUrl;
}

export class RequiredBaseUrlConsumer extends React.PureComponent<
  RenderChildrenProp<UrlContextValuesWithRequiredBase>
> {
  renderWithRequiredBase = (ctx: UrlContextValues) => {
    if (ctx.urlBase === undefined) {
      throw new Error(
        'You tried to use RequiredBaseUrlConsumer but no UrlContextProvider above the current element is available.'
      );
    }

    // @ts-ignore
    return this.props.children(ctx);
  };

  render() {
    return <UrlConsumer>{this.renderWithRequiredBase}</UrlConsumer>;
  }
}
