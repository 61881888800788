import objectAssign from 'object-assign';
import Promise from 'promise/lib/es6-extensions';
import { URLPonyfill } from '../url-utils/URLPonyfill';

if (!global.Promise) {
  global.Promise = Promise;
}

if (!Object.assign) {
  Object.assign = objectAssign;
}

const correctUrl =
  global.URL && global.URL.prototype && 'href' in global.URL.prototype;

if (!correctUrl) {
  global.URL = URLPonyfill;
}
