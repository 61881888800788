import { find } from 'ramda';
import { Address } from '../../apolyApi';
import { AddressFromInput } from '../../../app-utilities/toStringUtils';

// addressToGoogleType
const addrToGType = {
  street: 'route',
  zip: 'postal_code',
  city: 'locality',
  streetNumber: 'street_number',
  country: 'country',
};

const compForType = (
  addressComponents: google.maps.GeocoderAddressComponent[],
  type: string
) => find(comp => comp.types.indexOf(type) >= 0, addressComponents);

const toLongName = (addressComp?: google.maps.GeocoderAddressComponent) =>
  addressComp ? addressComp.long_name : undefined;

const toShortName = (addressComp?: google.maps.GeocoderAddressComponent) =>
  addressComp ? addressComp.short_name : undefined;

const toStreet = (addressComponents: google.maps.GeocoderAddressComponent[]) =>
  [
    toLongName(compForType(addressComponents, addrToGType.street)),
    toLongName(compForType(addressComponents, addrToGType.streetNumber)),
  ]
    .filter(Boolean)
    .join(' ');

const googleAddressToAddressObject = ({
  address_components,
  geometry,
}: google.maps.GeocoderResult): Partial<Address> => ({
  street: toStreet(address_components),
  zip: toLongName(compForType(address_components, addrToGType.zip)),
  city: toLongName(compForType(address_components, addrToGType.city)),
  country: toShortName(compForType(address_components, addrToGType.country)),
  latitude: geometry.location.lat(),
  longitude: geometry.location.lng(),
});

export const toAddress = (
  googleAddress: Partial<Address>,
  { name, notes }: AddressFromInput
): Address | undefined => {
  return googleAddress.street &&
    googleAddress.zip &&
    googleAddress.city &&
    googleAddress.latitude &&
    googleAddress.longitude &&
    googleAddress.country
    ? ({ ...googleAddress, name, notes } as Address)
    : undefined;
};

export const checkAddress = (
  partialAddress: Partial<Address>
): Address | undefined => {
  return partialAddress.street &&
    partialAddress.zip &&
    partialAddress.city &&
    partialAddress.latitude &&
    partialAddress.longitude &&
    partialAddress.country &&
    partialAddress.name
    ? partialAddress as Address
    : undefined;
};

export default googleAddressToAddressObject;
