import { logErrorGracefully } from './failGracefully';

const ERROR_TEXT =
  'googles dataLayer-object is not defined, but tried to push in it';

const googleDataLayerPush = data => {
  if (!window.dataLayer) {
    if (process.env.NODE_ENV === 'production') {
      logErrorGracefully(new Error(ERROR_TEXT));
      return;
    }
  }

  window.dataLayer.push(data);
};

export default googleDataLayerPush;
