import * as React from 'react';
import { logErrorGracefully } from '../app-utilities/failGracefully';

interface ErrorBoundaryProps {
  component: React.ComponentType<{ error?: Error }>;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = {
    hasError: false,
  };

  componentDidCatch(error: Error, info: React.ErrorInfo) {
    if (process.env.NODE_ENV === 'development') {
      // tslint:disable-next-line:no-console
      console.error(error, info);
    }

    error.message = `${error.message}; React.ErrorInfo.componentStack: ${
      info.componentStack
    }`;

    logErrorGracefully(error);

    this.setState({ hasError: true });
  }

  toggleHasError = () => this.setState({ hasError: !this.state.hasError });

  render() {
    if (this.state.hasError) {
      const Component = this.props.component;

      return (
        <React.Fragment>
          {process.env.NODE_ENV === 'development' && (
            <div
              style={{
                display: 'flex',
                alignContent: 'center',
                flexDirection: 'column',
              }}
            >
              <h1>DEV-Error! see logs! :/</h1>
              <button
                style={{ padding: '16px 80px' }}
                onClick={this.toggleHasError}
              >
                reset app
              </button>
              <hr />
            </div>
          )}

          <Component />
        </React.Fragment>
      );
    }

    return this.props.children;
  }
}
