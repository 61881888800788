import renderProductSuggestion from './renderProductSuggestion';
import renderSymptomSuggestion from './renderSymptomSuggestion';

const renderSuggestion = (suggestion, { query, isHighlighted }) => {
  const bigAvatar = {
    margin: 16,
    marginRight: 24,
    width: 40,
    height: 40,
  };
  return suggestion.url
    ? renderProductSuggestion(suggestion, bigAvatar, { query, isHighlighted })
    : renderSymptomSuggestion(suggestion, bigAvatar, { query, isHighlighted });
};

export default renderSuggestion;
