export interface WirecardRequestFromApi {
  merchantAccountId: string;
  wirecardRequestId: string;
  requestTimeStamp: string;
  paymentMethod: string;
  transactionType: string;
  requestedAmount: string;
  requestedAmountCurrency: string;
  locale: string;
  redirectUrl: string;
  requestSignature: string;
  ipAddress: string;
  cardType: string;
  mandateId: string;
  mandateSignedDate: string;
  creditorId: string;
  cancelRedirectUrl: string;
  successRedirectUrl: string;
  descriptor: string;
  attemptThreeD: string;
}

export interface WirecardRequest {
  merchant_account_id: string;
  request_id: string;
  request_time_stamp: string;
  payment_method: string;
  transaction_type: string;
  requested_amount: string;
  requested_amount_currency: string;
  locale: string;
  redirect_url: string;
  request_signature: string;
  ip_address: string;
  card_type: string;
  mandate_mandate_id: string;
  mandate_signed_date: string;
  creditor_id: string;
  cancel_redirect_url: string;
  success_redirect_url: string;
  descriptor: string;
  attempt_three_d: string;
}

export const toWcRequest = (
  wirecardRequestFromServer: WirecardRequestFromApi
): WirecardRequest => ({
  merchant_account_id: wirecardRequestFromServer.merchantAccountId,
  request_id: wirecardRequestFromServer.wirecardRequestId,
  request_time_stamp: wirecardRequestFromServer.requestTimeStamp,
  payment_method: wirecardRequestFromServer.paymentMethod,
  transaction_type: wirecardRequestFromServer.transactionType,
  requested_amount: wirecardRequestFromServer.requestedAmount,
  requested_amount_currency: wirecardRequestFromServer.requestedAmountCurrency,
  locale: wirecardRequestFromServer.locale,
  redirect_url: wirecardRequestFromServer.redirectUrl,
  request_signature: wirecardRequestFromServer.requestSignature,
  ip_address: wirecardRequestFromServer.ipAddress,
  card_type: wirecardRequestFromServer.cardType,
  mandate_mandate_id: wirecardRequestFromServer.mandateId, // doppeltes mandate_mandate ist korrekt!
  mandate_signed_date: wirecardRequestFromServer.mandateSignedDate, // doppeltes mandate_mandate ist korrekt!
  creditor_id: wirecardRequestFromServer.creditorId,
  cancel_redirect_url: wirecardRequestFromServer.cancelRedirectUrl,
  success_redirect_url: wirecardRequestFromServer.successRedirectUrl,
  descriptor: wirecardRequestFromServer.descriptor,
  attempt_three_d: wirecardRequestFromServer.attemptThreeD,
});
