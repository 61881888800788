import { defineMessages } from 'react-intl';

export const formMessages = defineMessages({
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  namePlaceholder: {
    id: 'namePlaceholder',
    defaultMessage: 'John Doe',
  },
  emailPlaceholder: {
    id: 'emailPlaceholder',
    defaultMessage: 'john.doe@example.com',
  },
  email: {
    id: 'email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'password',
    defaultMessage: 'Password',
  },
  repeatPassword: {
    id: 'repeatPassword',
    defaultMessage: 'Repeat password',
  },
  street: {
    id: 'street',
    defaultMessage: 'street',
  },
  telephone: {
    id: 'telephone',
    defaultMessage: 'telephone',
  },
  streetPlaceholder: {
    id: 'streetPlaceholder',
    defaultMessage: 'wall street',
  },
  houseNumber: {
    id: 'houseNumber',
    defaultMessage: 'house nr.',
  },
  zipCode: {
    id: 'zipCode',
    defaultMessage: 'zip code',
  },
  city: {
    id: 'city',
    defaultMessage: 'city',
  },
  cityPlaceholder: {
    id: 'cityPlaceholder',
    defaultMessage: 'springfield',
  },
  medicSearchPlaceholder: {
    id: 'medicSearchPlaceholder',
    defaultMessage: 'insert medicine name or PZN',
  },
  yourMessage: {
    id: 'yourMessage',
    defaultMessage: 'your message',
  },
  yourMessagePlaceholder: {
    id: 'yourMessagePlaceholder',
    defaultMessage: 'Insert your message here...',
  },
  search: {
    id: 'search',
    defaultMessage: 'search',
  },
  insertAddressOrZip: {
    id: 'insertAddressOrZip',
    defaultMessage: 'insert your address or zip',
  },
  noEmptyField: {
    id: 'noEmptyField',
    defaultMessage: 'This field is not allowed to be empty!',
  },
  noValidEmail: {
    id: 'noValidEmail',
    defaultMessage: 'This email is invalid!',
  },
  notes: {
    id: 'notes',
    defaultMessage: 'Notes',
  },
  fax: {
    id: 'fax',
    defaultMessage: 'Fax',
  },
});

export default formMessages;
