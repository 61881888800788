const europeanCountries = [
  { countryName: 'Albania', isoCode2: 'AL', isoCode3: 'ALB' },
  { countryName: 'Andorra', isoCode2: 'AD', isoCode3: 'AND' },
  { countryName: 'Armenia', isoCode2: 'AM', isoCode3: 'AND' },
  { countryName: 'Austria', isoCode2: 'AT', isoCode3: 'AUT' },
  { countryName: 'Azerbaijan', isoCode2: 'AZ', isoCode3: 'AZE' },
  { countryName: 'Belarus', isoCode2: 'BY', isoCode3: 'BLR' },
  { countryName: 'Belgium', isoCode2: 'BE', isoCode3: 'BEL' },
  { countryName: 'Bosnia and Herzegovina', isoCode2: 'BA', isoCode3: 'BIH' },
  { countryName: 'Bulgaria', isoCode2: 'BG', isoCode3: 'BGR' },
  { countryName: 'Croatia', isoCode2: 'HR', isoCode3: 'HRV' },
  { countryName: 'Cyprus', isoCode2: 'CY', isoCode3: 'CYP' },
  { countryName: 'Czech Republic', isoCode2: 'CZ', isoCode3: 'CZE' },
  { countryName: 'Denmark', isoCode2: 'DK', isoCode3: 'DNK' },
  { countryName: 'Estonia', isoCode2: 'EE', isoCode3: 'EST' },
  { countryName: 'Faroe Islands', isoCode2: 'FO', isoCode3: 'FRO' },
  { countryName: 'Finland', isoCode2: 'FI', isoCode3: 'FIN' },
  { countryName: 'France', isoCode2: 'FR', isoCode3: 'FRA' },
  { countryName: 'Georgia', isoCode2: 'GE', isoCode3: 'GEO' },
  { countryName: 'Germany', isoCode2: 'DE', isoCode3: 'DEU' },
  { countryName: 'Gibraltar', isoCode2: 'GI', isoCode3: 'GIB' },
  { countryName: 'Greece', isoCode2: 'GR', isoCode3: 'GRC' },
  { countryName: 'Hungary', isoCode2: 'HU', isoCode3: 'HUN' },
  { countryName: 'Iceland', isoCode2: 'IS', isoCode3: 'ISL' },
  { countryName: 'Ireland', isoCode2: 'IE', isoCode3: 'IRL' },
  { countryName: 'Isle of Man', isoCode2: 'IM', isoCode3: 'IMN' },
  { countryName: 'Italy', isoCode2: 'IT', isoCode3: 'ITA' },
  { countryName: 'Kazakhstan', isoCode2: 'KZ', isoCode3: 'KAZ' },
  { countryName: 'Latvia', isoCode2: 'LV', isoCode3: 'LVA' },
  { countryName: 'Liechtenstein', isoCode2: 'LI', isoCode3: 'LIE' },
  { countryName: 'Lithuania', isoCode2: 'LT', isoCode3: 'LTU' },
  { countryName: 'Luxembourg', isoCode2: 'LU', isoCode3: 'LUX' },
  { countryName: 'Macedonia', isoCode2: 'MK', isoCode3: 'MKD' },
  { countryName: 'Malta', isoCode2: 'MT', isoCode3: 'MLT' },
  { countryName: 'Moldova', isoCode2: 'MD', isoCode3: 'MDA' },
  { countryName: 'Monaco', isoCode2: 'MC', isoCode3: 'MCO' },
  { countryName: 'Montenegro', isoCode2: 'ME', isoCode3: 'MNE' },
  { countryName: 'Netherlands', isoCode2: 'NL', isoCode3: 'NLD' },
  { countryName: 'Norway', isoCode2: 'NO', isoCode3: 'NOR' },
  { countryName: 'Poland', isoCode2: 'PL', isoCode3: 'POL' },
  { countryName: 'Portugal', isoCode2: 'PT', isoCode3: 'PRT' },
  { countryName: 'Romania', isoCode2: 'RO', isoCode3: 'ROU' },
  { countryName: 'Russia', isoCode2: 'RU', isoCode3: 'RUS' },
  { countryName: 'San Marino', isoCode2: 'SM', isoCode3: 'SMR' },
  { countryName: 'Serbia', isoCode2: 'RS', isoCode3: 'SRB' },
  { countryName: 'Slovakia', isoCode2: 'SK', isoCode3: 'SVK' },
  { countryName: 'Slovenia', isoCode2: 'SI', isoCode3: 'SVN' },
  { countryName: 'Spain', isoCode2: 'ES', isoCode3: 'ESP' },
  { countryName: 'Sweden', isoCode2: 'SE', isoCode3: 'SWE' },
  { countryName: 'Switzerland', isoCode2: 'CH', isoCode3: 'CHE' },
  { countryName: 'Turkey', isoCode2: 'TR', isoCode3: 'TUR' },
  { countryName: 'Ukraine', isoCode2: 'UA', isoCode3: 'UKR' },
  { countryName: 'United Kingdom', isoCode2: 'GB', isoCode3: 'GBR' },
  { countryName: 'Vatican city', isoCode2: 'VA', isoCode3: 'VAT' },
];

export const countryIsoCode2IsPartOfEurope = isoCode2 =>
  europeanCountries.filter(country => country.isoCode2 === isoCode2).length > 0;
