import * as React from 'react';
import { RenderChildrenProp } from '../app-utilities/reactUtilTypes';
import { UrlPathConsumer } from '../url-utils/index';

export class RequiredUrlPathConsumer extends React.PureComponent<
  RenderChildrenProp<string>
> {
  renderRoute = (route: string | undefined) =>
    route === undefined ? 'loading...' : this.props.children(route);

  render() {
    return <UrlPathConsumer>{this.renderRoute}</UrlPathConsumer>;
  }
}
