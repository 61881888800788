import { Grid } from '@material-ui/core';
import { css } from 'emotion';
import React from 'react';
import { FluidTypography } from '../../../../app-util-components/FluidTypography';
import {
  flex,
  flexAlignStart,
  verticalCenteredInFlex,
} from '../../../../app-utilities/cssClasses';

import { equals } from 'ramda';
import { defineMessages } from 'react-intl';
import { I18nMessage } from '../../../../app-util-components/I18nMessage';
import { appMessages } from '../../../../app-utilities/appMessages';
import { cond } from '../../../../app-utilities/fn-utils';
import cash from './assets/bar.png';
import mastercard from './assets/mastercard.png';
import paypal from './assets/paypal.png';
import sepa from './assets/sepa.png';
import sofort from './assets/sofort-ueberweisung.png';
import visa from './assets/visa.png';

const messages = defineMessages({
  paymentCash: {
    id: 'paymentCash',
    defaultMessage: 'cash',
  },
  paymentSofort: {
    id: 'paymentSofort',
    defaultMessage: 'sofort',
  },
  paymentVisa: {
    id: 'paymentVisa',
    defaultMessage: 'visa',
  },
  paymentSepa: {
    id: 'paymentSepa',
    defaultMessage: 'sepa',
  },
  paymentMastercard: {
    id: 'paymentMastercard',
    defaultMessage: 'Mastercard',
  },
  paymentPaypal: {
    id: 'paymentPaypal',
    defaultMessage: 'PayPal',
  },
});

const getPaymentMethodImage = cond<string, string>(
  [
    [equals('cash'), () => cash],
    [equals('sofort'), () => sofort],
    [equals('visa'), () => visa],
    [equals('sepa'), () => sepa],
    [equals('mastercard'), () => mastercard],
    [equals('paypal'), () => paypal],
  ],
  () => ''
);

const paymentMethodToMessage = cond(
  [
    [equals('cash'), () => messages.paymentCash],
    [equals('sofort'), () => messages.paymentSofort],
    [equals('visa'), () => messages.paymentVisa],
    [equals('sepa'), () => messages.paymentSepa],
    [equals('mastercard'), () => messages.paymentMastercard],
    [equals('paypal'), () => messages.paymentPaypal],
  ],
  () => appMessages.unknown
);

export interface PaymentMethodsProps {
  availablePaymentMethods: string[];
}

const image = css`
  max-width: 100%;
  margin: auto;
`;

export const PaymentMethods: React.SFC<PaymentMethodsProps> = ({
  availablePaymentMethods,
}) => (
  <Grid container={true} spacing={16}>
    {availablePaymentMethods &&
      availablePaymentMethods.map(paymentMethod => (
        <Grid item={true} xs={6} key={paymentMethod}>
          <Grid container={true}>
            <Grid item={true} xs={4} className={flexAlignStart}>
              <img
                className={image}
                alt={paymentMethod}
                src={getPaymentMethodImage(paymentMethod)}
              />
              <Grid item={true} xs={7} className={flex}>
                <FluidTypography className={verticalCenteredInFlex}>
                  <I18nMessage
                    message={paymentMethodToMessage(paymentMethod)}
                  />
                </FluidTypography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ))}
  </Grid>
);
