import { switchF, throwF } from '@apoly-42/apoly-utils';
import { add } from 'ramda';
import {
  getNextDays,
  momentWithNewTime,
  secondsOfDayFromTimeStr,
  toSecondsOfDay,
} from '../momentUtilities';
import {
  isAbsoluteTimeSpan,
  isBetweenTimeSpan,
  isInHoliday,
  orderUntilIsLaterThan,
  timeSpanIsOnWeekday,
} from './timehandlingUtilities';

const deliveryWithinToSecondsOfDay = deliveryWithin =>
  0.75 * secondsOfDayFromTimeStr(deliveryWithin);

const getAbsoluteDeliveryStartsAtMoment = (
  mom,
  absoluteDeliveryStartsAtMoment
) =>
  switchF(
    [() => !absoluteDeliveryStartsAtMoment, () => false],
    // if moment is before startPoint, we cannot start delivery now, but on the startPoint
    [
      () => mom < absoluteDeliveryStartsAtMoment,
      () => absoluteDeliveryStartsAtMoment,
    ],
    [() => true, () => mom]
  );

const getAbsoluteDeliverySecondsOfDay = (mom, courierTimeSpan) =>
  add(
    toSecondsOfDay(
      getAbsoluteDeliveryStartsAtMoment(
        mom,
        momentWithNewTime(mom, courierTimeSpan.absoluteDeliveryStartsAt)
      )
    ),
    deliveryWithinToSecondsOfDay(courierTimeSpan.deliveryWithin)
  );

const deliveryFromToToSecondsOfDay = courierTimeSpan =>
  Math.round(
    (secondsOfDayFromTimeStr(courierTimeSpan.deliveryFrom) +
      secondsOfDayFromTimeStr(courierTimeSpan.deliveryTo)) /
      2
  );

const getComparableSeconds = (mom, courierTimeSpan) =>
  switchF(
    [
      () => isAbsoluteTimeSpan(courierTimeSpan),
      () => getAbsoluteDeliverySecondsOfDay(mom, courierTimeSpan),
    ],
    [
      () => isBetweenTimeSpan(courierTimeSpan),
      () => deliveryFromToToSecondsOfDay(courierTimeSpan),
    ],
    [
      () => true,
      () => throwF(`unknown timeSpanType: ${courierTimeSpan.timeSpanType}`),
    ]
  );

const addComparableUtils = moment => courierTimeSpan => {
  const withNewTimeIfExists = time => time && momentWithNewTime(moment, time);
  const absoluteDeliveryStartsAtMoment =
    getAbsoluteDeliveryStartsAtMoment(
      moment,
      withNewTimeIfExists(courierTimeSpan.absoluteDeliveryStartsAt)
    ) || null;

  return {
    ...courierTimeSpan,
    comparableSeconds: getComparableSeconds(moment, courierTimeSpan),
    absoluteDeliveryStartsAtMoment,
    deliveryFromMoment:
      withNewTimeIfExists(courierTimeSpan.deliveryFrom) || null,
    deliveryToMoment: withNewTimeIfExists(courierTimeSpan.deliveryTo) || null,
  };
};

export const getCourierTimeSpansForDay = courierTimeSpans => timeMoment =>
  courierTimeSpans
    .filter(timeSpanIsOnWeekday(timeMoment.isoWeekday()))
    .filter(orderUntilIsLaterThan(toSecondsOfDay(timeMoment)))
    .map(addComparableUtils(timeMoment))
    .sort((a, b) => a.comparableSeconds - b.comparableSeconds);

const getCourierTimeSpansForNextDays = (
  numberOfNextDays,
  serverTimeMoment,
  courierTimeSpans,
  holidays
) =>
  [serverTimeMoment, ...getNextDays(numberOfNextDays, serverTimeMoment)]
    .filter(mom => !isInHoliday(holidays, mom))
    .map(getCourierTimeSpansForDay(courierTimeSpans))
    .reduce(
      (nextTimeSpans, courierTimeSpansForDay) => [
        ...nextTimeSpans,
        ...courierTimeSpansForDay,
      ],
      []
    );

export default getCourierTimeSpansForNextDays;
