import { faBars } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@material-ui/core';
import React from 'react';
import { defineMessages, InjectedIntl, injectIntl } from 'react-intl';

const messages = defineMessages({
  toggleSideBarMenu: {
    id: 'toggleSideBarMenu',
    defaultMessage: 'toggle sidebar menu',
  },
});

export interface ToggleDrawerButtonProps {
  toggleDrawer: () => void;
  intl: InjectedIntl;
}

const InnerToggleDrawerButton: React.SFC<ToggleDrawerButtonProps> = ({
  toggleDrawer,
  intl: { formatMessage },
}) => (
  <Button
    style={{ minWidth: 0 }}
    color="inherit"
    aria-label={formatMessage(messages.toggleSideBarMenu)}
    onClick={toggleDrawer}
  >
    <span>
      <FontAwesomeIcon icon={faBars} />
    </span>
  </Button>
);

export const ToggleDrawerButton = injectIntl(InnerToggleDrawerButton);
