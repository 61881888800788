import * as React from 'react';
import { RfcUrl } from './rfcUrl';

export interface UrlContextValues {
  urlBase: RfcUrl | undefined;
  url: RfcUrl | undefined;
  setUrl: (newUrl: RfcUrl) => void;
}

const UrlContext = React.createContext<UrlContextValues>({
  urlBase: undefined,
  url: undefined,
  setUrl: () => null,
});

interface UrlContextProviderProps {
  urlBase: RfcUrl;
  children: React.ReactNode;
  initialUrl?: RfcUrl;
}

const noMatchError = (url: RfcUrl, base: RfcUrl) =>
  `the new url ${url.toString()} does not matches the origin of the base (${base.toString()})`;

export class UrlContextProvider extends React.PureComponent<
  UrlContextProviderProps,
  { url: RfcUrl | undefined }
> {
  constructor(props: UrlContextProviderProps) {
    super(props);

    if (props.initialUrl && props.initialUrl.origin !== props.urlBase.origin) {
      throw new Error(noMatchError(props.initialUrl, props.urlBase));
    }

    this.state = { url: props.initialUrl };
  }

  handleNewUrl = (url: RfcUrl) => {
    if (url.origin !== this.props.urlBase.origin) {
      throw new Error(noMatchError(url, this.props.urlBase));
    }

    this.setState({ url });
  };

  render() {
    return (
      <UrlContext.Provider
        value={{
          urlBase: this.props.urlBase,
          setUrl: this.handleNewUrl,
          url: this.state.url,
        }}
        children={this.props.children}
      />
    );
  }
}

export const UrlConsumer = UrlContext.Consumer;
