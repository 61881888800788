import { I18nMessage } from '@apoly-42/apoly-components';
import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { commonMessages } from '../../../../../constants/messages/commonMessages';

const ClosureProductsTableHead = () => (
  <Grid container spacing={16}>
    <Grid item xs={8}>
      <Typography variant="subheading">
        <I18nMessage message={commonMessages.medicine} />
      </Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography variant="subheading">
        <I18nMessage message={commonMessages.amount} /> x{' '}
        <I18nMessage message={commonMessages.price} />
      </Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography align="right" variant="subheading">
        <I18nMessage message={commonMessages.totalAmount} />
      </Typography>
    </Grid>
  </Grid>
);

export default ClosureProductsTableHead;
