import { switchF } from '@apoly-42/apoly-utils';
/* eslint-disable prefer-template */
import qs from 'qs';
import { curry, pipe } from 'ramda';

const trimSlash = str => {
  if (!str) {
    return '';
  }

  let res = str.toString();
  if (res.charAt(0) === '/') {
    res = res.substr(1);
  }

  if (res && res.charAt(res.length - 1) === '/') {
    res = res.substr(0, res.length - 1);
  }

  return res;
};

export const urlJoin = (...segments) =>
  segments.reduce((url, segment) => url + '/' + trimSlash(segment), '');

export const POSTAL_URL_FLAG = 'regional';
export const LOCAL_URL_FLAG = 'lokal-online';

const objIsEmpty = obj => Object.keys(obj).filter(key => obj[key]).length <= 0;

const asQueryStringIfExists = obj =>
  objIsEmpty(obj) ? '' : '?' + qs.stringify(obj);

const getCurrentSearchString = () =>
  typeof window !== 'undefined' &&
  window &&
  window.location &&
  window.location.search &&
  window.location.search.substring(1);

export const parseCurrentSearchParams = pipe(
  getCurrentSearchString,
  str => str && qs.parse(str)
);

export const withQueryString = (url, obj) =>
  switchF(
    [() => objIsEmpty(obj), () => url],
    [() => !url, () => qs.stringify(obj)],
    [
      () => url.split('?')[1],
      () =>
        url.split('?')[0] +
        '?' +
        qs.stringify(Object.assign(qs.parse(url.split('?')[1]), obj)),
    ],
    [() => true, () => url + '?' + qs.stringify(obj)]
  );

export const toCurrentPath = location =>
  `${location.pathname}${location.search || ''}`;

export const getUrlForPath = path => {
  if (path.indexOf('http://') >= 0 || path.indexOf('https://') >= 0) {
    return path;
  }

  if (process.env.NODE_ENV === 'production') {
    return `${process.env.REACT_APP_ORIGIN}${path}`;
  } else {
    const host = window.location.hostname
      ? `${window.location.hostname}:${window.location.port}`
      : 'localhost:3000';
    return `${window.location.protocol || 'http:'}//${host}${path}`;
  }
};

export const withLanguageCode = curry((url, languageCode) =>
  withQueryString(url, { lang: languageCode })
);

// old Routes
export const rootPath = () => '';
export const registerPath = () => `${rootPath()}/registrierung`;
export const loginPath = () => `${rootPath()}/login`;

export const setPharmacyPath = () => '/apo/';

export const toChoosePharmacyQueryParams = query => ({ q: query });
export const choosePharmacyPath = ({ query = null } = {}) =>
  '/apotheken-suchen' + asQueryStringIfExists({ q: query });

export const medicSearch = () => '/medikamente-suchen';
export const singleProduct = (productUrlCode, selectedPackage) =>
  `/medikamente/${productUrlCode}/${selectedPackage}`;
export const landingPath = () => '/startseite';
export const loggedOutPath = () => '/logged-out';
export const loggedOutSetPharmacyPath = basePath =>
  urlJoin(basePath, loggedOutPath());
export const contactPath = () => '/kontakt';
export const faqPath = () => '/faq-fragen';
export const pressPath = () => '/presse-informationen-unternehmensueberblick';
export const jobsPath = () => '/stellen-bewerbungen';
export const deliveryCostsPath = () => '/lieferkosten';
export const imprintPath = () => '/impressum';
export const dataSecurityPath = () => '/datenschutz';
export const medicineSeoPath = (medicineUrlCode, pzn) =>
  `/${medicineUrlCode}` + (pzn ? `/${pzn}` : '');
export const medicineSeoPathInformation = (medicineUrlCode, pzn) =>
  urlJoin(medicineSeoPath(medicineUrlCode, pzn), 'beipackzettel-informationen');
export const medicineSeoAttributesRoute = (medicineUrlCode, pzn) =>
  urlJoin(medicineSeoPath(medicineUrlCode, pzn), 'merkmale');

export const aboutUsPath = () => '/team-vorstellung';

export const pharmacySeoPath = pharmacyUrlCode => `/${pharmacyUrlCode}`;
export const noPartnerPharmacySeoPath = noPartnerPharmacyUrlCode =>
  `/${noPartnerPharmacyUrlCode}`;
export const noPartnerPharmacySeoRequestSuccessfulPath = () =>
  '/anfrage-erfolgreich';

export const pharmacyShopRoute = (pharmacyUrlCode, postal) =>
  urlJoin(setPharmacyPath(), pharmacyUrlCode, postal);

export const pharmacyShopRouteLocal = pharmacyUrlCode =>
  pharmacyShopRoute(pharmacyUrlCode, LOCAL_URL_FLAG);

export const pharmacyShopRouteMailOrder = pharmacyUrlCode =>
  pharmacyShopRoute(pharmacyUrlCode, POSTAL_URL_FLAG);

// pharmacyShopRoutes :
export const otcOrderCompletePath = (basePath, otcOrderCode) =>
  `${basePath}/bestellung-abgeschlossen/${otcOrderCode}`;
export const rxOrderCompletePath = (basePath, rxOrderCode) =>
  `${basePath}/rezept-bestellung-abgeschlossen/${rxOrderCode}`;
export const otcPaymentFailedPath = (basePath, otcOrderCode) =>
  `${basePath}/bestellung-abgebrochen/${otcOrderCode}`;
export const registerSetPharmacyPath = basePath => `${basePath}/registrierung`;
export const loginSetPharmacyPath = basePath => `${basePath}/login`;
export const faqSetPharmacyPath = basePath => `${basePath}/faq-fragen`;
export const pressSetPharmacyPath = basePath =>
  `${basePath}/presse-informationen-unternehmensueberblick`;
export const jobsSetPharmacyPath = basePath => urlJoin(basePath, jobsPath());
export const deliveryCostsSetPharmacyPath = basePath =>
  `${basePath}/lieferkosten`;
export const imprintSetPharmacyPath = basePath => `${basePath}/impressum`;
export const dataSecuritySetPharmacyPath = basePath =>
  `${basePath}/datenschutz`;
export const contactPathSetPharmacy = basePath =>
  urlJoin(basePath, contactPath());
export const choosePharmacySetPharmacyPath = (
  basePath,
  { query = null } = {}
) => `${basePath}/apotheken-suchen` + asQueryStringIfExists({ q: query });

export const checkoutPath = basePath => `${basePath}/kasse`;
export const regionFinderPath = basePath => `${basePath}/koerperteilauswahl`;
export const checkoutPathLoginOrContinueAsGuest = basePath =>
  `${basePath}/kasse/login-oder-gast`;
export const checkoutPathSignUpContinueAsGuest = basePath =>
  `${basePath}/kasse/anmeldung-oder-gast`;
export const medicSearchSetPharmacyPath = basePath =>
  `${basePath}/medikamente-suchen`;
export const partnerPharmacyPath = basePath => `${basePath}`;
export const singleProductSetPharmacyPath = (
  basePath,
  productUrlCode,
  selectedPackage
) => `${basePath}/medikamente/${productUrlCode}/${selectedPackage}`;

export const medicineInformationRoute = (basePath, medicineUrlCode, pzn) =>
  urlJoin(
    singleProductSetPharmacyPath(basePath, medicineUrlCode, pzn),
    'beipackzettel-informationen'
  );
export const medicineAttributesRoute = (basePath, medicineUrlCode, pzn) =>
  urlJoin(
    singleProductSetPharmacyPath(basePath, medicineUrlCode, pzn),
    'merkmale'
  );

export const shoppingCartPath = basePath => `${basePath}/warenkorb`;
export const submitPrescriptionPath = basePath =>
  `${basePath}/rezept-bestellen`;

// edit userData routes

export const userPath = () => '/user';
export const userPathWithBase = basePath => urlJoin(basePath, userPath());

export const userDataOverviewPath = () =>
  urlJoin(userPath(), 'uebersicht-kundendaten');
export const editUserDataPath = () =>
  urlJoin(userPath(), 'kundendaten-hinterlegen');
export const changePasswordPath = () =>
  urlJoin(userPath(), '/passwort-aendern');
export const editUserDeliveryAddressPath = () =>
  urlJoin(userPath(), '/lieferadresse-hinterlegen');
export const userRxPurchaseHistoryPath = () =>
  urlJoin(userPath(), '/bestellungen-rezepte');
export const userOtcPurchaseHistoryPath = () =>
  urlJoin(userPath(), '/bestellungen-rezeptfrei');

export const userSingleRxOrderPath = orderId =>
  urlJoin(userPath(), userRxPurchaseHistoryPath(), orderId);

export const userSingleOtcOrderPath = orderId =>
  urlJoin(userPath(), userOtcPurchaseHistoryPath(), orderId);

export const userPrintShippingNotePath = orderId =>
  urlJoin(userSingleOtcOrderPath(orderId), 'lieferschein-drucken');
export const userPrintBillPath = orderId =>
  urlJoin(userSingleOtcOrderPath(orderId), 'rechnung-drucken');

export const userDataOverviewPathSetPharmacy = basePath =>
  urlJoin(basePath, userDataOverviewPath());
export const editUserDataPathSetPharmacy = basePath =>
  urlJoin(basePath, editUserDataPath());
export const changePasswordPathSetPharmacy = basePath =>
  urlJoin(basePath, changePasswordPath());
export const editUserDeliveryAddressPathSetPharmacy = basePath =>
  urlJoin(basePath, editUserDeliveryAddressPath());
export const userRxPurchaseHistoryPathSetPharmacy = basePath =>
  urlJoin(basePath, userRxPurchaseHistoryPath());
export const userOtcPurchaseHistoryPathSetPharmacy = basePath =>
  urlJoin(basePath, userOtcPurchaseHistoryPath());
export const userSingleRxOrderPathSetPharmacy = (basePath, orderId) =>
  urlJoin(basePath, userSingleRxOrderPath(orderId));
export const userSingleOtcOrderPathSetPharmacy = (basePath, orderId) =>
  urlJoin(basePath, userSingleOtcOrderPath(orderId));
export const userPrintShippingNotePathSetPharmacy = (basePath, orderId) =>
  urlJoin(basePath, userPrintShippingNotePath(orderId));
export const userPrintBillPathSetPharmacy = (basePath, orderId) =>
  urlJoin(basePath, userPrintBillPath(orderId));

export const userOtcOrderCompletePath = (basePath, orderId) =>
  urlJoin(basePath, userPath(), 'bestellung-abgeschlossen', orderId);

export const userRxOrderCompletePath = (basePath, orderId) =>
  urlJoin(basePath, userPath(), 'rezept-bestellung-abgeschlossen', orderId);

export const agbPath = () => `/files/Apoly-Nutzungsbedingungen-AGB.pdf`;
