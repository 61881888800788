import { I18nMessage } from '@apoly-42/apoly-components';
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import Autosuggest from 'react-autosuggest';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import { commonMessages } from '../../constants/messages/commonMessages';
import { formMessages } from '../../constants/messages/formMessages';
import { findButton, fullWidth } from '../../constants/styles/commonClasses';
import renderAutoSuggestInput from './renderAutoSuggestInput';
import renderSectionTitle from './renderSectionTitle';
import renderSuggestion from './renderSuggestion';
import renderSuggestionsContainer from './renderSuggestionsContainer';

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: 2,
    marginBottom: theme.spacing.unit * 3,
    left: 0,
    right: 0,
    padding: 8,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  searchBox: {
    padding: 8,
    backgroundColor: 'rgba(250,250,250,1)',
  },
  icon: {
    color: theme.palette.text.secondary,
    margin: 'auto 16px',
  },
  findButton,
});

const getSectionSuggestions = section => section.suggestions;

const getSuggestionValue = suggestion => suggestion.name;

const SearchAutoSuggestView = ({
  showSearchButton,
  setFetchValue,
  classes,
  suggestions,
  setSelectedSuggestion,
  handleSearchBarValueChange,
  searchValue,
  handleSuggestionsClearRequested,
  setSearchForMedic,
  intl: { formatMessage },
}) => (
  <div style={fullWidth}>
    <Grid container spacing={16}>
      <Grid
        item
        xs={12}
        sm={showSearchButton ? 8 : 12}
        md={showSearchButton ? 9 : 12}
      >
        <Autosuggest
          theme={{
            container: classes.container,
            suggestionsContainerOpen: classes.suggestionsContainerOpen,
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderInputComponent={renderAutoSuggestInput}
          suggestions={suggestions}
          multiSection
          renderSectionTitle={renderSectionTitle}
          getSectionSuggestions={getSectionSuggestions}
          onSuggestionsFetchRequested={({ value }) => setFetchValue(value)}
          onSuggestionsClearRequested={handleSuggestionsClearRequested}
          renderSuggestionsContainer={renderSuggestionsContainer}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            autoFocus: true,
            classes,
            placeholder: formatMessage(formMessages.search),
            label: formatMessage(formMessages.search),
            value: searchValue,
            onChange: handleSearchBarValueChange,
            onKeyPress: e =>
              e.key === 'Enter' && setSearchForMedic(searchValue),
          }}
          onSuggestionSelected={(_, { suggestion }) =>
            setSelectedSuggestion(suggestion)
          }
        />
      </Grid>
      {showSearchButton && (
        <Grid item xs={12} sm={4} md={3}>
          <Button
            className={classes.findButton}
            variant="raised"
            color="secondary"
            onClick={() => setSearchForMedic(searchValue)}
          >
            <I18nMessage message={commonMessages.findMedicine} />
            &nbsp;
            <FontAwesomeIcon icon={faChevronDoubleRight} />
          </Button>
        </Grid>
      )}
    </Grid>
  </div>
);

SearchAutoSuggestView.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  searchValue: PropTypes.string,
  suggestions: PropTypes.array.isRequired,
  showSearchButton: PropTypes.bool.isRequired,
  setFetchValue: PropTypes.func.isRequired,
  handleSearchBarValueChange: PropTypes.func.isRequired,
  handleSuggestionsClearRequested: PropTypes.func.isRequired,
  setSearchForMedic: PropTypes.func.isRequired,
  setSelectedSuggestion: PropTypes.func.isRequired,
};

SearchAutoSuggestView.defaultProps = {
  searchValue: '',
};

const enhance = compose(
  withStyles(styles),
  injectIntl
);

export default enhance(SearchAutoSuggestView);
