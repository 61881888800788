import { ShowComponentWhileLoading } from '@apoly-42/material-ui';
import { Grid, Hidden } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import LoadArticlesContainer from '../../../../../containers/product/LoadArticlesContainer';
import { selectPharmacyId } from '../../../../../redux/shopPharmacy/reducer';
import {
  getShoppingCartArticlesIdArray,
  selectProducts,
} from '../../../../../redux/shoppingCart/selectors';
import SingleProduct from './SingleProduct';
import SingleProductResponsive from './SingleProductResponsive';

const InnerClosureProducts = props => (
  <React.Fragment>
    <LoadArticlesContainer
      setIsLoading={props.setIsLoading}
      setError={props.setError}
      setArticles={props.setArticles}
      articleIdArray={props.articleIdArray}
      pharmacyId={props.pharmacyId}
    />

    {props.isLoading && (
      <ShowComponentWhileLoading isLoading={props.isLoading} />
    )}

    {!props.isLoading &&
      props.articles && (
        <Grid container spacing={16}>
          {props.articles &&
            props.products &&
            props.articles.map(
              article =>
                props.products[article.articleId] && (
                  <Grid item xs={12} key={article.articleId}>
                    <Hidden smDown>
                      <SingleProduct
                        article={article}
                        count={Number(props.products[article.articleId].count)}
                      />
                    </Hidden>
                    <Hidden mdUp>
                      <SingleProductResponsive
                        article={article}
                        count={Number(props.products[article.articleId].count)}
                      />
                    </Hidden>
                  </Grid>
                )
            )}
        </Grid>
      )}
  </React.Fragment>
);

const mapStateToProps = state => ({
  products: selectProducts(state),
  articleIdArray: getShoppingCartArticlesIdArray(state),
  pharmacyId: selectPharmacyId(state),
});

const mapDispatchToProps = {};

const enhance = compose(
  withState('isLoading', 'setIsLoading', false),
  withState('error', 'setError', null),
  withState('articles', 'setArticles', null),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

const ClosureProducts = enhance(InnerClosureProducts);

ClosureProducts.propTypes = {};

InnerClosureProducts.propTypes = {
  error: PropTypes.object,
  articleIdArray: PropTypes.array.isRequired,
  articles: PropTypes.array,
  products: PropTypes.object.isRequired,
  setArticles: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  ...ClosureProducts.propTypes,
};

InnerClosureProducts.defaultProps = {
  articles: null,
  error: null,
};

export default ClosureProducts;
