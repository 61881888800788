import { FormattedEuro, I18nMessage } from '@apoly-42/apoly-components';
import { withProp } from '@apoly-42/apoly-utils';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { commonMessages } from '../../../../constants/messages/commonMessages';
import {
  selectDeliveryAddressCountry,
  selectTransmission,
} from '../../../../redux/checkout/reducer';
import { selectEnhancedPharmacy } from '../../../../redux/shopPharmacy/reducer';
import { getShoppingCartTotalPrice } from '../../../../redux/shoppingCart/selectors';
import {
  deliveryPriceFromEnhancedPharmacy,
  getPostalCost,
} from '../../../../utilities/delivery/deliveryUtils';
import { getTotalPrice } from '../../../../utilities/product/productUtilities';

const messages = defineMessages({
  courierCharge: {
    id: 'courierCharge',
    defaultMessage: 'courier charge',
  },
  deliveryCharge: {
    id: 'deliveryCharge',
    defaultMessage: 'delivery charge',
  },
  inclusiveMwSt: {
    id: 'inclusiveMwSt',
    defaultMessage: 'inclusive 19% MwSt.',
  },
  inclusiveMwstTotal: {
    id: 'inclusiveMwstTotal',
    defaultMessage: 'Inklusive MwSt.',
  },
});

const ClosurePrice = ({
  shoppingCartPrice,
  deliveryAddressCountry,
  transmission,
  deliveryPrice,
}) => (
  <Grid container spacing={16} justify="flex-end">
    <Grid item>
      {transmission === 'courier' && (
        <Typography align="right">
          <I18nMessage message={messages.courierCharge} />
          {deliveryAddressCountry !== 'AT' && '*'}
          :&nbsp;
          <FormattedEuro value={Number(deliveryPrice)} />
        </Typography>
      )}

      {transmission === 'postal' && (
        <Typography align="right">
          <I18nMessage message={messages.deliveryCharge} />
          {deliveryAddressCountry !== 'AT' && '*'}
          :&nbsp;
          <FormattedEuro
            value={Number(getPostalCost(deliveryAddressCountry))}
          />
        </Typography>
      )}

      <Typography variant="body1" align="right">
        <I18nMessage message={commonMessages.totalAmount} />
        :&nbsp;
        <FormattedEuro
          value={getTotalPrice(shoppingCartPrice, transmission, deliveryPrice)}
        />
      </Typography>
      <Typography variant="caption" align="right">
        <I18nMessage message={messages.inclusiveMwstTotal} />
      </Typography>
      {transmission === 'postal' &&
        deliveryAddressCountry !== 'AT' && (
          <Typography variant="caption" align="right">
            *&npsp;
            <I18nMessage message={commonMessages.increasedDeliveryCostsAut} />
          </Typography>
        )}
    </Grid>
  </Grid>
);

const mapStateToProps = state => ({
  shoppingCartPrice: getShoppingCartTotalPrice(state),
  enhancedPharmacy: selectEnhancedPharmacy(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withProp('deliveryPrice', props =>
    deliveryPriceFromEnhancedPharmacy(
      props.enhancedPharmacy,
      props.deliveryAddressCountry,
      props.transmission
    )
  )
);

ClosurePrice.propTypes = {
  shoppingCartPrice: PropTypes.string.isRequired,
};

export default enhance(ClosurePrice);
