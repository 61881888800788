import { CancelTokenSource } from 'axios';
import axios from 'axios';
import * as React from 'react';
import { Omit } from 'utility-types';
import { tap } from '../app-utilities/fn-utils';
import { RenderProp } from '../app-utilities/reactUtilTypes';
import { Load, LoadingState, LoadsWrapperProps } from './Load';

interface AbortableLoadingState<T> extends LoadingState<T> {
  abortAll: () => void;
}

interface LoadWithAxiosAbortableProps<T, E = Error>
  extends Omit<LoadsWrapperProps<T, E>, 'fn' | 'children'> {
  fn: (cancelToken: CancelTokenSource) => Promise<T>; // Trigger to load fn
  children?: RenderProp<AbortableLoadingState<T>>;
}

export class LoadWithAxiosAbortable<T, E = Error> extends React.PureComponent<
  LoadWithAxiosAbortableProps<T, E>
> {
  tokens: CancelTokenSource[] = [];

  removeToken = (token: CancelTokenSource) =>
    this.tokens.splice(this.tokens.indexOf(token));

  callPromise = () => {
    const newToken = axios.CancelToken.source();
    this.tokens.push(newToken);

    return this.props
      .fn(newToken)
      .then(tap<T>(() => this.removeToken(newToken)))
      .catch((error: E) => {
        this.removeToken(newToken);
        throw error;
      });
  };

  abortAll = () =>
    this.tokens.forEach(token =>
      token.cancel('abort request cause component will unmount')
    );

  componentWillUnmount() {
    this.abortAll();
  }

  defaultCrashOnError = (error: E) => {
    // if its a cancelation the component-tree should not crash
    if (axios.isCancel(error)) {
      return false;
    }

    return true;
  };

  renderChildren = (loadingState: LoadingState<T>) =>
    this.props.children
      ? this.props.children({
          abortAll: this.abortAll,
          ...loadingState,
        })
      : null;

  render() {
    const {
      fn,
      children,
      crashComponentOnError = this.defaultCrashOnError,
      ...rest
    } = this.props;

    return (
      <Load<T, E>
        fn={this.callPromise}
        crashComponentOnError={crashComponentOnError}
        {...rest}
      >
        {this.renderChildren}
      </Load>
    );
  }
}
