import { Typography } from '@material-ui/core';
import React from 'react';
import { I18nMessage } from '../../../../../../app-util-components/I18nMessage';
import { commonMessages } from '../../../../constants/messages/commonMessages';

export const getTaxValueForProduct = MWSTCode => {
  if (Number(MWSTCode) === 0) {
    return '13%';
  }
  if (Number(MWSTCode) === 1) {
    return '10%';
  }
  if (Number(MWSTCode) === 2) {
    return '20%';
  }
  return '19%';
};

export const getTaxValueMessage = MWSTCode => (
  <Typography variant="caption" align="right">
    <I18nMessage
      message={commonMessages.inclusiveMwStWithValue}
      values={{
        taxValue: getTaxValueForProduct(MWSTCode),
      }}
    />
  </Typography>
);
