import { defineMessages } from 'react-intl';

export const appMessages = defineMessages({
  noPostalDeliveryAllowed: {
    id: 'noPostalDeliveryAllowed',
    defaultMessage:
      'Ihre Apotheke hat momentan leider noch keine Versandhandelserlaubnis. Per Post oder Bote dürfen wir deswegen leider nur Kosmetik-Produkte und Nahrungsergänzungsmittel versenden, wir bitten um Ihr Verständnis.',
  },
  editAddressInput: {
    id: 'editAddressInput',
    defaultMessage: 'Andere Adresse eingeben',
  },
  showMedicsForRegion: {
    id: 'showMedicsForRegion',
    defaultMessage: 'Show Medics for {region}',
  },
  selectNewRegion: {
    id: 'selectNewRegion',
    defaultMessage: 'Select new Region',
  },
  titleRxOrderComplete: {
    id: 'titleRxOrderComplete',
    defaultMessage: 'Rezept-Bestellung erfolgreich',
  },
  titleOtcOrderComplete: {
    id: 'titleOtcOrderComplete',
    defaultMessage: 'Medikament-Bestellung erfolgreich',
  },
  unknown: {
    id: 'unknown',
    defaultMessage: 'unknown',
  },
  address: {
    id: 'address',
    defaultMessage: 'address',
  },
  agbError: {
    id: 'agbError',
    defaultMessage: 'you have to agree to our terms and conditions',
  },
  agreeToUserDataSecurityPolicy: {
    id: 'agreeToUserDataSecurityPolicy',
    defaultMessage:
      'With this order, you agree to our {websiteAgbLink} and our {privacyPolicyLink}.',
  },
  agb: {
    id: 'agb',
    defaultMessage: 'agb',
  },
  backToMedicine: {
    id: 'backToMedicine',
    defaultMessage: 'back to medicine',
  },
  amount: {
    id: 'amount',
    defaultMessage: 'amount',
  },
  from: {
    id: 'from',
    defaultMessage: 'from',
  },
  to: {
    id: 'to',
    defaultMessage: 'to',
  },
  cash: {
    id: 'cash',
    defaultMessage: 'cash',
  },
  save: {
    id: 'save',
    defaultMessage: 'save',
  },
  change: {
    id: 'change',
    defaultMessage: 'change',
  },
  chatBot: {
    id: 'chatBot',
    defaultMessage: 'chatBot',
  },
  clock: {
    id: 'clock',
    defaultMessage: "o'clock",
  },
  close: {
    id: 'close',
    defaultMessage: 'close',
  },
  closed: {
    id: 'closed',
    defaultMessage: 'closed',
  },
  closeDrawer: {
    id: 'closeDrawer',
    defaultMessage: 'close drawer',
  },
  contact: {
    id: 'contact',
    defaultMessage: 'Contact',
  },
  continue: {
    id: 'continue',
    defaultMessage: 'continue',
  },
  dataSecurity: {
    id: 'dataSecurity',
    defaultMessage: 'data security',
  },
  privacyPolicy: {
    id: 'privacyPolicy',
    defaultMessage: 'privacy policy',
  },
  delete: {
    id: 'delete',
    defaultMessage: 'delete',
  },
  deliverable: {
    id: 'deliverable',
    defaultMessage: 'deliverable',
  },
  delivery: {
    id: 'delivery',
    defaultMessage: 'delivery',
  },
  pickUpAtPharmacy: {
    id: 'pickUpAtPharmacy',
    defaultMessage: 'Pick up at pharmacy',
  },
  faq: {
    id: 'faq',
    defaultMessage: 'faq',
  },
  deliveryAddress: {
    id: 'deliveryAddress',
    defaultMessage: 'Delivery address',
  },
  billingAddress: {
    id: 'billingAddress',
    defaultMessage: 'Billing address',
  },
  findPharmacy: {
    id: 'findPharmacy',
    defaultMessage: 'find Pharmacy',
  },
  finish: {
    id: 'finish',
    defaultMessage: 'finish',
  },
  home: {
    id: 'home',
    defaultMessage: 'Home',
  },
  imprint: {
    id: 'imprint',
    defaultMessage: 'imprint',
  },
  linkToLandingPage: {
    id: 'linkToLandingPage',
    defaultMessage: 'home',
  },
  loading: {
    id: 'loading',
    defaultMessage: 'loading...',
  },
  login: {
    id: 'login',
    defaultMessage: 'Login',
  },
  logOut: {
    id: 'logOut',
    defaultMessage: 'Logout',
  },
  medicine: {
    id: 'medicine',
    defaultMessage: 'medicine',
  },
  medicines: {
    id: 'medicines',
    defaultMessage: 'medicines',
  },
  medicSearch: {
    id: 'medicSearch',
    defaultMessage: 'medic search',
  },
  moreInformation: {
    id: 'moreInformation',
    defaultMessage: 'more information',
  },
  notDeliverable: {
    id: 'notDeliverable',
    defaultMessage: 'not deliverable',
  },
  notRegistered: {
    id: 'notRegistered',
    defaultMessage: 'Not registered?',
  },
  only: {
    id: 'only',
    defaultMessage: 'only ',
  },
  open: {
    id: 'open',
    defaultMessage: 'open ',
  },
  cancel: {
    id: 'cancel',
    defaultMessage: 'cancel ',
  },
  or: {
    id: 'or',
    defaultMessage: 'or',
  },
  showMore: {
    id: 'showMore',
    defaultMessage: 'show more',
  },
  showLess: {
    id: 'showLess',
    defaultMessage: 'show less',
  },
  showAll: {
    id: 'showAll',
    defaultMessage: 'show all',
  },
  orderSuccess: {
    id: 'orderSuccess',
    defaultMessage: 'order completed successful',
  },
  paymentFailed: {
    id: 'paymentFailed',
    defaultMessage: 'payment failed or canceled',
  },
  orderFailedToComplete: {
    id: 'orderFailedToComplete',
    defaultMessage: 'Your Order could not be completed successfully.',
  },
  paymentMethod: {
    id: 'paymentMethod',
    defaultMessage: 'payment method',
  },
  per: {
    id: 'per',
    defaultMessage: ' per ',
  },
  prescription: {
    id: 'prescription',
    defaultMessage: 'prescription',
  },
  price: {
    id: 'price',
    defaultMessage: 'price',
  },
  recipies: {
    id: 'recipies',
    defaultMessage: 'prescription',
  },
  register: {
    id: 'register',
    defaultMessage: 'register',
  },
  risksAndSideEffects: {
    id: 'risksAndSideEffects',
    defaultMessage:
      'Please consult your doctor or pharmacist about any risks and side effects.',
  },
  rx: {
    id: 'rx',
    defaultMessage: 'Rx',
  },
  safeWithApoly: {
    id: 'safeWithApoly',
    defaultMessage: 'be safe with {brand}',
  },
  select: {
    id: 'select',
    defaultMessage: 'select ',
  },
  shoppingCart: {
    id: 'shoppingCart',
    defaultMessage: 'shopping cart',
  },
  signUp: {
    id: 'signUp',
    defaultMessage: 'Sign up',
  },
  slowConnection: {
    id: 'slowConnection',
    defaultMessage:
      'It takes a lot of time to load this page. You may have a bad internet connection currently.',
  },
  submitPrescription: {
    id: 'submitPrescription',
    defaultMessage: 'submit prescription',
  },
  symptoms: {
    id: 'symptoms',
    defaultMessage: 'symptoms',
  },
  totalAmount: {
    id: 'totalAmount',
    defaultMessage: 'total',
  },
  deliveryTime: {
    id: 'deliveryTime',
    defaultMessage: 'delivery time',
  },
  costs: {
    id: 'costs',
    defaultMessage: 'costs',
  },
  note: {
    id: 'note',
    defaultMessage: 'note',
  },
  deliveryIn: {
    id: 'deliveryIn',
    defaultMessage: '{inDays} days',
  },
  postalDeliveryGermany: {
    id: 'postalDeliveryGermany',
    defaultMessage: 'delivery in between 1-3 workdays',
  },
  deliveredByPost: {
    id: 'deliveredByPost',
    defaultMessage: 'delivered by post',
  },
  deliveredByPostToLocation: {
    id: 'deliveredByPostToLocation',
    defaultMessage: 'delivered by post in {location}',
  },
  dependingOnPartnerPharmacy: {
    id: 'dependingOnPartnerPharmacy',
    defaultMessage:
      'Depending on region, assortment and opening times from the partner pharmacy',
  },
  openingTimes: {
    id: 'openingTimes',
    defaultMessage: 'opening times',
  },
  addToShoppingCart: {
    id: 'addToShoppingCart',
    defaultMessage: 'add to shoppingCart',
  },
  withMwStPlus: {
    id: 'withMwStPlus',
    defaultMessage: 'inclusive. MwSt, plus',
  },
  packageSize: {
    id: 'packageSize',
    defaultMessage: 'package size',
  },
  activeComponents: {
    id: 'activeComponents',
    defaultMessage: 'active components',
  },
  finishPayment: {
    id: 'finishPayment',
    defaultMessage: 'finish payment',
  },
  finishPaymentXs: {
    id: 'finishPaymentXs',
    defaultMessage: 'complete',
  },
  findMedicine: {
    id: 'findMedicine',
    defaultMessage: 'find medicine',
  },
  nextOpenDay: {
    id: 'nextOpenDay',
    defaultMessage: "closed, open at {day} at {openingTime} o'clock",
  },
  closingAt: {
    id: 'closingAt',
    defaultMessage: "open, closing at {openingTime} o'clock",
  },
  continueShopping: {
    id: 'continueShopping',
    defaultMessage: 'continue shopping',
  },
  germanServer: {
    id: 'germanServer',
    defaultMessage: 'server in germany',
  },
  sslSecureConnection: {
    id: 'sslSecureConnection',
    defaultMessage: 'SSL - encrypted connection',
  },
  deliveryBetween: {
    id: 'deliveryBetween',
    defaultMessage: "{day}, between {from} o'clock and {to} o'clock",
  },
  deliveryCost: {
    id: 'deliveryCost',
    defaultMessage: 'delivery cost',
  },
  characteristics: {
    id: 'characteristics',
    defaultMessage: 'characteristics',
  },
  selectLanguage: {
    id: 'selectLanguage',
    defaultMessage: 'select language',
  },
  generic: {
    id: 'generic',
    defaultMessage: 'generic',
  },
  herbal: {
    id: 'herbal',
    defaultMessage: 'herbal',
  },
  homeopathic: {
    id: 'homeopathic',
    defaultMessage: 'homoeopathisch',
  },
  anthroposophic: {
    id: 'anthroposophic',
    defaultMessage: 'anthroposophisch',
  },
  dopingRelevance: {
    id: 'dopingRelevance',
    defaultMessage: 'doping',
  },
  phytopharmaceutical: {
    id: 'phytopharmaceutical',
    defaultMessage: 'phytopharmaceutical',
  },
  lifestyle: {
    id: 'lifestyle',
    defaultMessage: 'lifestyle',
  },
  import: {
    id: 'import',
    defaultMessage: 'import',
  },
  goToPharmacyShop: {
    id: 'goToPharmacyShop',
    defaultMessage: 'go to pharmacy shop',
  },
  dietarysupplement: {
    id: 'dietarysupplement',
    defaultMessage: 'dietarysupplement',
  },
  cosmetic: {
    id: 'cosmetic',
    defaultMessage: 'cosmetic',
  },
  childFlag: {
    id: 'childFlag',
    defaultMessage: 'Für Kinder geeignet',
  },
  maleFlag: {
    id: 'maleFlag',
    defaultMessage: 'Mann',
  },
  femaleFlag: {
    id: 'femaleFlag',
    defaultMessage: 'Frau',
  },
  hours: {
    id: 'hours',
    defaultMessage:
      '{numberOfHours, plural, one {one hour} other {{numberOfHours} hours}}',
  },
  minutes: {
    id: 'minutes',
    defaultMessage:
      '{numberOfMinutes, plural, one {one minute} other {{numberOfMinutes} minutes}}',
  },
  now: {
    id: 'now',
    defaultMessage: 'now',
  },
  selectPharmacy: {
    id: 'selectPharmacy',
    defaultMessage: 'select {pharmacyName}',
  },
  userDataOverview: {
    id: 'userDataOverview',
    defaultMessage: 'User data overview',
  },
  changeUserData: {
    id: 'changeUserData',
    defaultMessage: 'Edit user data',
  },
  editDeliveryAddress: {
    id: 'editDeliveryAddress',
    defaultMessage: 'Edit delivery address',
  },
  otcPurchaseHistory: {
    id: 'otcPurchaseHistory',
    defaultMessage: 'Otc-orders purchase history',
  },
  rxPurchaseHistory: {
    id: 'rxPurchaseHistory',
    defaultMessage: 'Rx-orders purchase history',
  },
  orderNumber: {
    id: 'orderNumber',
    defaultMessage: 'Order number',
  },
  orderStatus: {
    id: 'orderStatus',
    defaultMessage: 'Status',
  },
  orderDate: {
    id: 'orderDate',
    defaultMessage: 'Order date',
  },
  orderedAtDate: {
    id: 'orderedAtDate',
    defaultMessage: 'Order date: {orderDate}',
  },
  details: {
    id: 'details',
    defaultMessage: 'Details',
  },
  watchDetails: {
    id: 'watchDetails',
    defaultMessage: 'Watch Details',
  },
  singleOrderWithNumber: {
    id: 'singleOrderWithNumber',
    defaultMessage: 'Order {orderNumber}',
  },
  product: {
    id: 'product',
    defaultMessage: 'product',
  },
  totalPrice: {
    id: 'totalPrice',
    defaultMessage: 'Total Price',
  },
  total: {
    id: 'total',
    defaultMessage: 'Total',
  },
  date: {
    id: 'date',
    defaultMessage: 'Date',
  },
  status: {
    id: 'status',
    defaultMessage: 'Status',
  },
  comment: {
    id: 'comment',
    defaultMessage: 'Comment',
  },
  pzn: {
    id: 'pzn',
    defaultMessage: 'Pzn',
  },
  print: {
    id: 'print',
    defaultMessage: 'Print',
  },
  bill: {
    id: 'bill',
    defaultMessage: 'Bill',
  },
  accountExistsWithDifferentCredential: {
    id: 'accountExistsWithDifferentCredential',
    defaultMessage:
      'There already exists an account with the email address asserted by the credential. Resolve this by calling firebase.auth.Auth#fetchProvidersForEmail and then asking the user to sign in using one of the returned providers. Once the user is signed in, the original credential can be linked to the user with firebase.',
  },
  packageAmount: {
    id: 'packageAmount',
    defaultMessage: 'Package amount',
  },
  deliveryOrderNumber: {
    id: 'deliveryOrderNumber',
    defaultMessage: 'Delivery order number: {orderId}',
  },
  billNumber: {
    id: 'billNumber',
    defaultMessage: 'Bill number: {billNumber}',
  },
  deliveryDate: {
    id: 'deliveryDate',
    defaultMessage: 'Delivery date: {deliveryDate}',
  },
  billDate: {
    id: 'billDate',
    defaultMessage: 'Bill date: {billDate}',
  },
  shippingNote: {
    id: 'shippingNote',
    defaultMessage: 'Shipping note',
  },
  deliveryCosts: {
    id: 'deliveryCosts',
    defaultMessage: 'Delivery costs',
  },
  deliveryMadeInContextOfAgb: {
    id: 'deliveryMadeInContextOfAgb',
    defaultMessage:
      'Die Lieferung erfolgt ausschließlich zu unseren Allgemeinen Geschäftsbedingungen (AGB).',
  },
  valueAddedTax: {
    id: 'valueAddedTax',
    defaultMessage: 'Value added tax (19%)',
  },
  afterTax: {
    id: 'afterTax',
    defaultMessage: 'After tax',
  },
  backdoorNotePlaceholder: {
    id: 'backdoorNotePlaceholder',
    defaultMessage: 'backdoor, ...',
  },
  changePassword: {
    id: 'changePassword',
    defaultMessage: 'Change Password',
  },
  thanksForOrder: {
    id: 'thanksForOrder',
    defaultMessage: 'Thank you very much for your Order!',
  },
  sentMailWithOrderInfo: {
    id: 'sentMailWithOrderInfo',
    defaultMessage:
      'We sent you an email with all details and information about your order.',
  },
  takeNoteOfOrder: {
    id: 'takeNoteOfOrder',
    defaultMessage:
      'Please take note of the delivery terms written in this email.',
  },
  backToLandingPage: {
    id: 'backToLandingPage',
    defaultMessage: 'back to home',
  },
  currentStatus: {
    id: 'currentStatus',
    defaultMessage: 'Current order status',
  },
  pickUpAt: {
    id: 'pickUpAt',
    defaultMessage: 'Pick up at',
  },
  pickUp: {
    id: 'pickUp',
    defaultMessage: 'Pick up',
  },
  deliveryTypePickUp: {
    id: 'deliveryTypePickUp',
    defaultMessage:
      'The medications will be picked up by {buyer} at the pharmacy.',
  },
  deliveryTypeCourier: {
    id: 'deliveryTypeCourier',
    defaultMessage:
      'The medications are delivery to {buyer} by the delivery service of the pharmacy.',
  },
  deliveryTypePostal: {
    id: 'deliveryTypePostal',
    defaultMessage: 'The medications are delivery to {buyer} by post.',
  },
  deliveryTimeWithin: {
    id: 'deliveryTimeWithin',
    defaultMessage: 'In between the next {deliveryWithin} hours after order',
  },
  deliveryTimeBetween: {
    id: 'deliveryTimeBetween',
    defaultMessage:
      "Next {day} after order, between {from} o'clock and {to} o'clock",
  },
  deliveryTimePostal: {
    id: 'deliveryTimePostal',
    defaultMessage: 'In between the next 1-3 workdays after order',
  },
  personalData: {
    id: 'personalData',
    defaultMessage: 'Personal data',
  },
  chosenPaymentMethod: {
    id: 'chosenPaymentMethod',
    defaultMessage: 'Chosen payment method',
  },
  deliveryInformation: {
    id: 'deliveryInformation',
    defaultMessage: 'Delivery information',
  },
  telephoneNeedReason: {
    id: 'telephoneNeedReason',
    defaultMessage:
      'The pharmacist need your telephone number, to inform you, if there is any kind of deliveryproblem or medical issue.',
  },
  deliveryForExtraCharge: {
    id: 'deliveryForExtraCharge',
    defaultMessage: 'delivery (for an extra charge of {price})',
  },
  continueAsGuest: {
    id: 'continueAsGuest',
    defaultMessage: 'Continue as guest',
  },
  noOrderFound: {
    id: 'noOrderFound',
    defaultMessage: 'No order found',
  },
  noOrdersFound: {
    id: 'noOrdersFound',
    defaultMessage: 'No orders found',
  },
  backToPurchaseHistory: {
    id: 'backToPurchaseHistory',
    defaultMessage: 'Back to purchase history',
  },
  costumerData: {
    id: 'costumerData',
    defaultMessage: 'Costumer data',
  },
  pharmacyData: {
    id: 'pharmacyData',
    defaultMessage: 'Pharmacy data',
  },
  selectDate: {
    id: 'selectDate',
    defaultMessage: 'select Date',
  },
  previousPage: {
    id: 'previousPage',
    defaultMessage: 'Previous page',
  },
  nextPage: {
    id: 'nextPage',
    defaultMessage: 'Next page',
  },
  articlesPerPage: {
    id: 'articlesPerPage',
    defaultMessage: 'Articles per page',
  },
  ordersPerPage: {
    id: 'ordersPerPage',
    defaultMessage: 'Orders per page',
  },
  deliveryInWholeGermany: {
    id: 'deliveryInWholeGermany',
    defaultMessage: 'Delivery in whole germany',
  },
  noOrderForKeyFound: {
    id: 'noOrderForKeyFound',
    defaultMessage: 'No Order for the given order key found!',
  },
  selectAgents: {
    id: 'selectAgents',
    defaultMessage: 'Select agents',
  },
  selectCharacteristics: {
    id: 'selectCharacteristics',
    defaultMessage: 'Select medicine flags',
  },
  selectSymptoms: {
    id: 'selectSymptoms',
    defaultMessage: 'Select symptoms',
  },
  selectDosageForms: {
    id: 'selectDosageForms',
    defaultMessage: 'Select dosage forms',
  },
  selectBodyRegions: {
    id: 'selectBodyRegions',
    defaultMessage: 'Select body regions',
  },
  selectProducers: {
    id: 'selectProducers',
    defaultMessage: 'Select producers',
  },
  pressAndPartners: {
    id: 'pressAndPartners',
    defaultMessage: 'Press & partners',
  },
  partners: {
    id: 'partners',
    defaultMessage: 'Partners',
  },
  jobs: {
    id: 'jobs',
    defaultMessage: 'jobs',
  },
  back: {
    id: 'back',
    defaultMessage: 'back',
  },
  jobsAtBrand: {
    id: 'jobsAtBrand',
    defaultMessage: 'Jobs at {brand}',
  },
  searchResults: {
    id: 'searchResults',
    defaultMessage: 'Search results',
  },
  searchFilter: {
    id: 'searchFilter',
    defaultMessage: 'Search filter',
  },
  contactForm: {
    id: 'contactForm',
    defaultMessage: 'contact form',
  },
  abort: {
    id: 'abort',
    defaultMessage: 'abort',
  },
  regionFinder: {
    id: 'regionFinder',
    defaultMessage: 'Region finder',
  },
  symptomsForRegion: {
    id: 'symptomsForRegion',
    defaultMessage: 'Symptoms for {region}',
  },
  noSymptomsFoundForRegion: {
    id: 'noSymptomsFoundForRegion',
    defaultMessage: 'No Symptoms Found for selected Region.',
  },
  age: {
    id: 'age',
    defaultMessage: 'age',
  },
  region: {
    id: 'region',
    defaultMessage: 'region',
  },
  ageAndGender: {
    id: 'ageAndGender',
    defaultMessage: 'Age and Gender',
  },
  aboutUs: {
    id: 'aboutUs',
    defaultMessage: 'About us',
  },
  optional: {
    id: 'optional',
    defaultMessage: 'optional',
  },
  understood: {
    id: 'understood',
    defaultMessage: 'verstanden',
  },
  editAddress: {
    id: 'editAddress',
    defaultMessage: 'edit address',
  },
  couldNotFoundAddress: {
    id: 'couldNotFoundAddress',
    defaultMessage: 'could not found address',
  },
  doYouMean: {
    id: 'doYouMean',
    defaultMessage: 'do you mean',
  },
  clickOnAddressToSelect: {
    id: 'clickOnAddressToSelect',
    defaultMessage: 'click on address to select',
  },
  addNewAddress: {
    id: 'addNewAddress',
    defaultMessage: 'Add new address',
  },
  selectAddress: {
    id: 'selectAddress',
    defaultMessage: 'Select address',
  },
  addAddress: {
    id: 'addAddress',
    defaultMessage: 'Add address',
  },
  noAddressDeclared: {
    id: 'noAddressDeclared',
    defaultMessage: 'No Address declared',
  },
  addSelectedAddress: {
    id: 'addSelectedAddress',
    defaultMessage: 'Add selected address',
  },
  increasedDeliveryCosts: {
    id: 'increasedDeliveryCosts',
    defaultMessage:
      'Shipping outside of Germany is causing Increased delivery costs',
  },
  deliveryTimeDependingOnLocation: {
    id: 'deliveryTimeDependingOnLocation',
    defaultMessage: 'Delivery time depends on your location',
  },
  europe: {
    id: 'europe',
    defaultMessage: 'europe',
  },
  reloadPage: {
    id: 'reloadPage',
    defaultMessage: 'Reload page',
  },
});

export const formMessages = defineMessages({
  name: {
    id: 'name',
    defaultMessage: 'Name',
  },
  namePlaceholder: {
    id: 'namePlaceholder',
    defaultMessage: 'John Doe',
  },
  emailPlaceholder: {
    id: 'emailPlaceholder',
    defaultMessage: 'john.doe@example.com',
  },
  email: {
    id: 'email',
    defaultMessage: 'Email',
  },
  password: {
    id: 'password',
    defaultMessage: 'Password',
  },
  repeatPassword: {
    id: 'repeatPassword',
    defaultMessage: 'Repeat password',
  },
  street: {
    id: 'street',
    defaultMessage: 'street',
  },
  telephone: {
    id: 'telephone',
    defaultMessage: 'telephone',
  },
  streetPlaceholder: {
    id: 'streetPlaceholder',
    defaultMessage: 'wall street',
  },
  houseNumber: {
    id: 'houseNumber',
    defaultMessage: 'house nr.',
  },
  zipCode: {
    id: 'zipCode',
    defaultMessage: 'zip code',
  },
  city: {
    id: 'city',
    defaultMessage: 'city',
  },
  cityPlaceholder: {
    id: 'cityPlaceholder',
    defaultMessage: 'springfield',
  },
  medicSearchPlaceholder: {
    id: 'medicSearchPlaceholder',
    defaultMessage: 'insert medicine name or PZN',
  },
  yourMessage: {
    id: 'yourMessage',
    defaultMessage: 'your message',
  },
  yourMessagePlaceholder: {
    id: 'yourMessagePlaceholder',
    defaultMessage: 'Insert your message here...',
  },
  search: {
    id: 'search',
    defaultMessage: 'search',
  },
  insertAddressOrZip: {
    id: 'insertAddressOrZip',
    defaultMessage: 'insert your address or zip',
  },
  noEmptyField: {
    id: 'noEmptyField',
    defaultMessage: 'This field is not allowed to be empty!',
  },
  noValidEmail: {
    id: 'noValidEmail',
    defaultMessage: 'This email is invalid!',
  },
  notes: {
    id: 'notes',
    defaultMessage: 'Notes',
  },
  fax: {
    id: 'fax',
    defaultMessage: 'Fax',
  },
});
