import { Button, Paper } from '@material-ui/core';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { FluidTypography } from '../../../../app-util-components/FluidTypography';
import { I18nMessage } from '../../../../app-util-components/I18nMessage';
import { appMessages } from '../../../../app-utilities/appMessages';
import { appGrid } from '../../../../app-utilities/cssClasses';
import { CtxPathLink } from '../../../../url-utils';
import { pharmacyCtxToPath } from '../../../layout/PharmacyBaseLink';
import { PharmacyValuesConsumer } from '../../../PharmacyContext';
import { shoppingCartPath } from '../../../routes';

const messages = defineMessages({
  medicsCantBeSent: {
    id: 'medicsCantBeSent',
    defaultMessage: 'Leider können diese Medikamente nicht versandt werden',
  },
});

export const PostalDeliveryNotAvailable = () => (
  <PharmacyValuesConsumer>
    {pharmacyCtx => (
      <div style={{ width: '100%' }}>
        <Paper className={appGrid} style={{ padding: 16 }}>
          <FluidTypography type="headline3">
            <I18nMessage message={messages.medicsCantBeSent} />
          </FluidTypography>
          <FluidTypography>
            <I18nMessage message={appMessages.noPostalDeliveryAllowed} />
          </FluidTypography>
          <div style={{ alignSelf: 'flex-end', display: 'flex' }}>
            <Button
              component={compPops => (
                <CtxPathLink
                  {...compPops}
                  path={pharmacyCtxToPath(shoppingCartPath, pharmacyCtx)}
                />
              )}
            >
              <I18nMessage message={appMessages.back} />
            </Button>
          </div>
        </Paper>
      </div>
    )}
  </PharmacyValuesConsumer>
);
