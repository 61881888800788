import * as React from 'react';
import { ButtonProps } from '@material-ui/core/Button';
import { Button } from '@material-ui/core';
import { WithIcon } from '@apoly-42/apoly-components';
import { faChevronDoubleRight } from '@fortawesome/pro-regular-svg-icons';

interface CtaButtonProps extends ButtonProps {}

export class CtaButton extends React.PureComponent<CtaButtonProps> {
  render() {
    const { children, ...rest } = this.props;
    return (
      <Button variant="raised" color="secondary" {...rest}>
        <WithIcon icon={faChevronDoubleRight}>{children}</WithIcon>
      </Button>
    );
  }
}
