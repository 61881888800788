/* eslint-disable quote-props */
import {
  BlindAnchor,
  FormattedEuro,
  I18nMessage,
} from '@apoly-42/apoly-components';
import { withHandler } from '@apoly-42/apoly-utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Grid, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { pipe, tap } from 'ramda';
import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { commonMessages } from '../../../../constants/messages/commonMessages';
import {
  flex,
  flexAlignFlexStart,
  marginAuto,
} from '../../../../constants/styles/commonClasses';
import { singleProductSetPharmacyPath } from '../../../../routes/paths';
import { articleBaseInformation } from '../../../../utilities/analyticsLayerUtils/productLayerEvents';
import googleDataLayerPush from '../../../../utilities/googleDataLayerPush';
import {
  checkDeliveryStatus,
  getArticleWithArticleId,
} from '../../../../utilities/product/productUtilities';
import ProductImageSmall from '../../../imageComponents/productImages/ProductImageSmall';
import AddRemoveButtonGroup from './AddRemoveButtonGroup';

const styles = {
  link: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  productImg: {
    maxWidth: '100%',
    margin: 'auto',
  },
};

const InnerShoppingCartItem = ({
  classes,
  articles,
  articleId,
  deleteItem,
  increaseItemAmount,
  decreaseItemAmount,
  count,
  basePath,
  trackDecrease,
  trackIncrease,
  trackRemove,
}) => {
  const article = getArticleWithArticleId(articles, articleId);

  const minusDisabled = count <= 1;
  const { medicine } = article.package;
  const url = singleProductSetPharmacyPath(
    basePath,
    article.package.medicine.urlCode,
    article.package.urlCode
  );
  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} md={8}>
        <Grid container spacing={16}>
          <Grid item xs={3} style={flexAlignFlexStart}>
            <ProductImageSmall
              name={medicine.name}
              imgUrl={article.package.imgUrl}
              style={{
                maxWidth: '100%',
                margin: 'auto',
                maxHeight: 160,
              }}
            />
          </Grid>
          <Grid item xs={9}>
            <Grid container spacing={16}>
              <Grid item xs={12}>
                <Typography
                  variant="title"
                  component={Link}
                  to={url}
                  className={classes.link}
                  dangerouslySetInnerHTML={{ __html: medicine.name }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption">
                  {article.package.amountText} | PZN: {article.package.pzn}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <BlindAnchor
                  onClick={pipe(
                    tap(trackRemove),
                    deleteItem
                  )}
                >
                  <Typography variant="caption">
                    <I18nMessage message={commonMessages.delete} />
                  </Typography>
                </BlindAnchor>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} md={1} style={flex}>
        <Typography style={marginAuto}>
          <FormattedEuro value={Number(article.price)} />
        </Typography>
      </Grid>
      <Grid item xs={6} md={2} style={flex}>
        <AddRemoveButtonGroup
          minusDisabled={minusDisabled}
          value={count}
          addFunc={pipe(
            tap(trackIncrease),
            increaseItemAmount
          )}
          removeFunc={pipe(
            tap(trackDecrease),
            decreaseItemAmount
          )}
        />
      </Grid>
      <Grid item xs={3} md={1} style={flex}>
        <FontAwesomeIcon
          style={marginAuto}
          icon={checkDeliveryStatus(article.availability)}
        />
      </Grid>
    </Grid>
  );
};

const mapPropsToIncreaseEvent = (props, event, bla) => ({
  event,
  ecommerce: {
    currencyCode: 'EUR',
    actionField: { list: 'Warenkorb' },
    remove: {
      products: [
        {
          ...articleBaseInformation(
            getArticleWithArticleId(props.articles, props.articleId).package
              .medicine,
            getArticleWithArticleId(props.articles, props.articleId).package,
            getArticleWithArticleId(props.articles, props.articleId)
          ),
          quantity: bla === null ? props.count : bla,
        },
      ],
    },
  },
});

const enhance = compose(
  withStyles(styles),
  withHandler('trackDecrease', props => () =>
    googleDataLayerPush(mapPropsToIncreaseEvent(props, 'removeFromCart', null))
  ),
  withHandler('trackIncrease', props => () =>
    googleDataLayerPush(mapPropsToIncreaseEvent(props, 'addToCart', null))
  ),
  withHandler('trackRemove', props => () =>
    googleDataLayerPush(mapPropsToIncreaseEvent(props, 'removeFromCart', 0))
  )
);

const ShoppingCartItem = enhance(InnerShoppingCartItem);

ShoppingCartItem.propTypes = {
  articles: PropTypes.array.isRequired,
  articleId: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  deleteItem: PropTypes.func.isRequired,
  increaseItemAmount: PropTypes.func.isRequired,
  decreaseItemAmount: PropTypes.func.isRequired,
};

InnerShoppingCartItem.propTypes = {
  classes: PropTypes.object.isRequired,
  ...ShoppingCartItem.propTypes,
};

export default ShoppingCartItem;
