import { BlindAnchor, I18nMessage } from '@apoly-42/apoly-components';
import { Typography } from '@material-ui/core';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import React from 'react';
import { commonMessages } from '../../../../constants/messages/commonMessages';
import { flex, marginAuto } from '../../../../constants/styles/commonClasses';
import {
  appGrid,
  gridArea,
} from '../../../../constants/styles/cssEmotionClasses';
import AddRemoveButtonGroup from './AddRemoveButtonGroup';

export const addDeleteBtnGrid = css`
  ${appGrid};
  grid-template-columns: 25% minmax(0, 1fr);
  grid-template-areas: 'delete addRemoveButtons';
`;

const ProductDeleteAddResponsive = ({
  deleteItem,
  count,
  increaseItemAmount,
  decreaseItemAmount,
}) => {
  const minusDisabled = count <= 1;
  return (
    <div className={addDeleteBtnGrid}>
      <div className={gridArea('delete')}>
        <div style={flex}>
          <BlindAnchor style={marginAuto} onClick={deleteItem}>
            <Typography variant="caption">
              <I18nMessage message={commonMessages.delete} />
            </Typography>
          </BlindAnchor>
        </div>
      </div>
      <div className={gridArea('addRemoveButtons')}>
        <div style={{ display: 'flex', maxWidth: '90%' }}>
          <AddRemoveButtonGroup
            minusDisabled={minusDisabled}
            value={count}
            addFunc={increaseItemAmount}
            removeFunc={decreaseItemAmount}
          />
        </div>
      </div>
    </div>
  );
};

ProductDeleteAddResponsive.propTypes = {
  count: PropTypes.number.isRequired,
  deleteItem: PropTypes.func.isRequired,
  increaseItemAmount: PropTypes.func.isRequired,
  decreaseItemAmount: PropTypes.func.isRequired,
};

export default ProductDeleteAddResponsive;
