import { Address, PaymentMethod } from '../../apolyApi';
import { PharmacyChannel } from '../../routes-helper';
import { DeliveryType } from './OtcOrderTypes';

export enum CheckoutPssStep {
  billingData = 1,
  payment,
  closure,
}

export enum CheckoutDefaultStep {
  billingData = 1,
  deliveryData,
  payment,
  closure,
}

export enum CheckoutOffizinStep {
  billingData = 1,
  closure,
}

export interface CompletedCheckoutOffizin {
  billingAddress?: Address;
  newsletterOptIn: boolean;
  agbOptIn: boolean;
}

export interface CompletedCheckoutPss {
  email: string;
  billingAddress?: Address;
  paymentMethod: PaymentMethod;
  newsletterOptIn: boolean;
  agbOptIn: boolean;
}

export type PssCheckoutValues = CompletedCheckoutPss & {
  channel: PharmacyChannel.pss;
};

export type CustomerBoardCheckoutValues = CompletedCheckoutOffizin & {
  channel: PharmacyChannel.customerBoard;
};

export interface CompletedCheckoutDefault {
  email: string;
  telephone?: string;
  billingAddress?: Address;
  deliveryAddress?: Address;
  deliveryType: DeliveryType;
  paymentMethod: PaymentMethod;
  newsletterOptIn: boolean;
  agbOptIn: boolean;
}

// TODO aufsplitten nochmal
export type DefaultCheckoutValues = CompletedCheckoutDefault & {
  channel: PharmacyChannel.local;
};

export type CheckoutValues =
  | PssCheckoutValues
  | CustomerBoardCheckoutValues
  | DefaultCheckoutValues;
