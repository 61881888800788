import landingSliderPic1 from './sliderLandingPage/veritas_slider1.jpg';
import landingSliderPic2 from './sliderLandingPage/veritas_slider2.jpg';
import landingSliderPic3 from './sliderLandingPage/veritas_slider3.jpg';
import logo from './veritas_logo.png';

export const veritasLogo = logo;

export const veritasLandingPageSliderImages = [
  landingSliderPic1,
  landingSliderPic2,
  landingSliderPic3,
];
