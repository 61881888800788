import * as React from 'react';
import {
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
  MessageValue,
} from 'react-intl';
import {
  MuiFormikTextField,
  MuiFormikTextFieldProps,
} from './MuiFormikTextField';

interface MuiFormikTextFieldI18nProps extends MuiFormikTextFieldProps {
  labelMessage?: FormattedMessage.MessageDescriptor;
  labelValues?: { [key: string]: MessageValue };
  placeholderMessage?: FormattedMessage.MessageDescriptor;
  placeholderValues?: { [key: string]: MessageValue };
}

export const MuiFormikTextFieldI18n = injectIntl(
  class InnerMuiFormikTextFieldI18n extends React.PureComponent<
    MuiFormikTextFieldI18nProps & InjectedIntlProps
  > {
    render() {
      const {
        intl: { formatMessage },
        labelMessage,
        labelValues,
        label,
        placeholderMessage,
        placeholderValues,
        placeholder,
        ...rest
      } = this.props;

      return (
        <MuiFormikTextField
          label={
            labelMessage ? formatMessage(labelMessage, labelValues) : label
          }
          placeholder={
            placeholderMessage
              ? formatMessage(placeholderMessage, placeholderValues)
              : placeholder
          }
          {...rest}
        />
      );
    }
  }
);
