let Raven;
let notAvailable = false;

const fulfillOnRaven = (resolve, reject) => {
  if (Raven) {
    resolve(Raven);
  } else if (notAvailable) {
    reject(new Error('sentry was not initialized'));
  } else {
    setTimeout(fulfillOnRaven.bind(null, resolve), 200);
  }
};

export const getRaven = () => {
  if (!Raven) {
    return new Promise(fulfillOnRaven);
  }

  return Promise.resolve(Raven);
};

const setupRaven = (apiKey, config) => RavenInstance => {
  RavenInstance.config(apiKey, config).install();
  Raven = RavenInstance;
};

export const loadRaven = (apiKey, config) =>
  import('raven-js').then(setupRaven(apiKey, config));

export const noRavenSetup = () => {
  notAvailable = true;
};
