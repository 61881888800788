import { ListWithBulletPoints } from '@apoly-42/material-ui';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Grid, Theme, WithStyles, withStyles } from '@material-ui/core';
import React from 'react';
import { defineMessages } from 'react-intl';
import { I18nMessage } from '../../../app-util-components/I18nMessage';
import { appMessages } from '../../../app-utilities/appMessages';
import { BRAND_NAME } from '../../../environment';
import { FooterHeadline } from './FooterHeadline';

const messages = defineMessages({
  MadeInGermany: {
    id: 'MadeInGermany',
    defaultMessage: 'made in germany',
  },
  highestAvailability: {
    id: 'highestAvailability',
    defaultMessage: 'highest availability',
  },
});

// tslint:disable:jsx-key
export const secureWithBrand: React.ReactNode[] = [
  <I18nMessage message={appMessages.germanServer} />,
  <I18nMessage message={messages.MadeInGermany} />,
  <I18nMessage message={appMessages.sslSecureConnection} />,
  <I18nMessage message={messages.highestAvailability} />,
];

const styles = (theme: Theme) => ({
  listItem: theme.typography.body1,
});

export const InnerSafeWithBrand: React.SFC<WithStyles<typeof styles>> = ({
  classes,
}) => (
  <Grid container={true} spacing={16}>
    <Grid item={true} xs={12}>
      <FooterHeadline>
        <I18nMessage
          message={appMessages.safeWithApoly}
          values={{ brand: BRAND_NAME }}
        />
      </FooterHeadline>
    </Grid>
    <Grid item={true} xs={12} style={{ color: '#fff' }}>
      <ListWithBulletPoints
        listArray={secureWithBrand}
        icon={faCheck}
        listItemTextClassName={classes.listItem}
      />
    </Grid>
  </Grid>
);


export const SafeWithBrand = withStyles(styles)(InnerSafeWithBrand);
