import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { compose } from 'recompose';
import apoPanorama from './assets/apotheke-panorama.png';

const startHeight = 400;

const styles = theme => ({
  panorama: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top',
    backgroundImage: `url(${apoPanorama})`,
    backgroundSize: `cover ${startHeight + 0 * 40}px`,
    [`${theme.breakpoints.up('xs')}`]: {
      backgroundSize: `cover ${startHeight + 1 * 40}px`,
    },
    [theme.breakpoints.up('sm')]: {
      backgroundSize: `cover ${startHeight + 2 * 40}px`,
    },
    [theme.breakpoints.up('md')]: {
      backgroundSize: `cover ${startHeight + 3 * 40}px`,
    },
    [theme.breakpoints.up('lg')]: {
      backgroundSize: `cover ${startHeight + 4 * 40}px`,
    },
  },
});

const InnerPharmacyPanorama = props => (
  <div className={props.classes.panorama}>{props.children}</div>
);

const enhance = compose(withStyles(styles));

const PharmacyPanorama = enhance(InnerPharmacyPanorama);

PharmacyPanorama.propTypes = {};

PharmacyPanorama.defaultProps = {};

InnerPharmacyPanorama.propTypes = {
  classes: PropTypes.object.isRequired,
  ...PharmacyPanorama.propTypes,
};

InnerPharmacyPanorama.defaultProps = {};

export default PharmacyPanorama;
