import {
  Dialog,
  DialogContent,
  DialogTitle,
  withMobileDialog,
} from '@material-ui/core';
import * as React from 'react';
import { AddressFromInput } from '../../app-utilities/toStringUtils';
import { Address } from '../../app/apolyApi';
import { commonMessages } from '../../deprecated/deprecated-apoly-app/constants/messages/commonMessages';
import { I18nMessage } from '../I18nMessage';
import { AddressForm } from './AddressForm';
import { CheckAddress } from './CheckAddress';
import { css } from 'emotion';
import { mdUp } from '../../app-utilities/cssClasses';

interface Props {
  isOpen: boolean;
  validateAddress?: (address: Address) => boolean;
  onSubmit: (address: Address) => void;
  onCloseClick: () => void;
}

interface InnerProps extends Props {
  fullScreen?: boolean;
}

enum Steps {
  input = 1,
  check,
}

interface State {
  address?: AddressFromInput;
  step: Steps.input;
}

interface State1 {
  address: AddressFromInput;
  step: Steps.check;
}

const dialogContentSize = css`
  ${mdUp} {
    width: 600px;
    height: 440px;
  }
`;

export const AddressDialog = withMobileDialog<Props>()(
  class InnerAddressDialog extends React.PureComponent<InnerProps> {
    state: State | State1 = {
      address: undefined,
      step: Steps.input,
    };

    render() {
      const {
        isOpen,
        fullScreen,
        onSubmit,
        validateAddress,
        onCloseClick,
      } = this.props;

      return (
        <Dialog
          open={isOpen}
          fullScreen={fullScreen}
          disableBackdropClick={true}
        >
          <DialogTitle>
            <I18nMessage message={commonMessages.addNewAddress} />
          </DialogTitle>

          <DialogContent className={dialogContentSize}>
            {this.state.step === Steps.input && (
              <AddressForm
                initialAddress={this.state.address}
                onSubmit={address => {
                  this.setState({ address, step: Steps.check });
                }}
                onCancelClick={onCloseClick}
              />
            )}

            {this.state.step === Steps.check && (
              <CheckAddress
                onSubmit={onSubmit}
                address={this.state.address}
                validateAddress={validateAddress}
                onBackButtonClick={() => this.setState({ step: Steps.input })}
              />
            )}
          </DialogContent>
        </Dialog>
      );
    }
  }
);
