import {
  getNextDays,
  momentWithNewTime,
  secondsOfDayFromTimeStr,
  toSecondsOfDay,
} from '../momentUtilities';
import { isInHoliday, timeSpanIsOnWeekday } from './timehandlingUtilities';

const openingToIsLaterThan = timeInSeconds => openingTime =>
  timeInSeconds < secondsOfDayFromTimeStr(openingTime.openingTo);

const addComparableUtils = moment => openingTime => ({
  ...openingTime,
  comparableSeconds:
    moment.unix() + secondsOfDayFromTimeStr(openingTime.openingFrom),
  openingFromMoment: momentWithNewTime(moment, openingTime.openingFrom),
  openingToMoment: momentWithNewTime(moment, openingTime.openingTo),
});

export const getOpeningTimesForDay = openingTimes => timeMoment =>
  openingTimes
    .filter(timeSpanIsOnWeekday(timeMoment.isoWeekday()))
    .filter(openingToIsLaterThan(toSecondsOfDay(timeMoment)))
    .map(addComparableUtils(timeMoment))
    .sort((a, b) => a.comparableSeconds - b.comparableSeconds);

const getOpeningTimesForNextDays = (
  numberOfNextDays,
  serverTimeMoment,
  openingTimes,
  holidays
) =>
  [serverTimeMoment, ...getNextDays(numberOfNextDays, serverTimeMoment)]
    .filter(mom => !isInHoliday(holidays, mom))
    .map(getOpeningTimesForDay(openingTimes))
    .reduce(
      (nextOpeningTimes, openingTimesForDay) => [
        ...nextOpeningTimes,
        ...openingTimesForDay,
      ],
      []
    );

export default getOpeningTimesForNextDays;
