import { Loading } from '@apoly-42/material-ui';
import * as React from 'react';
import { Redirect } from 'react-router';
import { Load } from '../../../../app-util-components/Load';
import { createOtcOrder } from '../../../../deprecated/apolyApi';
import {
  otcOrderCompletePath,
  otcPaymentFailedPath,
} from '../../../../deprecated/deprecated-apoly-app/routes/paths';
import { wirecardPaymentRequest } from '../../../../deprecated/deprecated-apoly-app/utilities/apis/apolyApi/apolyApi';
import { rfcUrl, RfcUrl } from '../../../../url';
import {
  CashOtcOrder,
  EPaymentOtcOrder,
  OtcOrder,
  PaymentMethod,
  Pharmacy,
} from '../../../apolyApi';
import { pharmacyCtxToPath } from '../../../layout/PharmacyBaseLink';
import { pharmacyBase } from '../../../routes';
import { PharmacyChannel } from '../../../routes-helper';
import { WirecardRequestFromApi } from './wirecard-utils';
import { WirecardWrapper } from './WirecardWrapper';

type OtcGuestOrderResponse = { otcOrderKey: string };

const postNewGuestOrder = (order: OtcOrder): Promise<OtcGuestOrderResponse> => {
  return createOtcOrder(order);
};

interface Props {
  urlBase: RfcUrl;
  order: OtcOrder;
  pharmacy: Pharmacy;
  pharmacyChannel: PharmacyChannel;
}

export class SubmitGuestOrder extends React.PureComponent<Props> {
  toUrl = (path: string) => rfcUrl(path, this.props.urlBase).toString();

  /*
  // TODO check wie man am sinnvollsten async await einbaut in webpack-bundle und dann wieder die sachen umschreiben
   postNewGuestOrderWithEPayment = async (
    order: EPaymentOtcOrder
  ): Promise<WirecardRequestFromApi> => {
    const basePath = pharmacyCtxToPath(pharmacyBase, this.props);

    const { otcOrderKey } = await postNewGuestOrder(order);

    return await wirecardPaymentRequest(
      otcOrderKey,
      this.toUrl(otcOrderCompletePath(basePath, otcOrderKey)),
      this.toUrl(otcPaymentFailedPath(basePath, otcOrderKey))
    );
  };
   */
  postNewGuestOrderWithEPayment = (
    order: EPaymentOtcOrder
  ): Promise<WirecardRequestFromApi> => {
    const basePath = pharmacyCtxToPath(pharmacyBase, this.props);

    return postNewGuestOrder(order).then(({ otcOrderKey }) =>
      wirecardPaymentRequest(
        otcOrderKey,
        this.toUrl(otcOrderCompletePath(basePath, otcOrderKey)),
        this.toUrl(otcPaymentFailedPath(basePath, otcOrderKey))
      )
    );
  };

  renderSubmitEPaymentUserOrder = (order: EPaymentOtcOrder) => (
    <Load fn={() => this.postNewGuestOrderWithEPayment(order)}>
      {apiState => (
        <React.Fragment>
          {apiState.isLoading && <Loading />}

          {apiState.response && (
            <WirecardWrapper wcRequestFromApi={apiState.response} />
          )}
        </React.Fragment>
      )}
    </Load>
  );

  renderSubmitCashPaymentUserOrder = (order: CashOtcOrder) => {
    const basePath = pharmacyCtxToPath(pharmacyBase, this.props);

    return (
      <Load fn={() => postNewGuestOrder(order)}>
        {({ response, isLoading }) => (
          <React.Fragment>
            {isLoading && <Loading />}

            {response && (
              <Redirect
                to={otcOrderCompletePath(basePath, response.otcOrderKey)}
              />
            )}
          </React.Fragment>
        )}
      </Load>
    );
  };

  render() {
    const { order } = this.props;

    return order.paymentType === PaymentMethod.cash
      ? this.renderSubmitCashPaymentUserOrder(order)
      : this.renderSubmitEPaymentUserOrder(order);
  }
}
