import { Avatar, MenuItem } from '@material-ui/core';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { fullMaxWidth } from '../../constants/styles/commonClasses';
import SymptomImage from '../imageComponents/SymptomImage';

const renderSymptomSuggestion = (
  suggestion,
  bigAvatar,
  { query, isHighlighted } = {}
) => (
  <MenuItem style={{ marginTop: 8 }} selected={isHighlighted} component="div">
    <Avatar style={bigAvatar}>
      <SymptomImage
        altTxt={suggestion.name}
        style={fullMaxWidth}
        imageUrl={suggestion.pictureUrl}
      />
    </Avatar>
    <div>
      <Highlighter
        highlightStyle={{ fontWeight: 600, backgroundColor: 'transparent' }}
        searchWords={[query]}
        autoEscape
        textToHighlight={suggestion.name}
      />
    </div>
  </MenuItem>
);

export default renderSymptomSuggestion;
