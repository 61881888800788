import {
  faFacebookF,
  faGooglePlusG,
  faInstagram,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@material-ui/core';
import { css } from 'emotion';
import React from 'react';
import { flexAlignCenter } from '../../../app-utilities/cssClasses';
import { FooterHeadline } from './FooterHeadline';

const container = css`
  display: flex;
  margin: auto;
`;

const button = css`
    margin: 0 8px;
    @media(max-width: 600px) {
      width: 48px;
      height: 48px;
    `;

export interface SocialMediaProps {
  title?: React.ReactNode;
  colored?: boolean;
}

export const SocialMedia: React.SFC<SocialMediaProps> = ({
  title = null,
  colored = false,
}) => {
  const iconColor = colored ? 'primary' : 'default';
  return (
    <Grid container={true} spacing={16}>
      {title && (
        <Grid item={true} xs={12}>
          <FooterHeadline align="center">{title}</FooterHeadline>
        </Grid>
      )}
      <Grid item={true} xs={12} className={flexAlignCenter}>
        <div className={container}>
          <Button
            variant="fab"
            color={iconColor}
            aria-label="facebook"
            target="_blank"
            rel="noopener"
            href={'https://www.facebook.com/apoly.de/'}
            className={button}
          >
            <FontAwesomeIcon icon={faFacebookF} />
          </Button>
          <Button
            variant="fab"
            color={iconColor}
            aria-label="twitter"
            target="_blank"
            rel="noopener"
            href={'https://twitter.com/ApolyDE'}
            className={button}
          >
            <FontAwesomeIcon icon={faTwitter} />
          </Button>
          <Button
            variant="fab"
            color={iconColor}
            aria-label="google plus"
            target="_blank"
            rel="noopener"
            href={'https://plus.google.com/+ApolyDe'}
            className={button}
          >
            <FontAwesomeIcon icon={faGooglePlusG} />
          </Button>
          <Button
            variant="fab"
            color={iconColor}
            aria-label="instagram"
            target="_blank"
            rel="noopener"
            href={'https://www.instagram.com/Apoly.de/'}
            className={button}
          >
            <FontAwesomeIcon icon={faInstagram} />
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};
