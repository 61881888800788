import { faMinusSquare, faPlusSquare } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  Input,
  InputAdornment,
  withStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  inputAmount: {
    color: 'black',
    alignItems: 'center',
    '& > *': {
      textAlign: 'center',
    },
  },
};

const AddRemoveButtonGroup = ({
  classes,
  fullWidth,
  addFunc,
  removeFunc,
  minusDisabled,
  value,
}) => (
  <div style={{ margin: 'auto', width: fullWidth ? '100%' : '70%' }}>
    <Input
      fullWidth
      startAdornment={
        <InputAdornment position="start">
          <IconButton
            color="primary"
            onClick={removeFunc}
            disabled={minusDisabled}
          >
            <FontAwesomeIcon icon={faMinusSquare} />
          </IconButton>
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <IconButton color="primary" onClick={addFunc}>
            <FontAwesomeIcon icon={faPlusSquare} />
          </IconButton>
        </InputAdornment>
      }
      className={classes.inputAmount}
      value={value}
      disabled
      disableUnderline
    />
  </div>
);

AddRemoveButtonGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  addFunc: PropTypes.func.isRequired,
  removeFunc: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  minusDisabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

AddRemoveButtonGroup.defaultProps = {
  minusDisabled: false,
  fullWidth: true,
};

export default withStyles(styles)(AddRemoveButtonGroup);
