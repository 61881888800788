import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import { Button } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';
import {
  defineMessages,
  InjectedIntl,
  InjectedIntlProps,
  injectIntl,
} from 'react-intl';
import {
  buttonXs,
  verticalCenteredInFlex,
} from '../../../../../app-utilities/cssClasses';
import { SizedFaIcon } from '../../../../../app-util-components/SizedFaIcon';

const messages = defineMessages({
  toggleSearchBar: {
    id: 'toggleSearchBar',
    defaultMessage: 'toggle search bar',
  },
});

export interface FindMedicButtonProps {
  toggleSearchBar: () => void;
  disabled: boolean;
  intl: InjectedIntl;
}

export const FindMedicButton = injectIntl(
  class InnerFindMedicButton extends React.PureComponent<
    FindMedicButtonProps & InjectedIntlProps
  > {
    render() {
      const { toggleSearchBar, disabled, intl } = this.props;

      return (
        <Button
          className={cn(verticalCenteredInFlex, buttonXs)}
          disabled={disabled}
          color="inherit"
          aria-label={intl.formatMessage(messages.toggleSearchBar)}
          onClick={toggleSearchBar}
        >
          <span>
            <SizedFaIcon icon={faSearch} />
          </span>
        </Button>
      );
    }
  }
);
