import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { BRAND_NAME } from '../../../environment';
import HelmetComponentWithMessage from '../../helmet/HelmetComponentWithMessage';
import ShoppingCartView from './ShoppingCartView';

const messages = defineMessages({
  shoppingCartTitle: {
    id: 'shoppingCartTitle',
    defaultMessage: 'your {brand}-shoppingcart',
  },
});

const ShoppingCart = props => (
  <React.Fragment>
    <HelmetComponentWithMessage
      pageTitle={messages.shoppingCartTitle}
      values={{ brand: BRAND_NAME }}
      noIndex
      noFollow
    />

    <ShoppingCartView submit={props.submit} pharmacyId={props.pharmacyId} />
  </React.Fragment>
);

ShoppingCart.propTypes = {
  submit: PropTypes.func,
  pharmacyId: PropTypes.number.isRequired,
};

ShoppingCart.defaultProps = {
  submit: null,
};

export default ShoppingCart;
