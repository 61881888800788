import {
  createAction,
  createReducer,
  findInObject,
  objectFromKeys,
} from '@apoly-42/apoly-utils';

const deleteProductFromCart = (shoppingCart, articleId) =>
  objectFromKeys(
    Object.keys(shoppingCart).filter(
      key => shoppingCart[key].articleId !== articleId.toString()
    ),
    key => shoppingCart[key]
  );

const getCurrentArticleCount = (shoppingCart, articleId, articleCount) => {
  const article = findInObject(
    currentArticle => currentArticle.articleId === articleId,
    shoppingCart
  );

  return articleCount + (article ? article.count : 0);
};

const addProductToCart = (shoppingCart, payload) => ({
  ...shoppingCart,
  [payload.articleId.toString()]: {
    articleId: payload.articleId.toString(),
    articlePrice: payload.articlePrice,
    count: getCurrentArticleCount(
      shoppingCart,
      payload.articleId.toString(),
      payload.articleCount || 1
    ),
  },
});

const changeCartItemAmount = changeAmount => (shoppingCart, itemId) => ({
  ...shoppingCart,
  [itemId.toString()]: {
    ...shoppingCart[itemId.toString()],
    count: changeAmount + shoppingCart[itemId].count,
  },
});

const decreaseCartItemCount = changeCartItemAmount(-1);
const increaseCartItemCount = changeCartItemAmount(1);

const setLastAddedProduct = payload => ({
  articleId: payload.articleId.toString(),
  count: payload.articleCount,
});

const clearCart = {
  shoppingCartPrice: '',
  products: {},
  shoppingCartLength: '',
  articleIdArray: [],
  lastAddedItemToCart: null,
};

export const addItemToShoppingCart = createAction('addItemToShoppingCart');
export const deleteItemFromShoppingCart = createAction(
  'deleteItemFromShoppingCart'
);
export const increaseItemAmount = createAction('increaseItemAmount');
export const decreaseItemAmount = createAction('decreaseItemAmount');
export const clearShoppingCart = createAction('clearShoppingCart');
export const clearLastAddedItemToCart = createAction(
  'clearLastAddedItemToCart'
);
export const overwriteShoppingCartState = createAction(
  'overwriteShoppingCartState'
);

const reducer = createReducer(
  {},
  {
    [addItemToShoppingCart]: (state, { payload }) => ({
      ...state,
      [payload.pharmacyId]: {
        ...state[payload.pharmacyId],
        products: addProductToCart(
          state[payload.pharmacyId] ? state[payload.pharmacyId].products : [],
          payload
        ),
        lastAddedItemToCart: setLastAddedProduct(payload),
      },
    }),
    [deleteItemFromShoppingCart]: (state, { payload }) => ({
      ...state,
      [payload.pharmacyId]: {
        ...state[payload.pharmacyId],
        products: deleteProductFromCart(
          state[payload.pharmacyId].products,
          payload.articleId
        ),
      },
    }),
    [increaseItemAmount]: (state, { payload }) => ({
      ...state,
      [payload.pharmacyId]: {
        ...state[payload.pharmacyId],
        products: increaseCartItemCount(
          state[payload.pharmacyId].products,
          payload.articleId
        ),
      },
    }),
    [decreaseItemAmount]: (state, { payload }) => ({
      ...state,
      [payload.pharmacyId]: {
        ...state[payload.pharmacyId],
        products: decreaseCartItemCount(
          state[payload.pharmacyId].products,
          payload.articleId
        ),
      },
    }),
    [clearShoppingCart]: (state, { payload }) => ({
      ...state,
      [payload]: { ...clearCart },
    }),
    [clearLastAddedItemToCart]: (state, { payload }) => ({
      ...state,
      [payload]: {
        ...state[payload],
        lastAddedItemToCart: null,
      },
    }),
    [overwriteShoppingCartState]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  }
);

export default reducer;
