import PropTypes from 'prop-types';
import React from 'react';
import { injectIntl } from 'react-intl';
import { compose } from 'recompose';
import HelmetComponent from './HelmetComponent';

const InnerHelmetComponentWithMessage = ({
  pageTitle,
  values,
  intl: { formatMessage },
  keywords,
  description,
  ...props
}) => (
  <HelmetComponent
    pageTitle={formatMessage(pageTitle, values)}
    keywords={keywords ? formatMessage(keywords, values) : undefined}
    description={description ? formatMessage(description, values) : undefined}
    {...props}
  />
);

const enhance = compose(injectIntl);

const HelmetComponentWithMessage = enhance(InnerHelmetComponentWithMessage);

HelmetComponentWithMessage.propTypes = {
  noLanguageRef: PropTypes.bool,
  pageTitle: PropTypes.any.isRequired,
  keywords: PropTypes.any,
  description: PropTypes.any,
  path: PropTypes.string,
  values: PropTypes.object,
  noIndex: PropTypes.bool,
  noFollow: PropTypes.bool,
  follow: PropTypes.bool,
};

HelmetComponentWithMessage.defaultProps = {
  noLanguageRef: false,
  keywords: null,
  description: null,
  values: undefined, // use undefined cause formatMessage check only for !== undefined
  path: null,
  noIndex: false,
  noFollow: false,
  follow: false,
};

InnerHelmetComponentWithMessage.propTypes = {
  intl: PropTypes.object.isRequired,
  ...HelmetComponentWithMessage.propTypes,
};

export default HelmetComponentWithMessage;
