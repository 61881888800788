import { Typography } from '@material-ui/core';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { I18nMessage } from '../app-util-components/I18nMessage';
import { Address, Pharmacy } from '../app/apolyApi';

export interface AddressFromInput {
  name: string;
  street: string;
  zip: string;
  city: string;
  notes?: string;
}

export const addressToString = ({
  name,
  city,
  street,
  zip,
  notes,
}: Partial<AddressFromInput>) =>
  [name, street, `${zip} ${city}`.trim(), notes].filter(Boolean).join(', ');

const messages = defineMessages({
  billingAddressSummary: {
    id: 'billingAddressSummary',
    defaultMessage: 'Rechnungsadresse: {addressSummary}',
  },
  deliveryAddressSummary: {
    id: 'deliveryAddressSummary',
    defaultMessage: 'Lieferungsaddresse:',
  },
  pickUpAddressSummary: {
    id: 'pickUpAddressSummary',
    defaultMessage: 'Abholungsaddresse:',
  },
  pickUpPssAddressSummary: {
    id: 'pickUpPssAddressSummary',
    defaultMessage: 'Im Abholautomaten reservieren bei Apotheke:',
  },
});

export const BillingDataSummary: React.SFC<{
  billingAddress?: Address;
  telephone: string;
  email: string;
}> = props => (
  <Typography variant="caption">
    <span>{props.email}</span>
    <br />
    <span>{props.telephone}</span>
    {props.billingAddress && (
      <span>
        <br />
        <I18nMessage
          message={messages.billingAddressSummary}
          values={{ addressSummary: addressToString(props.billingAddress) }}
        />
      </span>
    )}
  </Typography>
);

export const PickUpSummary: React.SFC<{
  pharmacy: Pharmacy;
  isPss?: boolean;
}> = props =>
  props.pharmacy && (
    <React.Fragment>
      <Typography variant="caption">
        <I18nMessage
          message={
            props.isPss
              ? messages.pickUpPssAddressSummary
              : messages.pickUpAddressSummary
          }
        />
      </Typography>
      <Typography variant="caption">
        {props.pharmacy.name}, {props.pharmacy.phone}
      </Typography>
      <Typography variant="caption">{props.pharmacy.address.street}</Typography>
      <Typography variant="caption">
        {props.pharmacy.address.zip} {props.pharmacy.address.city}
      </Typography>
    </React.Fragment>
  );

export const DeliverySummary: React.SFC<{
  deliveryAddress: Address;
}> = props => (
  <React.Fragment>
    <Typography variant="caption">
      <I18nMessage message={messages.deliveryAddressSummary} />
    </Typography>
    <Typography variant="caption">{props.deliveryAddress.name}</Typography>
    <Typography variant="caption">{props.deliveryAddress.street}</Typography>
    <Typography variant="caption">
      {props.deliveryAddress.zip} {props.deliveryAddress.city}
    </Typography>
    {props.deliveryAddress.notes && (
      <Typography variant="caption">{props.deliveryAddress.notes}</Typography>
    )}
  </React.Fragment>
);
