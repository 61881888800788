import { createAction, createReducer } from '@apoly-42/apoly-utils';
import { compose, prop } from 'ramda';
import { selectAppStateRoot } from '../selectors';

export const changeServerTimeMoment = createAction('changeServerTimeMoment');
export const changeGermanServerTimeMoment = createAction(
  'changeGermanServerTimeMoment'
);

const initialState = {
  serverTimeMoment: null,
  germanServerTimeMoment: null,
};

const selectServerTimesRoot = compose(
  prop('serverTime'),
  selectAppStateRoot
);

export const selectServerTimeMoment = compose(
  prop('serverTimeMoment'),
  selectServerTimesRoot
);

// the german server-time-moment is created with timezone-info for germany via moment-timezone (https://momentjs.com/timezone/)
// see src/app/containers/ObserveServerTime.js for setting-information
export const selectGermanServerTimeMoment = compose(
  prop('germanServerTimeMoment'),
  selectServerTimesRoot
);

export default createReducer(initialState, {
  [changeServerTimeMoment]: (state, action) => ({
    ...state,
    serverTimeMoment: action.payload,
  }),
  [changeGermanServerTimeMoment]: (state, action) => ({
    ...state,
    germanServerTimeMoment: action.payload,
  }),
});
