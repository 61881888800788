import PropTypes from 'prop-types';
import React from 'react';
import Route from 'react-router-dom/Route';
import RouteSwitch from 'react-router-dom/Switch';
import PageNotFoundError from '../components/PageNotFoundError';

const Route404Switch = ({ children }) => (
  <RouteSwitch>
    {children}
    <Route component={PageNotFoundError} />
  </RouteSwitch>
);

Route404Switch.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Route404Switch;
