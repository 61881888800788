import { ShowComponentWhileLoading } from '@apoly-42/material-ui';
import * as React from 'react';
import { connect } from 'react-redux';
import LoadArticlesContainer from '../../../../deprecated/deprecated-apoly-app/containers/product/LoadArticlesContainer';
import { selectPharmacyId } from '../../../../deprecated/deprecated-apoly-app/redux/shopPharmacy/reducer';
import {
  getShoppingCartArticlesIdArray,
  selectProducts,
} from '../../../../deprecated/deprecated-apoly-app/redux/shoppingCart/selectors';
import { PharmacyValuesConsumer } from '../../../PharmacyContext';
import { CompletedCheckoutDefault } from '../checkoutTypes';
import { DeliveryType } from '../OtcOrderTypes';
import { CheckoutDefault } from './CheckoutDefault';
import { PostalDeliveryNotAvailable } from './PostalDeliveryNotAvailable';

interface CheckoutMailOrderProps {
  deliveryType: DeliveryType;
  onSubmit: (vals: CompletedCheckoutDefault) => void;
  pharmacyId?: number | string;
  articleIdArray?: Array<object> | null;
}

interface CheckoutMailOrderState {
  isLoading: boolean;
  articles: null | object;
  error: Error | null;
}

class InnerCheckoutMailOrder extends React.PureComponent<
  CheckoutMailOrderProps,
  CheckoutMailOrderState
> {
  state = {
    isLoading: false,
    articles: null,
    error: null,
  };

  setIsLoading = (isLoading: CheckoutMailOrderState['isLoading']) =>
    this.setState({ isLoading });

  setError = (error: CheckoutMailOrderState['error']) =>
    this.setState({ error });

  setArticles = (articles: CheckoutMailOrderState['articles']) =>
    this.setState({ articles });

  render() {
    return (
      <React.Fragment>
        <LoadArticlesContainer
          setIsLoading={this.setIsLoading}
          setError={this.setError}
          setArticles={this.setArticles}
          articleIdArray={this.props.articleIdArray}
          pharmacyId={this.props.pharmacyId}
        />

        {this.state.isLoading && (
          <ShowComponentWhileLoading isLoading={this.state.isLoading} />
        )}

        {!this.state.isLoading &&
          this.state.articles && (
            <React.Fragment>
              {console.log(this.state.articles)}
              <PharmacyValuesConsumer>
                {({ pharmacy }) =>
                  pharmacy &&
                  (!pharmacy.trustedPharmacyUrl &&
                  this.state.articles.some(
                    (pharmacyPackage: any) =>
                      Number(
                        pharmacyPackage.package.medicine.apothekenpflichtig
                      ) === 1
                  ) ? (
                    <PostalDeliveryNotAvailable />
                  ) : (
                    <CheckoutDefault
                      disableDelivery={false}
                      deliveryType={this.props.deliveryType}
                      onSubmit={this.props.onSubmit}
                    />
                  ))
                }
              </PharmacyValuesConsumer>
            </React.Fragment>
          )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  products: selectProducts(state),
  articleIdArray: getShoppingCartArticlesIdArray(state),
  pharmacyId: selectPharmacyId(state),
});

const mapDispatchToProps = {};

export const CheckoutMailOrder = connect(
  mapStateToProps,
  mapDispatchToProps
)(InnerCheckoutMailOrder);
