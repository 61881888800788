import { objectToArray } from '@apoly-42/apoly-utils';
import { compose, prop } from 'ramda';
import { selectAppStateRoot } from '../selectors';
import { selectPharmacyId } from '../shopPharmacy/reducer';

export const selectShoppingCartState = compose(
  prop('shoppingCart'),
  selectAppStateRoot
);

const getShoppingCartPrice = shoppingCart => {
  let totalPrice = 0;
  const products = { ...shoppingCart };
  if (shoppingCart) {
    Object.keys(products).map(key => {
      totalPrice += products[key].count * products[key].articlePrice;
      return totalPrice;
    });
  }
  return totalPrice.toFixed(2);
};

const getShoppingCartArticlesArray = shoppingCart =>
  shoppingCart &&
  Object.keys(shoppingCart).map(key => shoppingCart[key].articleId);

const countAllProducts = shoppingCart =>
  objectToArray(shoppingCart).reduce(
    (count, article) => count + article.count,
    0
  );

const ifCartExists = state => state.shoppingCart[selectPharmacyId(state)];

const getProductsForCart = state =>
  state.shoppingCart[selectPharmacyId(state)].products;

export const selectProducts = state =>
  ifCartExists(state) && getProductsForCart(state);
export const getShoppingCartLength = state =>
  ifCartExists(state) && countAllProducts(getProductsForCart(state));
export const getShoppingCartTotalPrice = state =>
  ifCartExists(state) && getShoppingCartPrice(getProductsForCart(state));
export const getShoppingCartArticlesIdArray = state =>
  ifCartExists(state) &&
  getShoppingCartArticlesArray(getProductsForCart(state));
export const selectLastAddedItem = state =>
  ifCartExists(state) &&
  state.shoppingCart[selectPharmacyId(state)].lastAddedItemToCart;
