import PropTypes from 'prop-types';
import React from 'react';

const getImage = imageUrl =>
  imageUrl && imageUrl !== '' ? imageUrl : 'default.png';

const SymptomImage = ({ imageUrl, altTxt, ...props }) => (
  <img
    {...props}
    src={require(`../../constants/assets/symptomImages/${getImage(imageUrl)}`)}
    alt={altTxt}
  />
);

SymptomImage.propTypes = {
  imageUrl: PropTypes.string,
  altTxt: PropTypes.string.isRequired,
};

SymptomImage.defaultProps = {
  imageUrl: 'default.png',
};

export default SymptomImage;
