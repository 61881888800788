import { toError } from '@apoly-42/apoly-utils';
import React from 'react';
import { RenderProp } from '../app-utilities/reactUtilTypes';

export interface LoadingState<T, E = Error> {
  error?: E;
  response?: T;
  isLoading: boolean;
  load: () => Promise<any>; // function(...args)	Trigger to load fn
}

export interface LoadsWrapperProps<T, E = Error> {
  fn: () => Promise<T>;
  delay?: number;
  loadOnMount?: boolean;
  crashComponentOnError?: boolean | ((error: E) => boolean);
  children?: RenderProp<LoadingState<T, E>>;
}

// https://github.com/jxom/react-loads#children-render-props
export class Load<T, E = Error> extends React.PureComponent<
  LoadsWrapperProps<T, E>,
  LoadingState<T, E>
> {
  constructor(props: LoadsWrapperProps<T, E>) {
    super(props);

    this.state = {
      error: undefined,
      load: this.callPromise,
      response: undefined,
      isLoading: false,
    };
  }

  handleError = (error: E) => {
    const { crashComponentOnError = true } = this.props;

    const shouldCrash =
      typeof crashComponentOnError === 'function'
        ? crashComponentOnError(error)
        : crashComponentOnError;

    if (shouldCrash) {
      this.setState({ error });
    }

    this.setState({ isLoading: false });

    throw error;
  };

  componentDidMount() {
    const { loadOnMount = true } = this.props;

    if (loadOnMount) {
      this.callPromise();
    }
  }

  callPromise = () => {
    this.setState({ isLoading: true });

    return this.props
      .fn()
      .then(response => this.setState({ response }))
      .then(() => this.setState({ isLoading: false }))
      .catch(this.handleError);
  };

  render() {
    if (this.state.error) {
      throw toError(this.state.error);
    }

    return this.props.children ? this.props.children(this.state) : null;
  }
}
