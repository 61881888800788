import { FormattedEuro } from '@apoly-42/apoly-components';
import { Divider, Hidden, Typography, withStyles } from '@material-ui/core';
import { css } from 'emotion';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { flexAlignFlexStart } from '../../../../constants/styles/commonClasses';
import {
  appGrid,
  gridArea,
  smUp,
} from '../../../../constants/styles/cssEmotionClasses';
import { singleProductSetPharmacyPath } from '../../../../routes/paths';
import {
  checkForDeliveryAvailability,
  getArticleWithArticleId,
} from '../../../../utilities/product/productUtilities';
import ProductImageSmall from '../../../imageComponents/productImages/ProductImageSmall';
import ProductDeleteAddResponsive from './ProductDeleteAddResponsive';

const styles = {
  link: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  productImg: {
    maxWidth: '100%',
    margin: 'auto',
  },
};

const shoppingCartItemGrid = css`
  ${appGrid};
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    'divider'
    'cartItem'
    'addRemoveButtons';
  ${smUp} {
    grid-template-areas:
      'divider'
      'cartItem';
  }
`;

const cartItemInfoGrid = css`
  ${appGrid};
  grid-area: cartItem;
  grid-template-columns: 33% 1fr;
  grid-template-areas: 'productImage productInfo';
`;

const productInfoGrid = css`
  ${appGrid};
  grid-area: productInfo;
  grid-template-columns: 1fr;
  height: auto;
  align-self: start;
  grid-template-areas:
    'name'
    'data'
    'price';
  ${smUp} {
    grid-template-areas:
      'name'
      'data'
      'price'
      'addRemoveBtns';
  }
`;

const InnerShoppingCartItemResponsive = ({
  classes,
  count,
  articles,
  articleId,
  basePath,
  deleteItem,
  increaseItemAmount,
  decreaseItemAmount,
}) => {
  const article = getArticleWithArticleId(articles, articleId);
  const { medicine } = article.package;
  const url = singleProductSetPharmacyPath(
    basePath,
    article.package.medicine.urlCode,
    article.package.urlCode
  );
  return (
    <div className={shoppingCartItemGrid}>
      <div className={gridArea('divider')}>
        <Divider />
      </div>
      <div className={cartItemInfoGrid}>
        <div className={gridArea('productImage')}>
          <div style={flexAlignFlexStart}>
            <ProductImageSmall
              imgUrl={article.package.imgUrl}
              name={medicine.name}
              style={{
                maxWidth: '100%',
                margin: 'auto',
                maxHeight: 160,
              }}
            />
          </div>
        </div>
        <div className={productInfoGrid}>
          <div className={gridArea('name')}>
            <Typography
              variant="title"
              component={Link}
              to={url}
              className={classes.link}
              dangerouslySetInnerHTML={{ __html: medicine.name }}
            />
          </div>
          <div className={gridArea('data')}>
            <Typography variant="caption">
              {article.package.amountText} | PZN: {article.package.pzn}
            </Typography>
          </div>
          <div className={gridArea('price')}>
            <Typography>
              <FormattedEuro value={Number(article.price)} />,{' '}
              {checkForDeliveryAvailability(article.availability)}
            </Typography>
          </div>
          <Hidden xsDown>
            <div className={gridArea('addRemoveBtns')}>
              <ProductDeleteAddResponsive
                deleteItem={deleteItem}
                count={count}
                increaseItemAmount={increaseItemAmount}
                decreaseItemAmount={decreaseItemAmount}
              />
            </div>
          </Hidden>
        </div>
      </div>
      <Hidden smUp>
        <div className={gridArea('addRemoveButtons')}>
          <ProductDeleteAddResponsive
            deleteItem={deleteItem}
            count={count}
            increaseItemAmount={increaseItemAmount}
            decreaseItemAmount={decreaseItemAmount}
          />
        </div>
      </Hidden>
    </div>
  );
};

const enhance = compose(withStyles(styles));

const ShoppingCartItemResponsive = enhance(InnerShoppingCartItemResponsive);

ShoppingCartItemResponsive.propTypes = {
  articles: PropTypes.array.isRequired,
  articleId: PropTypes.string.isRequired,
  basePath: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  deleteItem: PropTypes.func.isRequired,
  increaseItemAmount: PropTypes.func.isRequired,
  decreaseItemAmount: PropTypes.func.isRequired,
};

InnerShoppingCartItemResponsive.propTypes = {
  classes: PropTypes.object.isRequired,
  ...ShoppingCartItemResponsive.propTypes,
};

export default ShoppingCartItemResponsive;
