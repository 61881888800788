import { cx } from 'emotion';
import PropTypes from 'prop-types';
import React from 'react';
import striptags from 'striptags';
import { maxWidthHeight } from '../../../../../app-utilities/cssClasses';
import productImageDefault from './apolyPille_default.png';

// cdn8.apopixx.de/500/web_schraeg_png/03321472.png
export const getSmallImageUrl = imgUrl => imgUrl || productImageDefault;

const ProductImageSmall = ({ imgUrl, name, className, ...props }) => (
  <img
    {...props}
    className={cx(maxWidthHeight, className)}
    src={imgUrl || productImageDefault}
    alt={striptags(name)}
  />
);

ProductImageSmall.propTypes = {
  imgUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
};

ProductImageSmall.defaultProps = {
  imgUrl: null,
};

export default ProductImageSmall;
