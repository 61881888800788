interface URLPonyfillProps {
  hash: string;
  host: string;
  hostname: string;
  href: string;
  pathname: string;
  port: string;
  protocol: string;
  search: string;
  readonly origin: string;
}

export class URLPonyfill implements URLPonyfillProps {
  private anchor: HTMLAnchorElement;

  constructor(url: string, base: string | URLPonyfill) {
    if (!url) {
      throw new TypeError('Invalid argument');
    }

    const doc = document.implementation.createHTMLDocument('');
    if (base) {
      const baseElement = doc.createElement('base');

      baseElement.href =
        typeof base === 'string' ? base : `${base.protocol}//${base.host}`;
      doc.head.appendChild(baseElement);
    }

    const anchorElement = doc.createElement('a');
    anchorElement.href = url;
    doc.body.appendChild(anchorElement);

    if (anchorElement.protocol === ':' || !/:/.test(anchorElement.href)) {
      throw new TypeError('Invalid URL');
    }

    this.anchor = anchorElement;
  }

  get origin() {
    return this.anchor.origin;
  }

  get href() {
    return this.anchor.href;
  }
  set href(value: string) {
    this.anchor.href = value;
  }

  get protocol() {
    return this.anchor.protocol;
  }
  set protocol(value: string) {
    this.anchor.protocol = value;
  }

  get host() {
    return this.anchor.host;
  }
  set host(value: string) {
    this.anchor.host = value;
  }

  get hostname() {
    return this.anchor.hostname;
  }
  set hostname(value: string) {
    this.anchor.hostname = value;
  }

  get port() {
    return this.anchor.port;
  }
  set port(value: string) {
    this.anchor.port = value;
  }

  get pathname() {
    return this.anchor.pathname;
  }
  set pathname(value: string) {
    this.anchor.pathname = value;
  }

  get search() {
    return this.anchor.search;
  }
  set search(value: string) {
    this.anchor.search = value;
  }

  get hash() {
    return this.anchor.hash;
  }
  set hash(value: string) {
    this.anchor.pHash = value;
  }
}
