/* eslint-disable quote-props */

export const articleBaseInformation = (medicine, medicinePackage, article) => ({
  name: medicine.name,
  id: medicinePackage.pzn,
  price: Number(article.price),
  brand: medicine.producers.map(producer => producer.name).join(','),
  category: medicine.symptoms.map(symptom => symptom.name).join(','),
  variant: medicinePackage.amountText,
});

export const packageBaseInformation = (medicine, medicinePackage) => ({
  name: medicine.name,
  id: medicinePackage.pzn,
  price: Number(medicinePackage.rrp),
  brand: medicine.producers.map(producer => producer.name).join(','),
  category: medicine.symptoms.map(symptom => symptom.name).join(','),
  variant: medicinePackage.amountText,
});

export const articleBaseWithCount = (
  medicine,
  medicinePackage,
  article,
  products
) => ({
  ...articleBaseInformation(medicine, medicinePackage, article),
  quantity:
    products && products[article.articleId]
      ? Number(products[article.articleId].count)
      : undefined,
});
