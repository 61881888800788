import * as React from 'react';
import {
  FormattedMessage,
  InjectedIntlProps,
  injectIntl,
  MessageValue,
} from 'react-intl';
import { Omit } from 'utility-types';
import { AppHelmet, AppHelmetProps } from './AppHelmet';

export interface AppHelmetI18nProps {
  pageTitleMessage: FormattedMessage.MessageDescriptor;
  pageTitleValues?: { [key: string]: MessageValue };
  keywordsMessage?: FormattedMessage.MessageDescriptor;
  keywordsValues?: { [key: string]: MessageValue };
  descriptionMessage?: FormattedMessage.MessageDescriptor;
  descriptionValues?: { [key: string]: MessageValue };
}

export const AppHelmetI18n = injectIntl(
  class InnerAppHelmetI18n extends React.PureComponent<
    InjectedIntlProps &
      Omit<AppHelmetProps, 'pageTitle' | 'keywords' | 'description'> &
      AppHelmetI18nProps
  > {
    render() {
      const {
        pageTitleMessage,
        pageTitleValues,
        keywordsMessage,
        keywordsValues,
        descriptionMessage,
        descriptionValues,
        intl,
        ...rest
      } = this.props;

      return (
        <AppHelmet
          pageTitle={intl.formatMessage(pageTitleMessage, pageTitleValues)}
          keywords={
            keywordsMessage
              ? intl.formatMessage(keywordsMessage, keywordsValues)
              : undefined
          }
          description={
            descriptionMessage
              ? intl.formatMessage(descriptionMessage, descriptionValues)
              : undefined
          }
          {...rest}
        />
      );
    }
  }
);
