import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose, withHandlers, withState } from 'recompose';
import SearchAutosuggestContainer from '../../containers/searchAutosuggest/SearchAutosuggestContainer';
import {
  selectBasePath,
  selectPharmacyId,
} from '../../redux/shopPharmacy/reducer';
import { medicSearchSetPharmacyPath } from '../../routes/paths';
import SearchAutosuggestView from './SearchAutosuggestView';

const InnerSearchAutoSuggestComponent = props => (
  <React.Fragment>
    {props.redirectToMedicSearchPage && (
      <Redirect
        to={{
          pathname: medicSearchSetPharmacyPath(props.basePath),
          state: { searchKeyword: props.searchValue },
        }}
      />
    )}

    <SearchAutosuggestContainer
      setRedirectToMedicSearchPage={props.customSetRedirectToMedicSearchPage}
      searchForMedic={props.searchForMedic}
      pharmacyId={props.pharmacyId}
      onEnter={props.onEnter}
      basePath={props.basePath}
      searchValue={props.searchValue}
      fetchValue={props.fetchValue}
      selectedSuggestion={props.selectedSuggestion}
      setSearchValue={props.setSearchValue}
      setSuggestions={props.setSuggestions}
      closeSearchBar={props.closeSearchBar}
    />

    <SearchAutosuggestView
      suggestions={props.suggestions}
      searchValue={props.searchValue}
      handleSearchBarValueChange={props.handleSearchBarValueChange}
      setSearchForMedic={props.setSearchForMedic}
      setFetchValue={props.setFetchValue}
      showSearchButton={props.showSearchButton}
      handleSuggestionsClearRequested={props.handleSuggestionsClearRequested}
      setSelectedSuggestion={props.setSelectedSuggestion}
    />
  </React.Fragment>
);

const mapStateToProps = state => ({
  basePath: selectBasePath(state),
  pharmacyId: selectPharmacyId(state),
});

const enhance = compose(
  connect(mapStateToProps),
  withState('redirectToMedicSearchPage', 'setRedirectToMedicSearchPage', false),
  withState('selectedSuggestion', 'setSelectedSuggestion', null),
  withState('searchValue', 'setSearchValue', ''),
  withState('fetchValue', 'setFetchValue', ''),
  withState('suggestions', 'setSuggestions', []),
  withState('searchForMedic', 'setSearchForMedic', ''),
  withHandlers({
    handleSearchBarValueChange: props => (event, { newValue }) => [
      props.setRedirectToMedicSearchPage(false),
      props.setSearchValue(newValue),
    ],
    handleSuggestionsClearRequested: props => () => props.setSuggestions([]),
    // hack weil furchtbar:
    customSetRedirectToMedicSearchPage: props => val => [
      props.onWillRedirect && val && props.onWillRedirect(val),
      props.setRedirectToMedicSearchPage(val),
    ],
  })
);

const SearchAutoSuggestComponent = enhance(InnerSearchAutoSuggestComponent);

SearchAutoSuggestComponent.propTypes = {
  showSearchButton: PropTypes.bool,
  closeSearchBar: PropTypes.func,
  onWillRedirect: PropTypes.func,
};

SearchAutoSuggestComponent.defaultProps = {
  showSearchButton: false,
  closeSearchBar: null,
  onWillRedirect: null,
};

InnerSearchAutoSuggestComponent.propTypes = {
  basePath: PropTypes.string.isRequired,
  fetchValue: PropTypes.string.isRequired,
  pharmacyId: PropTypes.number.isRequired,
  suggestions: PropTypes.array.isRequired,
  selectedSuggestion: PropTypes.object,
  redirectToMedicSearchPage: PropTypes.bool.isRequired,
  setSearchValue: PropTypes.func.isRequired,
  onEnter: PropTypes.func,
  setFetchValue: PropTypes.func.isRequired,
  setSuggestions: PropTypes.func.isRequired,
  searchForMedic: PropTypes.string.isRequired,
  setSearchForMedic: PropTypes.func.isRequired,
  setSelectedSuggestion: PropTypes.func.isRequired,
  handleSearchBarValueChange: PropTypes.func.isRequired,
  handleSuggestionsClearRequested: PropTypes.func.isRequired,
  setRedirectToMedicSearchPage: PropTypes.func.isRequired,
  ...SearchAutoSuggestComponent.propTypes,
};

InnerSearchAutoSuggestComponent.defaultProps = {
  onEnter: null,
  selectedSuggestion: null,
  ...SearchAutoSuggestComponent.defaultProps,
};

export default SearchAutoSuggestComponent;
