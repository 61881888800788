import { createDynamicContext } from 'react-dynamic-context';
import { EnhancedPharmacy, Pharmacy } from './apolyApi';
import { channelToRoute, PharmacyChannel } from './routes-helper';
import { PharmacyBasePathProps } from './routes';

export interface PharmacyContextValues {
  pharmacy: Pharmacy | undefined;
  pharmacyIsLoading: boolean;
  enhancedPharmacy: EnhancedPharmacy | undefined;
  pharmacyChannel: PharmacyChannel | undefined;
}

const pharmacyCtx = createDynamicContext<PharmacyContextValues>({
  pharmacy: undefined,
  pharmacyIsLoading: false,
  enhancedPharmacy: undefined,
  pharmacyChannel: undefined,
});

export const PharmacyConsumer = pharmacyCtx.Consumer;
export const PharmacyValuesConsumer = pharmacyCtx.ValuesConsumer;

export const pharmacyCtxToRouteProps = ({
  pharmacy,
  pharmacyChannel,
}: PharmacyContextValues): PharmacyBasePathProps | undefined =>
  pharmacy && pharmacyChannel
    ? {
        pharmacyChannel: channelToRoute(pharmacyChannel),
        pharmacyUrlCode: pharmacy.urlCode,
      }
    : undefined;
