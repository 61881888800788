import { parse } from 'qs';
import { RfcUrl } from '../url/index';

export interface QsLanguage {
  lang?: string;
}

export function parseQueryString<T extends {}>(query: string) {
  return (parse(query) || {}) as T;
}

export function newQs(url: RfcUrl, newQueryObject: {}) {
  return Object.assign({}, parse(url.query), newQueryObject);
}

export const toQsLanguage = (lang: string): QsLanguage => ({ lang });
