import { Button, Divider, FormHelperText, Hidden } from '@material-ui/core';
import { FormikFields } from 'formik-fields';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { CtaButton } from '../../../app-util-components/CtaButton';
import { noEmptyFormField } from '../../../app-util-components/form-utils/formikFieldValidators';
import { MuiFormikCheckboxField } from '../../../app-util-components/form-utils/MuiFormikCheckboxField';
import { I18nMessage } from '../../../app-util-components/I18nMessage';
import { appMessages } from '../../../app-utilities/appMessages';
import { appGrid } from '../../../app-utilities/cssClasses';
import ClosurePrice from '../../../deprecated/deprecated-apoly-app/components/pages/checkout/closure/ClosurePrice';
import ClosureProducts from '../../../deprecated/deprecated-apoly-app/components/pages/checkout/closure/productTable/ClosureProducts';
import ClosureProductsTableHead from '../../../deprecated/deprecated-apoly-app/components/pages/checkout/closure/productTable/ClosureProductsTableHead';
import { commonMessages } from '../../../deprecated/deprecated-apoly-app/constants/messages/commonMessages';
import { BRAND_NAME } from '../../../deprecated/deprecated-apoly-app/environment';
import { agbPath } from '../../../deprecated/deprecated-apoly-app/routes/paths';
import { Pharmacy } from '../../apolyApi';
import { pharmacyCtxToPath } from '../../layout/PharmacyBaseLink';
import {
  PharmacyContextValues,
  PharmacyValuesConsumer,
} from '../../PharmacyContext';
import { dataSecurityPath } from '../../routes';
import { toDeliveryType } from './CheckoutSubmitOrder';
import { DeliveryType } from './OtcOrderTypes';

interface CheckoutClosureProps {
  deliveryType: DeliveryType;
  onSubmit: (values: ClosureValues) => void;
  onBackClick?: () => void;
  backComponent?: React.Component | null;
  deliveryAddressCountry?: string;
}
const messages = defineMessages({
  orderWithCosts: {
    id: 'orderWithCosts',
    defaultMessage: 'order with costs',
  },
  brandAgb: {
    id: 'brandAgb',
    defaultMessage: '{brand}-AGB',
  },
  agreeToAgbAnDisclaimerWithLinks: {
    id: 'agreeToAgbAnDisclaimerWithLinks',
    defaultMessage:
      'Yes, i agree to the {agbLink} and the {cancellationPolicyLink}.',
  },
  agreeToNewsletter: {
    id: 'agreeToNewsletter',
    defaultMessage:
      'I want to get information about new health care topics. (optional)',
  },
  cancellationPolicy: {
    id: 'cancellationPolicy',
    defaultMessage: 'cancellation policy',
  },
  newsletterHelperText: {
    id: 'newsletterHelperText',
    defaultMessage:
      'Mit Aktivieren der Anmeldeoption durch Häkchensetzung stimmen Sie der Verwendung von Cookies und der Erhebung von Gesundheitsdaten zu. Einzelheiten dazu sowie Ihrem Widerrufsrecht entnehmen Sie den {dataProtectionLink}',
  },
});

export interface ClosureValues {
  newsletterOptIn: boolean;
  agbOptIn: boolean;
}

export class CheckoutClosure extends React.Component<CheckoutClosureProps> {
  renderAgbLabel = (pharmacy?: Pharmacy) => (
    <I18nMessage
      message={messages.agreeToAgbAnDisclaimerWithLinks}
      values={{
        agbLink: (
          <a href={pharmacy ? pharmacy.agbUrl : ''} target="_blank">
            <I18nMessage message={commonMessages.agb} />
          </a>
        ),
        cancellationPolicyLink: (
          <a href={pharmacy ? pharmacy.wrbUrl : ''} target="_blank">
            <I18nMessage message={messages.cancellationPolicy} />
          </a>
        ),
      }}
    />
  );

  renderNewsletterHelperText = (pharmacyCtx: PharmacyContextValues) => (
    <I18nMessage
      message={messages.newsletterHelperText}
      values={{
        dataProtectionLink: (
          <a
            href={pharmacyCtxToPath(dataSecurityPath, pharmacyCtx)}
            target="_blank"
          >
            <I18nMessage message={commonMessages.privacyPolicy} />
          </a>
        ),
      }}
    />
  );

  renderOrderLegalText = (pharmacyCtx: PharmacyContextValues) => (
    <I18nMessage
      message={commonMessages.agreeToUserDataSecurityPolicy}
      values={{
        websiteAgbLink: (
          <a href={agbPath()} target="_blank">
            <I18nMessage
              message={messages.brandAgb}
              values={{ brand: BRAND_NAME }}
            />
          </a>
        ),
        privacyPolicyLink: (
          <a
            href={pharmacyCtxToPath(dataSecurityPath, pharmacyCtx)}
            target="_blank"
          >
            <I18nMessage message={commonMessages.privacyPolicy} />
          </a>
        ),
      }}
    />
  );

  render() {
    const {
      onSubmit,
      backComponent,
      onBackClick,
      deliveryType,
      deliveryAddressCountry,
    } = this.props;

    return (
      <PharmacyValuesConsumer>
        {pharmacyCtx => (
          <FormikFields<ClosureValues>
            fields={{
              agbOptIn: { initialValue: false, validate: noEmptyFormField },
              newsletterOptIn: { initialValue: false },
            }}
            onSubmit={onSubmit}
          >
            {(fields, formikBag) => (
              <form onSubmit={formikBag.handleSubmit} className={appGrid}>
                <Hidden smDown={true}>
                  <ClosureProductsTableHead />
                </Hidden>
                <ClosureProducts />
                <Divider />
                <ClosurePrice
                  deliveryAddressCountry={deliveryAddressCountry}
                  transmission={toDeliveryType(deliveryType)}
                />
                <div>
                  <MuiFormikCheckboxField
                    field={fields.newsletterOptIn}
                    label={<I18nMessage message={messages.agreeToNewsletter} />}
                  />
                  <FormHelperText style={{ marginTop: -8 }}>
                    {this.renderNewsletterHelperText(pharmacyCtx)}
                  </FormHelperText>
                </div>
                <MuiFormikCheckboxField
                  field={fields.agbOptIn}
                  label={this.renderAgbLabel(pharmacyCtx.pharmacy)}
                />
                <FormHelperText style={{ margin: 0 }}>
                  {this.renderOrderLegalText(pharmacyCtx)}
                </FormHelperText>
                <div style={{ alignSelf: 'flex-end', display: 'flex' }}>
                  {backComponent ? (
                    <Button component={backComponent}>
                      <I18nMessage message={appMessages.back} />
                    </Button>
                  ) : (
                    <Button onClick={onBackClick}>
                      <I18nMessage message={appMessages.back} />
                    </Button>
                  )}
                  <CtaButton type="submit" style={{ marginLeft: 'auto' }}>
                    <I18nMessage message={messages.orderWithCosts} />
                  </CtaButton>
                </div>
              </form>
            )}
          </FormikFields>
        )}
      </PharmacyValuesConsumer>
    );
  }
}
