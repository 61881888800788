// aktuell ausgelagert wegen Struktur mit js-files die zyklisch darauf zugreifen könnten

import { equals } from 'ramda';
import { cond, throwF } from '../app-utilities/fn-utils';

export const localDeliveryRoute = 'lokal-online';
const mailOrderRoute = 'regional';
const pssRoute = 'abholautomat';
export const customerBoardRoute = 'lokal';

export enum PharmacyChannel {
  local = 1,
  mailOrder, // versandhandel
  pss, // automaten
  customerBoard,
}

export const isLocalChannel = (channel: PharmacyChannel) =>
  channel === PharmacyChannel.local;

export const isCustomerBoardChannel = (channel: PharmacyChannel) =>
  channel === PharmacyChannel.customerBoard;

export const channelToRoute = cond<PharmacyChannel, string>(
  [
    [equals(PharmacyChannel.local), () => localDeliveryRoute],
    [equals(PharmacyChannel.mailOrder), () => mailOrderRoute],
    [equals(PharmacyChannel.pss), () => pssRoute],
    [equals(PharmacyChannel.customerBoard), () => customerBoardRoute],
  ],
  throwF(val => `unknown channel ${val}`)
);
export const routeToChannel = cond<string, PharmacyChannel>(
  [
    [equals(localDeliveryRoute), () => PharmacyChannel.local],
    [equals(mailOrderRoute), () => PharmacyChannel.mailOrder],
    [equals(pssRoute), () => PharmacyChannel.pss],
    [equals(customerBoardRoute), () => PharmacyChannel.customerBoard],
  ],
  throwF(val => `unknown channel ${val}`)
);
