import { ShowComponentWhileLoading } from '@apoly-42/material-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose, withState } from 'recompose';
import LoadArticlesContainer from '../../../../containers/product/LoadArticlesContainer';
import {
  getShoppingCartArticlesIdArray,
  getShoppingCartLength,
} from '../../../../redux/shoppingCart/selectors';
import NoItemsInShoppingCart from '../../../NoItemsInShoppingCart';
import ShoppingCartTableView from './ShoppingCartTableView';

const InnerShoppingCartTable = props => (
  <React.Fragment>
    {props.shoppingCartLength > 0 && (
      <LoadArticlesContainer
        pharmacyId={props.pharmacyId}
        articleIdArray={props.articleIdArray}
        setArticles={props.setArticles}
        setIsLoading={props.setIsLoading}
        setError={props.setError}
      />
    )}

    {props.isLoading && (
      <ShowComponentWhileLoading isLoading={props.isLoading} />
    )}

    {!props.isLoading &&
      props.articles && <ShoppingCartTableView articles={props.articles} />}

    {!props.isLoading && !props.articles && <NoItemsInShoppingCart />}
  </React.Fragment>
);

const mapStateToProps = state => ({
  shoppingCartLength: getShoppingCartLength(state),
  articleIdArray: getShoppingCartArticlesIdArray(state),
});

const enhance = compose(
  withState('articles', 'setArticles', null),
  withState('isLoading', 'setIsLoading', false),
  withState('error', 'setError', null),
  connect(mapStateToProps)
);

const ShoppingCartTable = enhance(InnerShoppingCartTable);

ShoppingCartTable.propTypes = {
  pharmacyId: PropTypes.number.isRequired,
};

InnerShoppingCartTable.propTypes = {
  error: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  articleIdArray: PropTypes.array,
  articles: PropTypes.array,
  shoppingCartLength: PropTypes.number,
  setArticles: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  ...ShoppingCartTable.propTypes,
};

InnerShoppingCartTable.defaultProps = {
  shoppingCartLength: null,
  articleIdArray: null,
  error: null,
  articles: null,
};

export default ShoppingCartTable;
