import { log, pLocalStorage } from '@apoly-42/apoly-utils';

const getShoppingCartFromJson = shoppingCartJson => {
  if (!shoppingCartJson) {
    return null;
  }

  try {
    return JSON.parse(shoppingCartJson);
    /* // TODO irgendwann mal anpassen
    // old code
    const {
      shoppingCartPrice, products, shoppingCartLength, articleIdArray, lastAddedItemToCart,
    } = JSON.parse(shoppingCartJson);
     return {
      shoppingCartPrice: parseFloatOrDefault(shoppingCartPrice, { defaultValue: 0 }),
      products: isObject(products) ? products : {},
      shoppingCartLength: parseIntOrDefault(shoppingCartLength, { defaultValue: 0 }),
      articleIdArray: Array.isArray(articleIdArray) ? articleIdArray : [],
      lastAddedItemToCart: isObject(lastAddedItemToCart) ? lastAddedItemToCart : null,
    };
    */
  } catch (ex) {
    log(ex);
    return null;
  }
};

export const getShoppingCartFromLocalStorage = () =>
  getShoppingCartFromJson(pLocalStorage.getItem('localDB.shoppingCart'));

export const setShoppingCartToLocalStorage = shoppingCart =>
  pLocalStorage.setItem('localDB.shoppingCart', JSON.stringify(shoppingCart));
