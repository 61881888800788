import { createAction, createReducer } from '@apoly-42/apoly-utils';
import { compose, prop } from 'ramda';
import { selectAppStateRoot } from '../selectors';

export const selectShopPharmacy = compose(
  prop('shopPharmacy'),
  selectAppStateRoot
);

export const setPharmacy = createAction('setPharmacy');
export const setBasePath = createAction('setBasePath');
export const setIsPostal = createAction('setIsPostal');
export const setEnhancedPharmacy = createAction('setEnhancedPharmacy');

export const selectPharmacy = compose(
  prop('pharmacy'),
  selectShopPharmacy
);
export const selectPharmacyId = compose(
  prop('pharmacyId'),
  selectShopPharmacy
);
export const selectBasePath = compose(
  prop('basePath'),
  selectShopPharmacy
);
export const selectIsPostal = compose(
  prop('isPostal'),
  selectShopPharmacy
);
export const selectEnhancedPharmacy = compose(
  prop('enhancedPharmacy'),
  selectShopPharmacy
);

const reducer = createReducer(
  {
    pharmacy: null,
    pharmacyId: null,
    basePath: '',
    isPostal: false,
    enhancedPharmacy: null,
  },
  {
    [setPharmacy]: (state, { payload }) => ({
      ...state,
      pharmacy: payload,
      pharmacyId: payload ? payload.pharmacyId : null,
    }),
    [setBasePath]: (state, { payload }) => ({ ...state, basePath: payload }),
    [setIsPostal]: (state, { payload }) => ({ ...state, isPostal: payload }),
    [setEnhancedPharmacy]: (state, { payload }) => ({
      ...state,
      enhancedPharmacy: payload,
    }),
  }
);

export default reducer;
