import { log, logError } from '@apoly-42/apoly-utils';
import { compose, tap } from 'ramda';
import { getRaven } from './sentryWrapper';

const getErrorMessage = error => error && error.message;

export const logErrorGracefully = (error, { extra } = {}) => {
  logError(
    `an unknown error occured (${getErrorMessage(error) ||
      'no error-message'}) and will logged gracefully. information about the error below`
  );
  log(error);
  if (extra) {
    log(extra);
  }

  if (false) {
    return Promise.resolve();
  } else if (error && error.name === 'NetworkError') {
    log('this error is an network-error, it will not logged gracefully');
    return Promise.resolve();
  }

  return getRaven().then(Raven => {
    console.log('via raven');
    Raven.captureException(error, { extra });
    console.log('via raven - done');
  });
};

const rethrowError = error => {
  throw error;
};

const failGracefully = compose(
  rethrowError,
  tap(logErrorGracefully)
);

export default failGracefully;
