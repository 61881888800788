import { Button, ButtonBase, Card } from '@material-ui/core';
import { css, cx } from 'emotion';
import { FormikFields } from 'formik-fields';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { CtaButton } from '../../../app-util-components/CtaButton';
import { FluidTypography } from '../../../app-util-components/FluidTypography';
import { noEmptyFormField } from '../../../app-util-components/form-utils/formikFieldValidators';
import { I18nMessage } from '../../../app-util-components/I18nMessage';
import { appMessages, formMessages } from '../../../app-utilities/appMessages';
import { appGrid, mdUp, smUp } from '../../../app-utilities/cssClasses';
import PaymentMethodImage from '../../../deprecated/deprecated-apoly-app/components/PaymentMethodImage';
import { PaymentMethod } from '../../apolyApi';

interface Props {
  onSubmit: (paymentType: PaymentMethod) => void;
  onBackClick: () => void;
  initialPaymentMethod?: PaymentMethod;
  paymentMethods: PaymentMethod[];
  disableDelivery: boolean;
}

interface FormValues {
  paymentMethod: PaymentMethod | undefined;
}

const grid = css`
  ${appGrid};
  grid-template-columns: repeat(2, 1fr);
  ${smUp} {
    grid-template-columns: repeat(3, 1fr);
  }
  ${mdUp} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const base = css`
  height: 104px;
  width: 100%;
  padding: 24px;
`;

const inactive = css`
  filter: grayscale(100%);
  &:hover {
    filter: grayscale(0%);
  }
`;

const active = css`
  border: 1px solid rgba(0, 0, 0, 0.87);
`;

const messages = defineMessages({
  noEPaymentAvailable: {
    id: 'noEPaymentAvailable',
    defaultMessage:
      'Ihre Apotheke hat momentan leider noch keine Versandhandelserlaubnis. Die Online-Bezahlung ist deshalb leider nur für Kosmetik-Produkte und Nahrungsergänzungsmittel verfügbar.',
  },
});

export class CheckoutPayment extends React.Component<Props> {
  render() {
    const {
      onBackClick,
      onSubmit,
      initialPaymentMethod,
      paymentMethods,
    } = this.props;

    return (
      <FormikFields<FormValues>
        fields={{
          paymentMethod: {
            initialValue: initialPaymentMethod,
            validate: noEmptyFormField,
          },
        }}
        onSubmit={values =>
          values.paymentMethod && onSubmit(values.paymentMethod)
        }
      >
        {(fields, formikBag) => (
          <form onSubmit={formikBag.handleSubmit} className={appGrid}>
            <div className={grid}>
              {paymentMethods.map(paymentMethod => {
                const { value, setValue } = fields.paymentMethod;
                const isActive = value === paymentMethod;

                return (
                  <ButtonBase
                    key={paymentMethod}
                    onClick={() => setValue(paymentMethod)}
                    disabled={
                      this.props.disableDelivery && paymentMethod !== 'cash'
                    }
                    focusRipple={true}
                    component={Card}
                    style={{
                      backgroundColor:
                        this.props.disableDelivery && paymentMethod !== 'cash'
                          ? '#d1d1d1'
                          : 'transparent',
                    }}
                    className={cx(base, isActive ? active : inactive)}
                  >
                    <PaymentMethodImage
                      availablePaymentMethod={paymentMethod}
                      textAside={false}
                    />
                  </ButtonBase>
                );
              })}
            </div>
            {fields.paymentMethod.isTouched &&
              fields.paymentMethod.error && (
                <FluidTypography
                  color="error"
                  style={{ justifySelf: 'flex-end' }}
                >
                  <I18nMessage message={formMessages.noEmptyField} />
                </FluidTypography>
              )}
            {this.props.disableDelivery && (
              <FluidTypography variant="caption" type={'body2'}>
                <I18nMessage message={messages.noEPaymentAvailable} />
              </FluidTypography>
            )}
            <div style={{ alignSelf: 'flex-end', display: 'flex' }}>
              <Button onClick={onBackClick}>
                <I18nMessage message={appMessages.back} />
              </Button>
              <CtaButton type="submit" style={{ marginLeft: 'auto' }}>
                <I18nMessage message={appMessages.continue} />
              </CtaButton>
            </div>
          </form>
        )}
      </FormikFields>
    );
  }
}
