import { WithIcon } from '@apoly-42/apoly-components';
import {
  faChevronDoubleRight,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import {
  Button,
  Collapse,
  FormControlLabel,
  Radio,
  Switch,
} from '@material-ui/core';
import { FormikFields } from 'formik-fields';
import * as React from 'react';
import { defineMessages } from 'react-intl';
import { AddressDialog } from '../../../app-util-components/address-utils/AddressDialog';
import { FluidTypography } from '../../../app-util-components/FluidTypography';
import { noEmptyFormField } from '../../../app-util-components/form-utils/formikFieldValidators';
import { MuiFormikTextFieldI18n } from '../../../app-util-components/form-utils/MuiFormikTextFieldI18n';
import { I18nMessage } from '../../../app-util-components/I18nMessage';
import { formMessages } from '../../../app-utilities/appMessages';
import { appGrid } from '../../../app-utilities/cssClasses';
import { addressToString } from '../../../app-utilities/toStringUtils';
import { commonMessages } from '../../../deprecated/deprecated-apoly-app/constants/messages/commonMessages';
import { Address } from './OtcOrderTypes';

interface CheckoutBillingDataProps {
  onSubmit: (
    data: {
      billingAddress?: Address | undefined;
      email: string;
      telephone?: string;
    }
  ) => void;
  showTelephone?: boolean | undefined;
  email?: string;
  telephone?: string;
  billingAddress?: Address;
  addresses: Address[];
  onSubmitNewAddress: (address: Address) => void;
}

type BillingDataForm = {
  email: string;
  telephone: string;
  billingAddress: Address | undefined;
};

const messages = defineMessages({
  chooseYourBillingAddress: {
    id: 'chooseYourBillingAddress',
    defaultMessage:
      'Wählen Sie Ihre Rechnungsadresse aus oder fügen Sie eine neue hinzu.',
  },
  addBillingAddress: {
    id: 'addBillingAddress',
    defaultMessage: 'Fügen Sie bitte eine neue Rechnungsadresse hinzu.',
  },
});

export class CheckoutBillingData extends React.Component<
  CheckoutBillingDataProps,
  { withBillingAddress: boolean; addresses: Address[]; showDialog: boolean }
> {
  state = {
    withBillingAddress: !!this.props.billingAddress,
    addresses: this.props.addresses,
    showDialog: false,
  };

  newAddress = (address: Address) => {
    this.setState({ addresses: [...this.state.addresses, address] });
  };

  toggleWithBillingAddress = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.setState({ withBillingAddress: e.target.checked });

  toggleShowDialog = () =>
    this.setState({ showDialog: !this.state.showDialog });

  isValidAddress = (address: undefined | Address) =>
    address && this.state.addresses.indexOf(address) >= 0;

  isValidTelephone = (telephone: undefined | string) =>
    telephone && telephone !== '';

  validate = (values: BillingDataForm) => ({
    billingAddress:
      this.state.withBillingAddress &&
      !this.isValidAddress(values.billingAddress) ? (
        <I18nMessage message={formMessages.noEmptyField} />
      ) : (
        undefined
      ),
    telephone:
      this.props.showTelephone && !this.isValidTelephone(values.telephone) ? (
        <I18nMessage message={formMessages.noEmptyField} />
      ) : (
        undefined
      ),
  });

  render() {
    const { addresses, withBillingAddress, showDialog } = this.state;

    const {
      showTelephone,
      billingAddress,
      telephone,
      email,
      onSubmitNewAddress,
    } = this.props;

    return (
      <FormikFields<BillingDataForm>
        validate={this.validate}
        fields={{
          email: { initialValue: email || '', validate: noEmptyFormField },
          telephone: { initialValue: telephone || '' },
          billingAddress: { initialValue: billingAddress },
        }}
        onSubmit={values =>
          this.props.onSubmit({
            telephone: values.telephone,
            email: values.email,
            billingAddress: withBillingAddress
              ? values.billingAddress
              : undefined,
          })
        }
      >
        {(fields, formikBag) => (
          <React.Fragment>
            <form className={appGrid} onSubmit={formikBag.handleSubmit}>
              <MuiFormikTextFieldI18n
                labelMessage={formMessages.email}
                type="email"
                autoComplete="email"
                required={true}
                placeholderMessage={formMessages.emailPlaceholder}
                field={fields.email}
              />

              {showTelephone && (
                <MuiFormikTextFieldI18n
                  labelMessage={formMessages.telephone}
                  type="tel"
                  autoComplete="tel"
                  required={true}
                  placeholderMessage={formMessages.telephone}
                  field={fields.telephone}
                />
              )}

              <FormControlLabel
                control={
                  <Switch
                    checked={withBillingAddress}
                    onChange={this.toggleWithBillingAddress}
                  />
                }
                label={<I18nMessage message={commonMessages.billingAddress} />}
              />

              {/* padding to fix cut radio button */}
              <Collapse in={withBillingAddress} style={{ paddingLeft: 4 }}>
                <div className={appGrid}>
                  <FluidTypography>
                    <I18nMessage
                      message={
                        addresses.length > 0
                          ? messages.chooseYourBillingAddress
                          : messages.addBillingAddress
                      }
                    />
                  </FluidTypography>

                  {addresses.map((address, i) => (
                    <FormControlLabel
                      key={i}
                      name={fields.billingAddress.name}
                      checked={fields.billingAddress.value === address}
                      onChange={(_, checked) =>
                        checked && fields.billingAddress.setValue(address)
                      }
                      control={
                        <Radio
                          color={
                            fields.billingAddress.error
                              ? 'secondary'
                              : 'primary'
                          }
                        />
                      }
                      label={addressToString(address)}
                    />
                  ))}

                  {fields.billingAddress.error && (
                    <FluidTypography color="error">
                      {fields.billingAddress.error}
                    </FluidTypography>
                  )}

                  <Button
                    style={{ justifySelf: 'flex-start' }}
                    variant={addresses.length > 0 ? 'outlined' : 'raised'}
                    color={addresses.length > 0 ? undefined : 'secondary'}
                    onClick={this.toggleShowDialog}
                  >
                    <WithIcon icon={faPlus} iconLeft={true}>
                      <I18nMessage message={commonMessages.addAddress} />
                    </WithIcon>
                  </Button>
                </div>
              </Collapse>
              <Button
                type="submit"
                style={{ justifySelf: 'flex-end' }}
                variant="raised"
                color="secondary"
              >
                <WithIcon icon={faChevronDoubleRight}>
                  <I18nMessage message={commonMessages.continue} />
                </WithIcon>
              </Button>
            </form>
            <AddressDialog
              isOpen={showDialog}
              onSubmit={address => {
                onSubmitNewAddress(address);
                fields.billingAddress.setValue(address);
                this.setState({
                  addresses: [...addresses, address],
                  showDialog: false,
                });
              }}
              onCloseClick={this.toggleShowDialog}
            />
          </React.Fragment>
        )}
      </FormikFields>
    );
  }
}
