import { Paper } from '@material-ui/core';
import React from 'react';

const renderSuggestionsContainer = options => {
  const { containerProps, children } = options;
  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  );
};

export default renderSuggestionsContainer;
