import { tryParseJson } from '@apoly-42/apoly-utils';
import { Address } from '../app/apolyApi';
import { checkAddress } from '../app/pages/checkout/geocoderResultToAddress';

const getAddressArrayFromLocalStorage = (): Address[] => {
  const res = tryParseJson(window.localStorage.getItem('localDB.addressArray'));

  return Array.isArray(res) ? (res.filter(checkAddress) as Address[]) : [];
};

export const addAddressToLocalStorage = (address: Address) =>
  setAddressesToLocalStorage([...addressesFromLocalStorage, address]);

export const setAddressesToLocalStorage = (addresses: Address[]) => {
  addressesFromLocalStorage = addresses;

  window.localStorage.setItem(
    'localDB.addressArray',
    JSON.stringify(addresses)
  );
};

export let addressesFromLocalStorage = getAddressArrayFromLocalStorage();
