import { ButtonWithIcon } from '@apoly-42/material-ui';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
  Button,
  Divider,
  Hidden,
  SwipeableDrawer,
  Theme,
  withTheme,
} from '@material-ui/core';
import { css } from 'emotion';
import React from 'react';
import { defineMessages } from 'react-intl';
import { PharmacyThemeContext } from '../../app-theme/pharmacy-theme/pharmacyTheme';
import { I18nMessage } from '../../app-util-components/I18nMessage';
import {
  appGrid,
  contentContainer,
  flexAlignCenter,
  lgUp,
  mdUp,
  smUp,
} from '../../app-utilities/cssClasses';
import { PharmacyValuesConsumer } from '../PharmacyContext';
import { AppMuiTheme } from './AppMuiTheme';
import { AboutCompany } from './footer/AboutCompany';
import { ChangeLocale } from './footer/ChangeLocale';
import { FootNotes } from './footer/FootNotes';
import { LawAgreements } from './footer/LawAgreements';
import { MoreInformation } from './footer/MoreInformation';
import { PossiblePaymentMethods } from './footer/PossiblePaymentMethods';
import { SafeWithBrand } from './footer/SafeWithBrand';
import { SocialMedia } from './footer/SocialMedia';

const footer = (theme: Theme) => css`
  background-color: ${theme.palette.grey[900]};
`;

const messages = defineMessages({
  followUs: {
    id: 'followUs',
    defaultMessage: 'Follow us now!',
  },
  hideFooter: {
    id: 'hide footer',
    defaultMessage: 'hide footer',
  },
  showFooter: {
    id: 'show footer',
    defaultMessage: 'Impressum, Datenschutz & mehr',
  },
});

const footerElement = css`
  padding: 16px 8px;
  ${mdUp} {
    padding: 24px 8px;
  }
`;

const footerGrid = css`
  ${appGrid};
  ${contentContainer};
  grid-template-columns: 1fr;
  ${smUp} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${lgUp} {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const dividerInGrid = css`
  grid-column: span 1;
  ${smUp} {
    grid-column: span 2;
  }
  ${mdUp} {
    grid-column: span 4;
  }
`;

const FooterDivider = () => (
  <div className={dividerInGrid}>
    <Divider />
  </div>
);

interface InnerFooterPageProps {
  theme: Theme;
}

interface State {
  displayFooter: boolean;
}

class InnerFooter extends React.Component<InnerFooterPageProps, State> {
  state: State = {
    displayFooter: false,
  };

  toggleFooter = () =>
    this.setState({ displayFooter: !this.state.displayFooter });

  render() {
    const { theme } = this.props;

    return (
      <React.Fragment>
        <div style={{ display: 'flex' }}>
          <Button
            onClick={this.toggleFooter}
            size="small"
            style={{
              textTransform: 'none',
              marginLeft: 'auto',
              marginTop: 16,
              marginRight: 8,
              marginBottom: 8,
            }}
          >
            <I18nMessage message={messages.showFooter} />
          </Button>
        </div>
        <SwipeableDrawer
          anchor="bottom"
          open={this.state.displayFooter}
          onClose={this.toggleFooter}
          onOpen={this.toggleFooter}
        >
          <div>
            <PharmacyValuesConsumer>
              {pharmacyCtx => (
                <PharmacyThemeContext>
                  {pharmacyTheme => (
                    <AppMuiTheme type="dark" palette={pharmacyTheme.palette}>
                      <div className={footer(theme)}>
                        <ButtonWithIcon
                          icon={faTimes}
                          iconRight={true}
                          variant="outlined"
                          onClick={this.toggleFooter}
                          style={{
                            zIndex: 1400,
                            position: 'absolute',
                            bottom: 8,
                            right: 8,
                          }}
                        >
                          <I18nMessage message={messages.hideFooter} />
                        </ButtonWithIcon>
                        <div className={footerGrid}>
                          <div className={footerElement}>
                            <ChangeLocale />
                          </div>
                          <Hidden smUp={true}>
                            <FooterDivider />
                          </Hidden>
                          <div className={footerElement}>
                            <MoreInformation />
                          </div>
                          <Hidden lgUp={true}>
                            <FooterDivider />
                          </Hidden>
                          <div className={footerElement}>
                            <PossiblePaymentMethods />
                          </div>
                          <Hidden only="sm">
                            <FooterDivider />
                          </Hidden>
                          <div className={footerElement}>
                            <AboutCompany />
                          </div>
                          <Hidden lgUp={true}>
                            <FooterDivider />
                          </Hidden>
                          <div className={footerElement}>
                            <SafeWithBrand />
                          </div>
                          <Hidden smUp={true}>
                            <FooterDivider />
                          </Hidden>
                          <div className={footerElement}>
                            <SocialMedia
                              title={
                                <I18nMessage message={messages.followUs} />
                              }
                            />
                          </div>
                        </div>
                        <div className={flexAlignCenter}>
                          <LawAgreements {...pharmacyCtx} />
                        </div>
                        <div
                          className={contentContainer}
                          style={{ paddingBottom: 16 }}
                        >
                          <FootNotes />
                        </div>
                      </div>
                    </AppMuiTheme>
                  )}
                </PharmacyThemeContext>
              )}
            </PharmacyValuesConsumer>
          </div>
        </SwipeableDrawer>
      </React.Fragment>
    );
  }
}

export const Footer = withTheme()(InnerFooter);
