import { Loading } from '@apoly-42/material-ui';
import firebase from 'firebase';
import * as React from 'react';
import { Redirect } from 'react-router';
import { Load } from '../../../../app-util-components/Load';
import { createOtcOrderForUser } from '../../../../deprecated/apolyApi';
import {
  otcPaymentFailedPath,
  userOtcOrderCompletePath,
} from '../../../../deprecated/deprecated-apoly-app/routes/paths';
import { wirecardPaymentRequestForUser } from '../../../../deprecated/deprecated-apoly-app/utilities/apis/apolyApi/apolyApi';
import { rfcUrl, RfcUrl } from '../../../../url';
import {
  CashOtcOrder,
  EPaymentOtcOrder,
  OtcOrder,
  PaymentMethod,
  Pharmacy,
} from '../../../apolyApi';
import { pharmacyCtxToPath } from '../../../layout/PharmacyBaseLink';
import { pharmacyBase } from '../../../routes';
import { PharmacyChannel } from '../../../routes-helper';
import { WirecardRequestFromApi } from './wirecard-utils';
import { WirecardWrapper } from './WirecardWrapper';

interface SubmitUserOrderProps {
  order: OtcOrder;
  user: firebase.User;
  pharmacy: Pharmacy;
  pharmacyChannel: PharmacyChannel;
  urlBase: RfcUrl;
}

type OtcUserOrderResponse = { otcOrderId: string };

type UserOtcData = { order: OtcOrder; user: firebase.User };

/*
// TODO check wie man am sinnvollsten async await einbaut in webpack-bundle und dann wieder die sachen umschreiben
const postNewUserOrder = async ({
  order,
  user,
}: UserOtcData): Promise<OtcUserOrderResponse> => {
  const userToken = await user.getIdToken();

  return await createOtcOrderForUser(userToken, user.uid, order);
};
 */
const postNewUserOrder = ({
  order,
  user,
}: UserOtcData): Promise<OtcUserOrderResponse> => {
  return user
    .getIdToken()
    .then(userToken => createOtcOrderForUser(userToken, user.uid, order));
};

/*
// TODO check wie man am sinnvollsten async await einbaut in webpack-bundle und dann wieder die sachen umschreiben
postNewUserOrderWithEPayment = async (
    order: EPaymentOtcOrder
  ): Promise<WirecardRequestFromApi> => {
    const { user } = this.props;

    const basePath = pharmacyCtxToPath(pharmacyBase, this.props);

    const { otcOrderId } = await postNewUserOrder({ order, user });

    const userToken = await user.getIdToken(); // dont reuse old token, could be expired for slow connections

    return await wirecardPaymentRequestForUser(
      userToken,
      user.uid,
      otcOrderId,
      this.toUrl(userOtcOrderCompletePath(basePath, otcOrderId)),
      this.toUrl(otcPaymentFailedPath(basePath, otcOrderId))
    );
  };
 */

export class SubmitUserOrder extends React.PureComponent<SubmitUserOrderProps> {
  toUrl = (path: string) => rfcUrl(path, this.props.urlBase).toString();

  postNewUserOrderWithEPayment = (
    order: EPaymentOtcOrder
  ): Promise<WirecardRequestFromApi> => {
    const { user } = this.props;

    const basePath = pharmacyCtxToPath(pharmacyBase, this.props);

    return postNewUserOrder({ order, user }).then(({ otcOrderId }) =>
      user
        .getIdToken()
        .then(userToken =>
          wirecardPaymentRequestForUser(
            userToken,
            user.uid,
            otcOrderId,
            this.toUrl(userOtcOrderCompletePath(basePath, otcOrderId)),
            this.toUrl(otcPaymentFailedPath(basePath, otcOrderId))
          )
        )
    );
  };

  renderSubmitEPaymentUserOrder = (order: EPaymentOtcOrder) => (
    <Load fn={() => this.postNewUserOrderWithEPayment(order)}>
      {apiState => (
        <React.Fragment>
          {apiState.isLoading && <Loading />}

          {apiState.response && (
            <WirecardWrapper wcRequestFromApi={apiState.response} />
          )}
        </React.Fragment>
      )}
    </Load>
  );

  renderSubmitCashPaymentUserOrder = (order: CashOtcOrder) => {
    const { user } = this.props;
    const basePath = pharmacyCtxToPath(pharmacyBase, this.props);

    return (
      <Load fn={() => postNewUserOrder({ order, user })}>
        {({ response, isLoading }) => (
          <React.Fragment>
            {isLoading && <Loading />}

            {response && (
              <Redirect
                to={userOtcOrderCompletePath(basePath, response.otcOrderId)}
              />
            )}
          </React.Fragment>
        )}
      </Load>
    );
  };

  render() {
    const { order } = this.props;

    return order.paymentType === PaymentMethod.cash
      ? this.renderSubmitCashPaymentUserOrder(order)
      : this.renderSubmitEPaymentUserOrder(order);
  }
}
