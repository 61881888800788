import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ListItemTextProps } from '@material-ui/core/ListItemText';
import React from 'react';

export interface ListItemLinkWithIconProps {
  secondaryText?: ListItemTextProps['secondary'];
  text: ListItemTextProps['primary'];
  icon: IconProp;
  linkComponent: React.ComponentType,
}

export const ListItemLinkWithIcon: React.SFC<ListItemLinkWithIconProps> = ({
  secondaryText = '',
  text,
  icon,
  linkComponent,
}) => (
  <ListItem button={true} component={linkComponent} divider={true}>
    <ListItemIcon>
      <FontAwesomeIcon icon={icon} />
    </ListItemIcon>
    <ListItemText primary={text} secondary={secondaryText} />
  </ListItem>
);
