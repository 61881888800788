import { createContainer, onMount, pLocalStorage } from '@apoly-42/apoly-utils';
import firebase from 'firebase/app';
import withRouter from 'react-router-dom/withRouter';
import compose from 'recompose/compose';
import { loginPath } from '../../routes/paths';

const checkRedirectResultOnMountContainer = createContainer(
  onMount(props =>
    firebase
      .auth()
      .getRedirectResult()
      .then(
        ({ user }) =>
          user &&
          pLocalStorage.getItem('redirectUrlAfterLogIn') && [
            props.history.push(pLocalStorage.getItem('redirectUrlAfterLogIn')),
            pLocalStorage.setItem('redirectUrlAfterLogIn', ''),
          ]
      )
      .catch(error =>
        props.history.replace(loginPath(), { redirectError: error })
      )
  )
);

const enhance = compose(withRouter);

const CheckRedirectResultFromProviderSignIn = enhance(
  checkRedirectResultOnMountContainer
);

export default CheckRedirectResultFromProviderSignIn;
