import { faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { I18nMessage } from '../../../../../app-util-components/I18nMessage';
import { appMessages } from '../../../../../app-utilities/appMessages';
import { ListItemButtonWithIcon } from '../ListItemButtonWithIcon';

export interface CloseDrawerButtonProps {
  handleDrawer: () => void;
}

export const CloseDrawerButton: React.SFC<CloseDrawerButtonProps> = ({
  handleDrawer,
}) => (
  <ListItemButtonWithIcon
    text={<I18nMessage message={appMessages.closeDrawer} />}
    icon={faCaretRight}
    onClickEvent={handleDrawer}
  />
);
