// tslint:disable:ordered-imports
import './bootstrap/polyfills';
import { loadCSS } from 'fg-loadcss';
import moment from 'moment-timezone';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ErrorBoundary } from './app-util-components/ErrorBoundary';
import { App } from './app/App';
import timeZoneData from './bootstrap/berlinTimeZoneData.json';
import {
  DeprecatedAppBootstrap,
  initDeprecatedApp,
} from './deprecated/deprecated-apoly-app-bootstrap/deprecated-app-bootstrap';
import { dataLayer } from './googleDataLayerPush';
import { registerServiceWorker } from './registerServiceWorker';
import { logErrorGracefully } from './deprecated/deprecated-apoly-app/utilities/failGracefully';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

initDeprecatedApp();

window.dataLayer = dataLayer;

// the berlinTimeZoneData is compiled via the createTimeZoneData.js-node-script for 2015-2020,
moment.tz.load(timeZoneData);

window.addEventListener('error', function(error) {
  console.log(error, error.error);
  logErrorGracefully(error);
});

const DefErr = () => <h1>everythin went wrong.</h1>;

ReactDOM.render(
  <ErrorBoundary component={DefErr}>
    <DeprecatedAppBootstrap>
      <App />
    </DeprecatedAppBootstrap>
  </ErrorBoundary>,
  document.getElementById('root') as HTMLElement
);

loadCSS(
  new URL('https://fonts.googleapis.com/css?family=Lora|Roboto:300,400,500')
);

registerServiceWorker();
