import { createAction, createReducer } from '@apoly-42/apoly-utils';
import { assoc, compose, prop } from 'ramda';
import { selectAppStateRoot } from '../selectors';

const SET_NEW_ADDRESS_FOR_PHARMACY_ID =
  'pharmacyIdToAddress/SET_NEW_ADDRESS_FOR_PHARMACY_ID';

export const overwritePharmacyIdToAddressState = createAction(
  'overwritePharmacyIdToAddressState'
);

export const setNewAddressForPharmacyId = ({ pharmacyId, address }) => ({
  type: SET_NEW_ADDRESS_FOR_PHARMACY_ID,
  payload: {
    pharmacyId,
    address,
  },
});

export const selectPharmacyIdToAddress = compose(
  prop('pharmacyIdToAddress'),
  selectAppStateRoot
);

export const selectAddressForPharmacyId = (state, pharmacyId) =>
  prop(pharmacyId, selectPharmacyIdToAddress(state));

const addNewAddressForPharmacyId = (state, { payload }) =>
  assoc(payload.pharmacyId, payload.address, state);

export default createReducer(
  {},
  {
    [SET_NEW_ADDRESS_FOR_PHARMACY_ID]: addNewAddressForPharmacyId,
    [overwritePharmacyIdToAddressState]: (state, { payload }) => payload,
  }
);
