import { Typography } from '@material-ui/core';
import React from 'react';

const renderSectionTitle = section => {
  if (section.suggestions.length > 0) {
    return (
      <div style={{ marginLeft: 16, marginTop: 8, marginBottom: 8 }}>
        <Typography variant="title">{section.title}</Typography>
      </div>
    );
  }

  return null;
};

export default renderSectionTitle;
