import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { css } from 'emotion';
import React from 'react';
import { I18nMessage } from '../../../../app-util-components/I18nMessage';
import { appMessages } from '../../../../app-utilities/appMessages';
import SearchAutoSuggestComponent from '../../../../deprecated/deprecated-apoly-app/components/searchAutoSuggest/SearchAutoSuggestComponent';

const absolute = css`
    position: absolute;
    overflow-y: initial;
    top: 48px;
    margin: 16px 0;
    width: 80%;
    max-width: 100%;
    @media (max-width: 600px) {
      width: 100%;
      margin: 0;
      top: 56px;
      padding: 8px;,
    }
`;

// TODO: mit Search Context
// const clearSearchFilterOptions = () => console.log('filter clear');

export interface InnerSearchBarProps {
  closeSearchBar: () => void;
  open: boolean;
}

export const SearchBar: React.SFC<InnerSearchBarProps> = ({
  closeSearchBar,
  open,
}) => (
  <Dialog
    classes={{ paper: absolute }}
    open={open}
    onClose={closeSearchBar}
    aria-labelledby="responsive-dialog-title"
  >
    <DialogTitle id="responsive-dialog-title">
      <I18nMessage message={appMessages.medicSearch} />
    </DialogTitle>
    <DialogContent style={{ overflowY: 'initial' }}>
      <SearchAutoSuggestComponent closeSearchBar={closeSearchBar} />
    </DialogContent>
  </Dialog>
);
