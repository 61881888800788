import { DefaultLoadable } from '@apoly-42/material-ui';
import { cond, equals } from 'ramda';
import React from 'react';
import { LanguageContextProps, Languages } from './LanguageContext';

export const EnLoadable = DefaultLoadable({
  loader: () => import('./En').then(module => module.En),
});

export const DeLoadable = DefaultLoadable({
  loader: () => import('./De').then(module => module.De),
});

export const TrLoadable = DefaultLoadable({
  loader: () => import('./Tr').then(module => module.Tr),
});

export const RuLoadable = DefaultLoadable({
  loader: () => import('./Ru').then(module => module.Ru),
});

export const ArLoadable = DefaultLoadable({
  loader: () => import('./Ar').then(module => module.Ar),
});

const getAsyncLanguageLoadable = cond<Languages, React.ComponentType>([
  [equals(Languages.de), () => DeLoadable],
  [equals(Languages.ar), () => ArLoadable],
  [equals(Languages.ru), () => RuLoadable],
  [equals(Languages.tr), () => TrLoadable],
  [equals(Languages.en), () => EnLoadable],
]);

interface AsyncLanguageLoadableProps {
  language: LanguageContextProps['language'];
}

export class AsyncLanguageLoadable extends React.PureComponent<
  AsyncLanguageLoadableProps
> {
  render() {
    const Component = getAsyncLanguageLoadable(this.props.language);

    return <Component {...this.props} />;
  }
}

// TODO set rtl/ltr which depends on locale ( props.locale === 'ar' ? 'rtl' : 'ltr' )
// const setBodyTextDirection = textDirection =>  (document.body.dir = textDirection);
