import * as React from 'react';
import { RenderChildrenProp } from '../../app-utilities/reactUtilTypes';
import { UrlConsumer } from '../../url/UrlContext';

export class UrlPathConsumer extends React.PureComponent<
  RenderChildrenProp<string | undefined>
> {
  render() {
    return (
      <UrlConsumer>
        {ctx => this.props.children(ctx.url ? ctx.url.path : undefined)}
      </UrlConsumer>
    );
  }
}
