import { createMuiTheme, MuiThemeProvider, Theme } from '@material-ui/core';
import { createGenerateClassName, jssPreset } from '@material-ui/core/styles';
import { create } from 'jss';
import rtl from 'jss-rtl';
import * as React from 'react';
import JssProvider from 'react-jss/lib/JssProvider';
import { PharmacyTheme } from '../../app-theme/pharmacy-theme/PharmacyThemeInterface';
import { Languages, LanguageValues } from '../../languages/LanguageContext';

// Configure JSS
// @ts-ignore
const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
  insertionPoint: document.getElementById('jss-insertion-point') as HTMLElement,
});

// Custom Material-UI class name generator.
const generateClassName = createGenerateClassName();

interface AppMuiThemeProps {
  palette: PharmacyTheme['palette'];
  type?: Theme['palette']['type'];
}

type CreateAppMuiThemeProps = AppMuiThemeProps & {
  direction: Theme['direction'];
};

export const createAppMuiTheme = ({
  direction,
  type = 'light',
  palette,
}: CreateAppMuiThemeProps) =>
  createMuiTheme({
    palette: {
      primary: palette.primary,
      secondary: palette.secondary,
      type,
    },
    direction,
  });

class InnerAppMuiTheme extends React.PureComponent<CreateAppMuiThemeProps> {
  componentDidMount() {
    document.body.dir = this.props.direction;
  }

  componentDidUpdate() {
    document.body.dir = this.props.direction;
  }

  render() {
    return (
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <MuiThemeProvider theme={createAppMuiTheme(this.props)}>
          {this.props.children}
        </MuiThemeProvider>
      </JssProvider>
    );
  }
}

export const AppMuiTheme: React.SFC<AppMuiThemeProps> = props => (
  <LanguageValues>
    {langCtx => (
      <InnerAppMuiTheme
        {...props}
        direction={langCtx.language === Languages.ar ? 'rtl' : 'ltr'}
      />
    )}
  </LanguageValues>
);
