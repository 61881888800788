import { FormattedEuro, I18nMessage } from '@apoly-42/apoly-components';
import { Divider, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { commonMessages } from '../../../../../constants/messages/commonMessages';
import {
  fullMaxWidthWithMarginAuto,
  marginAuto,
} from '../../../../../constants/styles/commonClasses';
import { priceWithTax } from '../../../../../constants/styles/cssEmotionClasses';
import ProductImageSmall from '../../../../imageComponents/productImages/ProductImageSmall';
import { getTaxValueMessage } from '../getTaxValueForProduct';

const messages = defineMessages({
  pricePerPiece: {
    id: 'pricePerPiece',
    defaultMessage: 'price per piece',
  },
});

const getTotalProductPrice = (amount, price) => (amount * price).toFixed(2);

const SingleProductResponsive = ({ article, count }) => {
  const { medicine } = article.package;
  const totalProductPrice = getTotalProductPrice(count, article.price);
  return (
    <Grid container spacing={16}>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="title"
          paragraph
          dangerouslySetInnerHTML={{ __html: medicine.name }}
        />
        <Typography variant="caption">
          {article.package.amountText} | PZN: {article.package.pzn}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container justify="center">
          <Grid item xs={8} sm={4}>
            <ProductImageSmall
              name={medicine.name}
              imgUrl={article.package.imgUrl}
              style={fullMaxWidthWithMarginAuto}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} style={marginAuto}>
        <Grid container spacing={16} justify="space-between">
          <Grid item xs={6}>
            <Typography>
              <I18nMessage message={commonMessages.amount} />:
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography align="right">{count}</Typography>
          </Grid>
        </Grid>
        <Grid container spacing={16} justify="space-between">
          <Grid item xs={6}>
            <Typography>
              <I18nMessage message={messages.pricePerPiece} />:
            </Typography>
          </Grid>
          <Grid item xs={6} className={priceWithTax}>
            <Typography align="right">
              <FormattedEuro value={Number(article.price)} />
            </Typography>
            {getTaxValueMessage(article.package.mwst_code)}
          </Grid>
        </Grid>
        <Grid container spacing={16} justify="space-between">
          <Grid item xs={7}>
            <Typography>
              <I18nMessage message={commonMessages.totalAmount} />:
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography align="right">
              <FormattedEuro value={Number(totalProductPrice)} />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

SingleProductResponsive.propTypes = {
  article: PropTypes.object.isRequired,
  count: PropTypes.number.isRequired,
};

export default SingleProductResponsive;
