import axios, { CancelToken } from 'axios';
import { Moment } from 'moment-timezone';

const axiosInstance = axios.create({
  baseURL: process.env.APOLY_API_BASE_URL,
});

type Weekday = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export type DeliveryType =
  | 'courier'
  | 'postal'
  | 'pickUp'
  | 'pss_vending_machine'
  | 'pss_vending_machine_reserve'
  | 'offizin_order_schnellkasse';

type TimeSpanType = 'between' | 'absolute';

export interface ShoppingCartItem {
  articleId: string;
  articlePrice: string;
  count: number;
}

export interface OtcOrderBase {
  shoppingCart: {
    price: number;
    items: ShoppingCartItem[];
  };
  buyer: {
    email: string;
    name?: string;
    phone?: string;
    gender?: string;
  };
  paymentType: PaymentMethod;
  pharmacyId: string;
  deliveryType: DeliveryType;
  shippingAddress?: Address;
  billingAddress?: Address;
  courierTimeSpanId?: string;
}

type PaymentMethodStr =
  | 'cash'
  | 'sofort'
  | 'visa'
  | 'sepa'
  | 'mastercard'
  | 'paypal';

export enum PaymentMethod {
  cash = 'cash',
  sofort = 'sofort',
  visa = 'visa',
  sepa = 'sepa',
  mastercard = 'mastercard',
  paypal = 'paypal',
}

export interface CashOtcOrder extends OtcOrderBase {
  paymentType: PaymentMethod.cash;
}

export interface EPaymentOtcOrder extends OtcOrderBase {
  paymentType:
    | PaymentMethod.sofort
    | PaymentMethod.visa
    | PaymentMethod.sepa
    | PaymentMethod.mastercard
    | PaymentMethod.paypal;
}

export type OtcOrder = EPaymentOtcOrder | CashOtcOrder;

export interface Address {
  addressId?: string;
  name: string;
  street: string;
  zip: string;
  city: string;
  latitude: number;
  longitude: number;
  country: string;
  notes?: string;
  additional?: string;
}

export interface OpeningTime {
  openingTimeId: string;
  weekday: Weekday;
  openingFrom: string;
  openingTo: string;
}

export interface CourierTimeSpan {
  courierTimeSpanId: string;
  price: number;
  weekday: Weekday;
  timeSpanType: TimeSpanType;
  orderUntil: string;
  deliveryFrom: string;
  deliveryTo: string;
  absoluteDeliveryStartsAt: string;
  deliveryWithin: string;
}

export interface Pharmacy {
  pharmacyId: string;
  name: string;
  owner: string;
  phone: string;
  fax: string;
  email: string;
  website: string;
  description: string;
  trustedPharmacyUrl: string;
  logoUrlPath: string;
  urlCode: string;
  photoUrl: string;
  pictureUrl: string;
  address: Address;
  paymentMethods: PaymentMethodStr[];
  courierTimeSpans: CourierTimeSpan[];
  openingTimes: OpeningTime[];
  availableZips: string[];
  agbUrl: string;
  wrbUrl: string;
}

export interface EnhancedPharmacy {
  nextCourierTimeSpans: CourierTimeSpan[];
  pharmacy: Pharmacy;
}

interface ServerTimeResponse {
  serverTime: string;
}

const toApiDateString = (mom: Moment) => {
  return mom
    .clone()
    .locale('en')
    .format('YYYY-MM-DD');
};

export const getServerTime = () =>
  axiosInstance.post<ServerTimeResponse>('/serverTime');

export const loadPharmacyFromUrlCode = (
  pharmacyUrlCode: string,
  cancelToken?: CancelToken
) =>
  axiosInstance
    .get<Pharmacy>(`/pharmacies/urlCode=${pharmacyUrlCode}`, { cancelToken })
    .then(response => response.data);

interface LoadPharmacyHolidaysApiCall {
  pharmacyId: string;
  date: Moment;
  until: Moment;
}
export const loadPharmacyHolidays = (
  { pharmacyId, date, until }: LoadPharmacyHolidaysApiCall,
  cancelToken?: CancelToken
) =>
  axiosInstance
    .get<string[]>(`/pharmacies/${pharmacyId}/holidays`, {
      params: { date: toApiDateString(date), until: toApiDateString(until) },
      cancelToken,
    })
    .then(response => response.data);
