import { createTplItems } from './tplItems/createTplItems';
import { ItemTypes, TemplateItem } from './tplItems/tplItemTypes';
import {
  ExtractKeyValues,
  ExtractValues,
  ItemTplVar,
  TplCreator,
  TplVarMatches,
} from './tplTypes';

// TODO build other library on top to use default-values for all
export function tpl<Values>(
  strings: TemplateStringsArray,
  ...t: ItemTplVar<Values>[]
) {
  const res: ItemTplVar<Values>[] = [];

  strings.forEach((str, i) => {
    if (str) {
      res.push(str);
    }

    if (t[i]) {
      res.push(t[i]);
    }
  });

  return res;
}

export function build<Values>(tplCreator: TplCreator<Values>, values: Values) {
  return tplCreator(values).join('');
}

function checkTplItem<Values>(tplItem: TemplateItem<Values>) {
  if (tplItem.start < 0) {
    return false;
  }

  if (tplItem.prevItem && tplItem.start !== tplItem.prevItem.end) {
    return false;
  }

  if (tplItem.type === ItemTypes.FnItem && tplItem.templateResult === false) {
    return false;
  }

  return true;
}

function checkTplItems<Values>(
  tplCreator: TplCreator<Values>,
  matcher: TplVarMatches<Values>,
  { checkStart, checkEnd }: { checkStart: boolean; checkEnd: boolean },
  str: string
) {
  const tester = tplCreator(matcher);

  const tplItems = createTplItems(tester, str);

  if (tplItems.length <= 0) {
    return true;
  }

  if (checkStart && tplItems[0].start !== 0) {
    return false;
  }

  if (!tplItems.every(checkTplItem)) {
    return false;
  }

  if (checkEnd && tplItems[tplItems.length - 1].end !== str.length) {
    return false;
  }

  return true;
}

const matchesConf = { checkStart: false, checkEnd: false };

export function matches<Values>(
  tplCreator: TplCreator<Values>,
  matcher: TplVarMatches<Values>,
  str: string
): boolean;
export function matches<Values>(
  tplCreator: TplCreator<Values>,
  matcher: TplVarMatches<Values>
): (str: string) => boolean;
export function matches<Values>(
  tplCreator: TplCreator<Values>,
  matcher: TplVarMatches<Values>,
  str?: string
) {
  if (str === undefined) {
    return (str2: string) => {
      return checkTplItems(tplCreator, matcher, matchesConf, str2);
    }
  }

  return checkTplItems(tplCreator, matcher, matchesConf, str);
}

const matchesExactConf = { checkStart: true, checkEnd: true };
export function matchesExact<Values>(
  tplCreator: TplCreator<Values>,
  matcher: TplVarMatches<Values>,
  str: string
) {
  return checkTplItems(tplCreator, matcher, matchesExactConf, str);
}

const startsWithConf = { checkStart: true, checkEnd: false };
export function startsWith<Values>(
  tplCreator: TplCreator<Values>,
  matcher: TplVarMatches<Values>,
  str: string
) {
  return checkTplItems(tplCreator, matcher, startsWithConf, str);
}

export function extract<Values>(
  tplCreator: TplCreator<Values>,
  extractors: ExtractValues<Values>,
  str: string
) {
  const keyValueExtractors = {} as ExtractKeyValues<Values>;

  const keys = Object.keys(extractors) as (keyof Values)[];
  keys.forEach(
    key => (keyValueExtractors[key] = (value: string) => ({ key, value: extractors[key](value) }))
  );

  const tester = tplCreator(keyValueExtractors);

  const tplItems = createTplItems(tester, str);

  const res = {} as Values;

  tplItems.forEach(
    tplItem =>
      tplItem.type === ItemTypes.FnItem &&
      tplItem.templateResult &&
      typeof tplItem.templateResult !== 'boolean' &&
      Object.assign(res, {
        [tplItem.templateResult.key]: tplItem.templateResult.value,
      })
  );

  return res;
}
