import { PaymentMethod } from '../../apolyApi';

export enum DeliveryType {
  pickUp = 1,
  mailOrder,
  courier,
  pssReservation,
}

export interface Buyer {
  email: string;
  name: string;
  phone: string;
}

export interface Address {
  name: string;
  street: string;
  zip: string;
  city: string;
  country: string;
  notes?: string;
  latitude: number;
  longitude: number;
}

export interface ShoppingCartItem {
  pharmacyPackageId: string; // muss für API zu articleId umbenannt werden
  price: number;
  count: number;
}

export interface ShoppingCart {
  price: number;
  items: ShoppingCartItem[];
}

export type CourierAddress = {
  deliveryType: DeliveryType.courier;
  billingAddress: Address;
  shippingAddress?: Address;
  courierTimeSpanId: string;
};

export type PostalAddress = {
  deliveryType: DeliveryType.mailOrder;
  billingAddress: Address;
  shippingAddress?: Address;
};

export type PickUpAddress = {
  deliveryType: DeliveryType.pickUp;
  billingAddress: Address;
  shippingAddress?: Address;
};

export interface OtcOrder {
  shoppingCart: ShoppingCart;
  buyer: Buyer;
  paymentType: PaymentMethod;
  pharmacyId: string;
  deliveryType: DeliveryType;
  shippingAddress: Address;
  billingAddress: Address;
  courierTimeSpanId: string;
}
