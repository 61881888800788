import { ItemTplVar } from '../tplTypes';
import { createStage1Array } from './createTplItemsStage1';
import { createStage2Array } from './createTplItemsStage2';
import {
  FnItem,
  FnItemStage2,
  ItemTypes,
  Stage2Item,
  StrItem,
  StrItemStage2,
  TemplateItem,
} from './tplItemTypes';

function createStrItem<Values>(
  strItemStage2: StrItemStage2,
  prevItem: TemplateItem<Values> | undefined
): StrItem<Values> {
  return {
    ...strItemStage2,
    type: ItemTypes.StrItem,
    prevItem,
  };
}

function createFnItem<Values>(
  str: string,
  fnItemStage2: FnItemStage2<Values>,
  prevItem: TemplateItem<Values> | undefined
): FnItem<Values> {
  const start = prevItem ? prevItem.end : 0;

  const extracted =
    start >= 0 && fnItemStage2.end >= 0
      ? str.substring(start, fnItemStage2.end)
      : undefined;

  return {
    type: ItemTypes.FnItem,
    tplVar: fnItemStage2.tplVar,
    start,
    end: fnItemStage2.end,
    extracted,
    templateResult:
      extracted !== undefined ? fnItemStage2.tplVar(extracted) : undefined,
    prevItem,
  };
}

function createTemplateItem<Values>(
  str: string,
  stage2Item: Stage2Item<Values>,
  prevItem: TemplateItem<Values> | undefined
) {
  if (stage2Item.type === ItemTypes.StrItemStage2) {
    return createStrItem(stage2Item, prevItem);
  } else {
    return createFnItem(str, stage2Item, prevItem);
  }
}

function createTemplateItems<Values>(
  str: string,
  stage2Array: Stage2Item<Values>[]
) {
  let prevItem: TemplateItem<Values>;

  return stage2Array.map(stage2Item => {
    prevItem = createTemplateItem(str, stage2Item, prevItem);
    return prevItem;
  });
}

export function createTplItems<Values>(
  templateVars: ItemTplVar<Values>[],
  str: string
) {
  if (templateVars.length <= 0) {
    return [];
  }

  const stage1Arr = createStage1Array(str, templateVars);
  const stage2Arr = createStage2Array(str, stage1Arr);

  return createTemplateItems(str, stage2Arr);
}
