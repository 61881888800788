import { CancelTokenSource } from 'axios';
import { Moment } from 'moment-timezone';
import * as React from 'react';
import { LoadWithAxiosAbortable } from '../app-util-components/LoadWithAxiosAbortable';
import { tap } from '../app-utilities/fn-utils';
import { getEnhancedPharmacy } from '../deprecated/loadPharmacies/EnhancePharmacy';
import {
  EnhancedPharmacy,
  loadPharmacyFromUrlCode,
  loadPharmacyHolidays,
  Pharmacy,
} from './apolyApi';
import { PharmacyChannel} from './routes-helper';
import { routeToChannel } from './routes-helper';

interface LoadPharmacyProps {
  pharmacyUrlCode: string;
  setPharmacyIsLoading: (val: boolean) => void;
  setPharmacy: (val: Pharmacy) => void;
  setEnhancedPharmacy: (val: EnhancedPharmacy) => void;
  serverTime: Moment;
  setPharmacyChannel: (val: PharmacyChannel) => void;
  pharmacyChannelFromRoute: string;
}

interface LoadEnhancedPharmacyProps extends LoadPharmacyProps {
  pharmacy: Pharmacy;
}

const LoadEnhancedPharmacy: React.SFC<LoadEnhancedPharmacyProps> = props => (
  <LoadWithAxiosAbortable
    key={props.serverTime.dayOfYear()}
    fn={(cancelToken: CancelTokenSource) =>
      loadPharmacyHolidays(
        {
          pharmacyId: props.pharmacy.pharmacyId,
          date: props.serverTime,
          until: props.serverTime.clone().add(7, 'days'),
        },
        cancelToken.token
      )
        .then(holidays =>
          getEnhancedPharmacy(props.pharmacy, holidays, props.serverTime)
        )
        .then(props.setEnhancedPharmacy)
    }
  />
);

export class LoadPharmacy extends React.Component<LoadPharmacyProps> {
  componentDidMount() {
    const { setPharmacyChannel, pharmacyChannelFromRoute } = this.props;

    setPharmacyChannel(routeToChannel(pharmacyChannelFromRoute));
  }

  componentDidUpdate(prevProps: LoadPharmacyProps) {
    const { setPharmacyChannel, pharmacyChannelFromRoute } = this.props;

    if (prevProps.pharmacyChannelFromRoute !== pharmacyChannelFromRoute) {
      setPharmacyChannel(routeToChannel(pharmacyChannelFromRoute));
    }
  }

  render() {
    return (
      <LoadWithAxiosAbortable<Pharmacy>
        key={this.props.pharmacyUrlCode}
        fn={(cancelToken: CancelTokenSource) =>
          loadPharmacyFromUrlCode(
            this.props.pharmacyUrlCode,
            cancelToken.token
          ).then(tap(this.props.setPharmacy))
        }
      >
        {loading =>
          loading.response ? (
            <LoadEnhancedPharmacy {...this.props} pharmacy={loading.response} />
          ) : null
        }
      </LoadWithAxiosAbortable>
    );
  }
}
