import { FormattedEuro, I18nMessage } from '@apoly-42/apoly-components';
import { ButtonWithIcon } from '@apoly-42/material-ui';
import {
  faChevronDoubleLeft,
  faChevronDoubleRight,
} from '@fortawesome/pro-solid-svg-icons';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { PharmacyValuesConsumer } from '../../../../../app/PharmacyContext';
import { checkoutPath } from '../../../../../app/routes';
import { PharmacyChannel } from '../../../../../app/routes-helper';
import { commonMessages } from '../../../constants/messages/commonMessages';
import { flex, flexRight } from '../../../constants/styles/commonClasses';
import { selectUser } from '../../../redux/auth/reducer';
import { selectBasePath } from '../../../redux/shopPharmacy/reducer';
import {
  getShoppingCartLength,
  getShoppingCartTotalPrice,
} from '../../../redux/shoppingCart/selectors';
import {
  checkoutPathLoginOrContinueAsGuest,
  medicSearchSetPharmacyPath,
} from '../../../routes/paths';

const styles = {
  fullWidthXs: {
    '@media(max-width: 600px)': {
      width: '100%',
    },
  },
};

const messages = defineMessages({
  couponCodeAvailable: {
    id: 'couponCodeAvailable',
    defaultMessage: 'Coupon code available ?',
  },
  checkout: {
    id: 'checkout',
    defaultMessage: 'checkout',
  },
  reserveMedicines: {
    id: 'reserveMedicines',
    defaultMessage: 'Medikamente reservieren',
  },
});

const InnerShoppingCartFooter = ({
  classes,
  submit,
  user,
  basePath,
  shoppingCartLength,
  shoppingCartPrice,
}) => (
  <Grid container spacing={16}>
    {shoppingCartPrice && (
      <Grid item xs={12} style={flex}>
        <Typography style={flexRight}>
          <I18nMessage message={commonMessages.totalAmount} />:{' '}
          <FormattedEuro value={Number(shoppingCartPrice)} />
        </Typography>
      </Grid>
    )}
    <Grid item xs={12}>
      <Grid container spacing={16}>
        <Grid item xs={12} sm={6}>
          <Button
            className={classes.fullWidthXs}
            variant="raised"
            component={Link}
            to={medicSearchSetPharmacyPath(basePath)}
            icon={faChevronDoubleLeft}
          >
            <I18nMessage message={commonMessages.continueShopping} />
          </Button>
        </Grid>
        {shoppingCartLength > 0 && (
          <PharmacyValuesConsumer>
            {pharmacyCtx => (
              <Grid item xs={12} sm={6} style={flex}>
                {pharmacyCtx.pharmacyChannel ===
                PharmacyChannel.customerBoard ? (
                  <ButtonWithIcon
                    className={classes.fullWidthXs}
                    variant="raised"
                    style={flexRight}
                    color="secondary"
                    onClick={submit}
                    iconRight
                    icon={faChevronDoubleRight}
                  >
                    <I18nMessage message={messages.reserveMedicines} />
                  </ButtonWithIcon>
                ) : (
                  <ButtonWithIcon
                    className={classes.fullWidthXs}
                    variant="raised"
                    color="secondary"
                    style={flexRight}
                    component={Link}
                    to={
                      user
                        ? checkoutPath(basePath)
                        : checkoutPathLoginOrContinueAsGuest(basePath)
                    }
                    iconRight
                    icon={faChevronDoubleRight}
                  >
                    <I18nMessage message={messages.checkout} />
                  </ButtonWithIcon>
                )}
              </Grid>
            )}
          </PharmacyValuesConsumer>
        )}
      </Grid>
    </Grid>
  </Grid>
);

const mapStateToProps = state => ({
  shoppingCartPrice: getShoppingCartTotalPrice(state),
  shoppingCartLength: getShoppingCartLength(state),
  basePath: selectBasePath(state),
  user: selectUser(state),
});

const mapDispatchToProps = {};

const enhance = compose(
  withStyles(styles),
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
);

const ShoppingCartFooter = enhance(InnerShoppingCartFooter);

ShoppingCartFooter.propTypes = {
  submit: PropTypes.func,
};

ShoppingCartFooter.defaultProps = {
  submit: null,
};

InnerShoppingCartFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object,
  basePath: PropTypes.string.isRequired,
  shoppingCartLength: PropTypes.number,
  shoppingCartPrice: PropTypes.string,
  ...ShoppingCartFooter.propTypes,
};

InnerShoppingCartFooter.defaultProps = {
  shoppingCartLength: null,
  shoppingCartPrice: null,
  user: null,
  ...ShoppingCartFooter.defaultProps,
};

export default ShoppingCartFooter;
