import * as React from 'react';
import { FormikFieldState } from 'formik-fields';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
} from '@material-ui/core';
import { FormControlProps } from '@material-ui/core/FormControl';

interface MuiFormikCheckboxFieldProps extends FormControlProps {
  field: FormikFieldState<boolean>;
  label: React.ReactNode;
}

export class MuiFormikCheckboxField extends React.PureComponent<
  MuiFormikCheckboxFieldProps
> {
  handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    this.props.field.setValue(e.target.checked);

  render() {
    const { field, label, ...rest } = this.props;

    const isError = field.isTouched && Boolean(field.error);

    return (
      <FormControl error={isError} {...rest}>
        <FormControlLabel
          control={
            <Checkbox
              id={field.name}
              value={field.name}
              checked={field.value}
              onChange={this.handleChange}
              onBlur={field.handleBlur}
              color={isError ? 'secondary' : undefined }
            />
          }
          label={label}
        />
        {isError && <FormHelperText style={{ margin: 0 }}>{field.error}</FormHelperText>}
      </FormControl>
    );
  }
}
